import axios from 'axios';

export default class SubGiroService {

    getSubGiros(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/subgiro`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getSubGirosGenerales() {            
        const url = process.env.VUE_APP_APIURL + `/api/subgirosgenerales`;    
        return axios.get(url).then(res=>res.data);
    }

    updateSubGiro(info){
        const url = process.env.VUE_APP_APIURL + `/api/subgiroedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createSubGiro(info){
        
        const url = process.env.VUE_APP_APIURL + `/api/subgiroalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteSubGiro(info){
        const url = process.env.VUE_APP_APIURL + `/api/subgirobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}
