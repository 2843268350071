import axios from "axios";

export default class PermissionService {
  getPermission() {
    const url = process.env.VUE_APP_APIURL + `/api/permisos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getPermissionsMenu() {
    const url = process.env.VUE_APP_APIURL + `/api/permisos/menu`;
    return axios.get(url).then((res) => res.data.data);
  }

  updatePermission(permiso) {
    const url = process.env.VUE_APP_APIURL + `/api/permisos/${permiso.id_permiso}`;
    return axios
      .put(url, permiso, { headers: { "Content-Type": "application/json" } })
      .then((res) => res.data);
  }

  createPermission(permiso) {
    permiso["id_permiso"] = 0;
    const url = process.env.VUE_APP_APIURL + `/api/permisos`;
    return axios
      .post(url, permiso, { headers: { "Content-Type": "application/json" } })
      .then((res) => res.data);
  }

  deletePermission(permiso) {
    const url = process.env.VUE_APP_APIURL + `/api/permisos/${permiso.id_permiso}`;
    return axios
      .delete(url, permiso, { headers: { "Content-Type": "application/json" } })
      .then((res) => res.data);
  }
}
