import axios from 'axios';

export default class UsuarioService {

    getUsuario() {            
        const url = process.env.VUE_APP_APIURL + `/api/usuario`;    
        return axios.get(url).then(res=>res.data);
    }

    updateUsuario(info){
        const url = process.env.VUE_APP_APIURL + `/api/usuarioedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateUsuarioPerfil(info){
        const url = process.env.VUE_APP_APIURL + `/api/usuarioedicionperfil`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createUsuario(info){
        const url = process.env.VUE_APP_APIURL + `/api/usuarioalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getUsuarioPerfil(info){
        const url = process.env.VUE_APP_APIURL + `/api/usuarioperfil`;     
        //console.log(axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data));
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteUsuario(info){
        const url = process.env.VUE_APP_APIURL + `/api/usuariobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getUsuarioEmpresa() {            
        const url = process.env.VUE_APP_APIURL + `/api/usuarioempresa`;    
        return axios.get(url).then(res=>res.data);
    }

    updateUsuarioEmpresa(info){
        const url = process.env.VUE_APP_APIURL + `/api/usuarioempresaedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createUsuarioEmpresa(info){
        const url = process.env.VUE_APP_APIURL + `/api/usuarioempresaalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteUsuarioEmpresa(info){
        const url = process.env.VUE_APP_APIURL + `/api/usuarioempresabaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    enviaCredenciales(info){
        const url = process.env.VUE_APP_APIURL + `/api/enviarcredencialescliente`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

}