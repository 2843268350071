
import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import auth from "../auth";
import { useRouter, useRoute } from 'vue-router';
//import { ref } from 'vue';
import Cookies from "js-cookie";
window.Cookies = Cookies;
import UserPanel from "./user-panel";

export default {
  props: {
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
    logOutFunc: Function
  },
   data() {
    return {
      useconver: null,
      userdata: null,
      nombre: null,
      id: null,
    };
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const email = JSON.parse(Cookies.get("ets_user")).nombre;//ref("");
    auth.getUser().then((e) => 
          
              email.value = e.data.email
    );
     
    
   
    
    const userMenuItems = [ 
      {
        text: "Salir",
        icon: "close",
        onClick: onLogoutClick
    }];
      
    function onLogoutClick() {
      auth.logOut();
      Cookies.set("ets_user", "null");
      router.push({
        path: "/login",
        query: { redirect: route.path }
      });
    }

  

    return {
      email,
      userMenuItems
    };
  },
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    UserPanel
  }
};
