import axios from 'axios';

export default class TipoProcesoResolucionService {

    getTipoProcesoResolucion() {            
        const url = process.env.VUE_APP_APIURL + `/api/tipoprocesoresolucion`;    
        return axios.get(url).then(res=>res.data);
    }

     
}