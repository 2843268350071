import axios from 'axios';

export default class GrupoListadoDocumentacionService {

    getGrupoListadoDocumentacion() {            
        const url = process.env.VUE_APP_APIURL + `/api/grupolistadodocumentacion`;    
        return axios.get(url).then(res=>res.data);
    }

    updateGrupoListadoDocumentacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupolistadodocumentacionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createGrupoListadoDocumentacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupolistadodocumentacionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteGrupoListadoDocumentacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupolistadodocumentacionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}