import axios from 'axios';

export default class LeyService {

    getLey() {            
        const url = process.env.VUE_APP_APIURL + `/api/ley`;    
        return axios.get(url).then(res=>res.data);
    }

    updateLey(info){
        const url = process.env.VUE_APP_APIURL + `/api/leyedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createLey(info){
        const url = process.env.VUE_APP_APIURL + `/api/leyalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteLey(info){
        const url = process.env.VUE_APP_APIURL + `/api/leybaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}