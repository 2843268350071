
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxLookup,
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,
    DxMasterDetail,
    DxFormItem
} from 'devextreme-vue/data-grid';
import 'devextreme-vue/text-area';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import ServicioService from '../services/servicio-service';
import EstadoService from '../services/estado-service';
import GuiaEvaluacionService from '../services/guiaevaluacion-service';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import MasterDetail from './guiaevaluacionMasterDetail.vue';
import Cookies from "js-cookie";
import notify from 'devextreme/ui/notify';
window.Cookies = Cookies;



export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxLookup,
        DxForm,
        DxItem,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule,
        DxMasterDetail,
        MasterDetail,
        DxFormItem
    },
    data() {
        return {
            procedimientos: null,
            dataSourceECAElegir: null,
            dataSourceUsuarios: null,
            dataSourceRoles: null,
            dataSourceEstados: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Guía Evaluación',
                showTitle: true,
                width: 700,
                height: 'auto',
            },
            suppliersData: createStore({
                key: 'SupplierID',
            }),
        };
    },
    created() {
        this.guiaevaluacionService = new GuiaEvaluacionService();
        this.servicioService = new ServicioService();
        this.estadoService = new EstadoService();

    },
    mounted() {
        this.loadDataInfo();
    },
    methods: {
        loadDataInfo() {
            this.servicioService.getServicio().then(data => this.dataSourceRoles = data);
            this.guiaevaluacionService.getGuiaEvaluacion().then(data => this.dataSourceUsuarios = data);
            this.guiaevaluacionService.getGuiaEvaluacionBase().then(data => this.dataSourceECAElegir = data);
            this.estadoService.getEstados().then(data => this.dataSourceEstados = data);
            
        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },
        editProcedure(e) {
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let vactivo = e.data.bactivo == true ? 1 : 0;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pservicioid: e.data.servicioid,
                pdescripcioncorta: e.data.descripcioncorta,
                pdescripcionlarga: e.data.descripcionlarga,
                pbactivo: vactivo,
                pusuarioregistroid: idusuario,
                pestadoid:e.data.estadoid,
                pbgenerico:0,
                pguiaevaluacion:e.data.guiaevaluacionid,
            });


           
            this.guiaevaluacionService.updateGuiaEvaluacion(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        
                        this.loadDataInfo();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);
                        this.loadDataInfo();

                    }


                    });
        },
        deleteProcedure(e) {
            let myJSON = JSON.stringify({
                pid: e.data.id,
            });

 

            this.guiaevaluacionService.deleteGuiaEvaluacion(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha eliminado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        
                        this.loadDataInfo();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);
                        this.loadDataInfo();

                    }


                    });
        },
        createProcedure(e) {


            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let vactivo = e.data.bactivo == true ? 1 : 0;
            let myJSON = JSON.stringify({
                pservicioid: 1,
                pdescripcioncorta: e.data.descripcioncorta,
                pdescripcionlarga: e.data.descripcionlarga,
                pbactivo: vactivo,
                pusuarioregistroid: idusuario,
                pestadoid:e.data.estadoid,
                pbgenerico:0,
                pguiaevaluacion:e.data.guiaevaluacionid,
             
            });

            //console.log(myJSON);

            this.guiaevaluacionService.createGuiaEvaluacion(myJSON).then(data => {

                if (data.err == 0) {
                    this.type = 'success';
                    this.message = 'Se ha creado el registro';
                    this.isVisible = true;

                    notify({
                        message: this.message,
                    }, this.type, 5000);
                    
                    this.loadDataInfo();
                } else {

                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                    }, this.type, 4000);
                    this.loadDataInfo();

                }


                });
        },
        onInitNewRow(e) {
            e.data.bactivo = true;
        },
    },
};
