
import {
    DxGroupItem,
    DxSimpleItem,
    DxLabel,
    DxItem,
    DxForm,

} from 'devextreme-vue/form';


import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxLookup,
    DxFormItem,
    DxValidationRule,
    DxButton

} from 'devextreme-vue/data-grid';




import { DxSelectBox } from 'devextreme-vue/select-box';
import axios from 'axios';
import DxTabPanel from "devextreme-vue/tab-panel";
import { customers } from './../data/dataperfil.js';
import 'devextreme-vue/text-area';
import notify from 'devextreme/ui/notify';
import Cookies from "js-cookie";
import auth from "../auth";
window.Cookies = Cookies;

import OrdenTrabajoResultadoService from '../services/ordentrabajoresultado-service';
import OrdenTrabajoDocumentoService from '../services/ordentrabajodocumento-service';
import OrdenTrabajoProcedimientoMedidasService from '../services/ordentrabajoprocedimientomedidas-service';
import OrdenTrabajoProcedimientoTerminosService from '../services/ordentrabajoprocedimientoterminos-service';
import OrdenTrabajoProcedimientoCondicionalesService from '../services/ordentrabajoprocedimientocondicionales-service';
import OrdenTrabajoProcedimientoProgramasService from '../services/ordentrabajoprocedimientoprogramas-service';
import OrdenTrabajoEtapaService from '../services/ordentrabajoetapa-service';
import TipoDocumentoService from '../services/tipodocumento-service';
import EstatusService from '../services/estatus-service';
import OrdenTrabajoPlanService from '../services/ordentrabajoplan-service';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { exportGantt as exportGanttToPdf } from 'devextreme/pdf_exporter';


import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxTextBox } from 'devextreme-vue/text-box';
import moment from 'moment';
const sendRequest = function (value) {
    const validEmail = 'test@dx-email.com';
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(value === validEmail);
        }, 1000);
    });
};
const ganttRef = 'gantt';
export default {
    components: {
        DxGroupItem,
        DxSimpleItem,
        DxLookup,
        //DxEmailRule,
        DxDataGrid,
        DxTabPanel,
        DxItem,
        DxEditing,
        DxColumn,
        DxPaging,
        DxForm,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxLabel,
        DxFormItem,
        DxSelectBox,


        DxFileUploader,
        DxTextBox,
        DxValidationRule,

        DxButton,


    },
    data() {
        return {
            ganttRef,
            loading: true,
            tasks: null,
            taskTitlePosition: 'outside',
            dependencies: null,
            resources: null,
            resourceAssignments: null,


            dataSourceEstatus: null,
            dataSourceCambioEstatus: null,
            dataSource: customers,
            dataSourceEtapaCierre: null,
            dataSourceEntrega: null,
            dataSourceListados: null,
            dataSourceDocumentacion: null,
            dataSourceEtapa: null,
            dataSourceHistorial: null,
            dataSourceDocumento: null,
            dataSourceAccion: null,
            dataSourceTipoMedida: null,
            dataSourceFactorAmbiental: null,
            dataSourceMedidasMitigacion: null,
            dataSourceMedidasSeguimiento: null,
            dataIdAccionSeleccionado: null,
            dataSourceTermino: null,
            dataSourceCondicion: null,
            dataSourcePrograma: null,
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptionsListado: {
                title: 'Resultados',
                showTitle: true,
                width: 650,
                height: 400,
            },
            popupOptions: {
                title: 'Resultados',
                showTitle: true,
                width: 650,
                height: 500,
            }, popupOptions2: {
                title: 'Resultados',
                showTitle: true,
                width: 500,
                height: 500,
            },
            popupOptions3: {
                title: 'Cambio Etapa',
                showTitle: true,
                width: 400,
                height: 200,
            },
            popupOptionsEstatus: {
                title: 'Estatus',
                showTitle: true,
                width: 400,
                height: 200,
            },
            valetapa: 0,
            valcierre: '',
            valtipodocumento: 0,
            valnombredocumento: '',
            columns: ['CompanyName', 'City', 'State', 'Phone', 'Fax'],
            customer: null,//service.getCustomer(),
            buttonOptions: {
                text: 'Actualizar Orden Trabajo',
                type: 'success',
                useSubmitBehavior: true,
            },
            readonlyOptions: {
                disabled: true,
            },
            passwordOptions: {
                mode: 'password',
            },
            dateBoxOptions: {
                invalidDateMessage:
                    'Campos tipo fecha debe tener el siguiente formato: MM/dd/yyyy',
            },
            checkBoxOptions: {
                text: 'I agree to the Terms and Conditions',
                value: false,
            },
            phoneEditorOptions: {
                mask: '+1 (X00) 000-0000',
                maskRules: {
                    X: /[02-9]/,
                },
                maskInvalidMessage: 'The phone must have a correct USA phone format',
            },
            currencyOptions: {
                mask: '#,##0.00',
                maskInvalidMessage: 'The phone must have a correct USA phone format',

            },
            esCampoRequerido: true,
            esCampoRequerido2: true,
            opcionesItem: {
                width: '200%',
            },

            maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
            namePattern: /^[^0-9]+$/,
            cityPattern: /^[^0-9]+$/,
            phonePattern: /^[02-9]\d{9}$/,
            numsPattern: /[0-9]/,
            vidot: null,
            backendURL: process.env.VUE_APP_APIURL,
            exportButtonOptions: {
                hint: 'Export to PDF',
                icon: 'exportpdf',
                stylingMode: 'text',
                onClick: () => {
                    this.exportGantt();
                },
            },
        };
    },
    computed: {
        gantt() {
            return this.$refs[ganttRef].instance;
        },
    },
    created() {
        this.ordentrabajoresultadoService = new OrdenTrabajoResultadoService();
        this.ordentrabajoetapaService = new OrdenTrabajoEtapaService();
        this.ordentrabajodocumentoService = new OrdenTrabajoDocumentoService();
        this.ordentrabajoprocedimientomedidasService = new OrdenTrabajoProcedimientoMedidasService();
        this.ordentrabajoprocedimientoterminosService = new OrdenTrabajoProcedimientoTerminosService();
        this.ordentrabajoprocedimientocondicionalesService = new OrdenTrabajoProcedimientoCondicionalesService();
        this.ordentrabajoprocedimientoprogramasService = new OrdenTrabajoProcedimientoProgramasService();
        this.ordentrabajoplanService = new OrdenTrabajoPlanService();
        this.estatusService = new EstatusService();
        this.tipodocumentoService = new TipoDocumentoService();
        this.vidot = this.$route.params.id;

    },
    mounted() {


        this.loadDataInfo();
    },
    methods: {
        exportGantt() {

            exportGanttToPdf({
                component: this.gantt,
                createDocumentMethod: (args) => new jsPDF(args),
                landscape: 'true',
                exportMode: 'all',
                dateRange: "visible",
                margins: { left: 0, top: 180, right: 0, botoom: 0 }
            }).then((doc) => {
                doc.setProperties({
                    title: 'Title',
                    subject: 'This is the subject',
                    author: 'James Hall',
                    keywords: 'generated, javascript, web 2.0, ajax',
                    creator: 'MEEE'
                });

                doc.setPage(1);
                doc.setFontSize(20);

                var img = new Image();
                img.src = require("../assets/logo.png");
                doc.addImage(img, "PNG", 0, 0, 290, 140);

                doc.setFontSize(12);
                doc.text("PLANTA:", 15, 168);
                doc.text("FECHA:", 560, 168);

                doc.text(this.pdfRazon ? this.pdfRazon : '', 85, 168);
                doc.text(this.pdfFechaInicio ? this.pdfFechaInicio : '', 625, 168);
                doc.text("PLAN DE TRABAJO", 300, 148);
                doc.save('gantt.pdf')
            });
        },

        onUploaded(e) {
            let id = this.vidot;
            let res = JSON.parse(e.request.responseText);
            let archivosubido = res.image_name;
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let myJSON = JSON.stringify({
                ptipodocumentoid: this.valtipodocumento,
                pnombredocumento: this.valnombredocumento,
                pruta: archivosubido,
                pordentrabajoid: id,
                pusuarioregistroid: idusuario
            });

            let err = 0;
            let msgerror = '';

            if (this.valtipodocumento == null || this.valtipodocumento == '' || this.valtipodocumento == 'undefined') {
                err = 1;
                msgerror = 'Ingresar tipo documento';
            }

            if (err == 0) {
                if (this.valnombredocumento == null || this.valnombredocumento == '' || this.valnombredocumento == 'undefined') {
                    err = 1;
                    msgerror = 'Ingresar nombre documento';
                }
            }

            if (err == 0) {
                this.ordentrabajodocumentoService.createOrdenTrabajoDocumento(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible = false;
                        this.valtipodocumento = null;
                        this.valnombredocumento = null;
                        this.loadDataInfo();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                    //this.loadDataInfo();

                });
            } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                    message: this.message,
                }, this.type, 4000);

            }
        },
        onUploadError(e) {
            let xhttp = e.request;
            if (xhttp.status === 400) {
                e.message = e.error.responseText;
            }
            if (xhttp.readyState === 4 && xhttp.status === 0) {
                e.message = "Conexión rechazada";
            }
        },

        editRegisterCambioEtapa(e) {
            let id = this.vidot;
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let myJSON = JSON.stringify({
                pid: id,
                pusuario: idusuario,
                petapaid: e.data.etapaid,
            });

            this.ordentrabajoresultadoService.getOrdenTrabajoCierreEtapa(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'OT Etapa ha sido actualizada',
                    }, 'success', 9000);
                    //this.$router.push({ name: 'ordentrabajoresultadoconguia', params: { id: this.vidot } });
                    this.loadDataInfo();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                    // document.location.reload();

                }


            });


        },
        editRegisterCambioEstatus(e) {

            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let myJSON = JSON.stringify({
                pid: this.vidot,
                pusuario: idusuario,
                pobservacion: e.data.observacion,
                pestatusid: e.data.estatusid,
            });
            console.log("UPDATE");
            console.log("UPDATE", e);
            console.log("UPDATE", myJSON);
            this.ordentrabajoresultadoService.getOrdenTrabajoCierre(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'OT ha sido actualizada',
                    }, 'success', 9000);
                    this.loadDataInfo();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                    // document.location.reload();

                }


            });

        },

        onAccionSeleccionada({ selectedRowsData }) {
            const accion = selectedRowsData[0];

            this.dataIdAccionSeleccionado = accion.id;

            let myJSONID = JSON.stringify({
                pid: accion.id,
            });

            this.ordentrabajoprocedimientomedidasService.getAccionMedida(myJSONID).then(data => {
                this.dataSourceMedidasMitigacion = data;
            });

        },
        agregarAccion(e) {
            let myJSONID = JSON.stringify({
                pordentrabajoid: this.vidot,
                pfactorambientalid: e.data.factorambientalid,
                pcomponentes: e.data.componentes,
                pacciones: e.data.acciones,
                petapas: e.data.etapas
            });

            this.ordentrabajoprocedimientomedidasService.createAccion(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Accion ha sido actualizada',
                    }, 'success', 9000);
                    this.loadDataAccion();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });
        },
        eliminarAccion(e) {

            let myJSONID = JSON.stringify({
                pid: e.data.id
            });

            this.ordentrabajoprocedimientomedidasService.deleteAccion(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'La acción ha sido eliminada',
                    }, 'success', 9000);
                    this.loadDataAccion();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });

        },
        editarAccion(e) {

            let myJSONID = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: this.vidot,
                pfactorambientalid: e.data.factorambientalid,
                pcomponentes: e.data.componentes,
                pacciones: e.data.acciones,
                petapas: e.data.etapas
            });


            this.ordentrabajoprocedimientomedidasService.updateAccion(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'La acción ha sido actualizada',
                    }, 'success', 9000);
                    this.loadDataAccion();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });
        },

        loadDataAccion() {
          
            let myJSON = JSON.stringify({
              pid: this.vidot
            });
      
            this.ordentrabajoprocedimientomedidasService.getAccion(myJSON).then(data => {
              this.dataSourceAccion = data;
            });
           
        },
        agregarMedida(e) {

            
             
            if(this.dataIdAccionSeleccionado===null || this.dataIdAccionSeleccionado==null){
                
                notify({
                        message: 'Debe seleccionar una acción',
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                this.dataSourceMedidasMitigacion=null;
            }
            else{
                let myJSONID = JSON.stringify({
                pordentrabajoestudioaccionid: this.dataIdAccionSeleccionado,
                ptipomedidaid: e.data.tipomedidaid,
                pmedida: e.data.medida
            });

            this.ordentrabajoprocedimientomedidasService.createMedida(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Medida ha sido insertada',
                    }, 'success', 9000);
                    this.loadDataMedida();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });

            }
           
        },
        eliminarMedida(e) {
            let myJSONID = JSON.stringify({
                pid: e.data.id
            });

            this.ordentrabajoprocedimientomedidasService.deleteMedida(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'La medida ha sido eliminada',
                    }, 'success', 9000);
                    this.loadDataMedida();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });

        },
        editarMedida(e) {
            console.log("DDDDDDDDDDDDDDDDDDDDD",this.dataIdAccionSeleccionado);

            if(this.dataIdAccionSeleccionado===null || this.dataIdAccionSeleccionado==null){
                
                notify({
                        message: 'Debe seleccionar una acción',
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                    this.dataSourceMedidasMitigacion=null;
            }
            else{
                let myJSONID = JSON.stringify({
                pid: e.data.id,
                ptipomedidaid: e.data.tipomedidaid,
                pmedida: e.data.medida
            });

            this.ordentrabajoprocedimientomedidasService.updateMedida(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'La medida ha sido actualizada',
                    }, 'success', 9000);
                    this.loadDataMedida();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });

            }
            
        },
        loadDataMedida() {

            let myJSONID = JSON.stringify({
                pid: this.dataIdAccionSeleccionado,
            });

            this.ordentrabajoprocedimientomedidasService.getAccionMedida(myJSONID).then(data => {
                this.dataSourceMedidasMitigacion = data;
            });
        },
        editarMedidaSeguimiento(e) {

            let myJSONID = JSON.stringify({
                pid: e.data.id,
                pfechalimite: moment(e.data.fechalimite).format('YYYY-MM-DD'),
                pcompletado: e.data.completado,
                pobservacion: e.data.observacion
            });

            this.ordentrabajoprocedimientomedidasService.updateMedidaSeguimiento(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'La medida seguimiento ha sido actualizada',
                    }, 'success', 9000);
                    this.loadDataMedidaSeguimiento();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });
        },
        loadDataMedidaSeguimiento() {
            let myJSON = JSON.stringify({
                pid: this.vidot
            });

            this.ordentrabajoprocedimientomedidasService.getMedidaSeguimiento(myJSON).then(data => {
                this.dataSourceMedidasSeguimiento = data;

            })
        },
        agregarTermino(e) {
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;
            let myJSONID = JSON.stringify({
                pordentrabajoid: this.vidot,
                pusuarioregistroid: idusuario,
                pnombre: e.data.nombre,
                pdescripcion: e.data.descripcion,
                pfechalimite: moment(e.data.fechalimite).format('YYYY-MM-DD'),
                pcompletado: e.data.completado,
                pobservacion: e.data.observacion,
                pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
                pevidencia_ruta: e.data.evidencia_ruta
            });

            this.ordentrabajoprocedimientoterminosService.createTermino(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Termino ha sido agregado',
                    }, 'success', 9000);
                    this.loadDataTerminos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }
            });


        },
        eliminarTermino(e) {

            let myJSONID = JSON.stringify({
                pid: e.data.id
            });

            this.ordentrabajoprocedimientoterminosService.deleteTermino(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'El termino ha sido eliminado',
                    }, 'success', 9000);
                    this.loadDataTerminos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });

        },
        editarTermino(e) {
            let myJSONID = JSON.stringify({
                pid: e.data.id,
                pnombre: e.data.nombre,
                pdescripcion: e.data.descripcion,
                pfechalimite: moment(e.data.fechalimite).format('YYYY-MM-DD'),
                pcompletado: e.data.completado,
                pobservacion: e.data.observacion,
                pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
                pevidencia_ruta: e.data.evidencia_ruta
            });

            this.ordentrabajoprocedimientoterminosService.updateTermino(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Termino ha sido actualizado',
                    }, 'success', 9000);
                    this.loadDataTerminos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }
            });


        },
        loadDataTerminos() {
            let myJSON = JSON.stringify({
                pid: this.vidot
            });

            this.ordentrabajoprocedimientoterminosService.getTermino(myJSON).then(data => {
                this.dataSourceTermino = data;

            });
        },
        agregarCondicion(e) {
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;
            let myJSONID = JSON.stringify({
                pordentrabajoid: this.vidot,
                pusuarioregistroid: idusuario,
                pnombre: e.data.nombre,
                pdescripcion: e.data.descripcion,
                pfechalimite: moment(e.data.fechalimite).format('YYYY-MM-DD'),
                pcompletado: e.data.completado,
                pobservacion: e.data.observacion,
                pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
                pevidencia_ruta: e.data.evidencia_ruta
            });

            this.ordentrabajoprocedimientocondicionalesService.createCondicional(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Condicional ha sido agregada',
                    }, 'success', 9000);
                    this.loadDataCondicionales();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }
            });


        },
        eliminarCondicion(e) {

            let myJSONID = JSON.stringify({
                pid: e.data.id
            });

            this.ordentrabajoprocedimientocondicionalesService.deleteCondicional(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'La condicional ha sido eliminada',
                    }, 'success', 9000);
                    this.loadDataCondicionales();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });

        },

        onResourceAssigned(e) {
            //ASIGNED {taskId: 104, resourceId: 3}

            let myJSON = JSON.stringify({
                pordentrabajoid: this.vidot,
                pordentrabajoplanid: e.values.taskId,
                pclientecontactoid: e.values.resourceId,

            });


            if (e.values.taskID != 0) {
                this.ordentrabajoplanService.createOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible = false;
                        this.loadDataInfo();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                    //this.loadDataInfo();

                });
            }
        }, onResourceUnassigned(e) {

            let myJSON = JSON.stringify({
                pid: e.key,


            });

            if (e.key != 0) {
                this.ordentrabajoplanService.deleteOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible = false;
                        this.loadDataInfo();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                    //this.loadDataInfo();

                });
            }
        },
        onTaskDeleted(e) {
            let err = 0;
            if (e.key != 0) {


                let myJSON = JSON.stringify({
                    pid: e.key,
                    pot: this.vidot
                });





                if (e.key != 0) {

                    if (err == 0) {


                        this.ordentrabajoplanService.deleteOrdenTrabajoPlan(myJSON).then(data => {

                            if (data.err == 0) {
                                this.type = 'success';
                                this.message = 'Se ha eliminado el registro';
                                this.isVisible = true;

                                notify({
                                    message: this.message,
                                }, this.type, 5000);
                                this.popupVisible = false;
                                this.loadDataInfo();
                            } else {

                                this.type = 'error';
                                this.message = 'Ocurrio un error ' + data.msg;
                                this.isVisible = true;
                                notify({
                                    message: 'Ocurrio un error ' + data.msg,
                                }, this.type, 4000);


                            }


                        });




                    } else {
                        this.type = 'error';
                        this.message = 'error';
                        this.isVisible = true;
                        notify({
                            message: this.message,
                        }, this.type, 4000);

                    }
                    e.cancel = true;
                }
            }
        },

        onTaskInserted(e) {

            let err = 0;
            let msgerror = '';

            let vfechainicio = '';
            let vfechafin = '';
            let vprogreso = '0';
            let vdescripcion = '';
            let vpadre = '';



            // Si esta indefinido no hubo cambio
            // De lo contrario si hubo cambio

            if (e.values.parentId === undefined || e.values.parentId === '' || e.values.parentId === 'null' || e.values.parentId === null) {
                vpadre = "-1";

            }
            else {
                vpadre = e.values.parentId;
            }
            vdescripcion = e.values.title;
            vfechainicio = moment(e.values.start).format('YYYY-MM-DD');
            vfechafin = moment(e.values.end).format('YYYY-MM-DD');


            // let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let myJSON = JSON.stringify({

                pordentrabajoid: this.vidot,
                vpadre: vpadre,
                pdescripcion: vdescripcion,
                pfechainicio: vfechainicio,
                pfechafin: vfechafin,
                pprogreso: vprogreso
            });



            if (e.key != 0) {

                if (err == 0) {
                    this.ordentrabajoplanService.createOrdenTrabajoPlan(myJSON).then(data => {

                        if (data.err == 0) {
                            this.type = 'success';
                            this.message = 'Se ha insertado el registro';
                            this.isVisible = true;

                            notify({
                                message: this.message,
                            }, this.type, 5000);
                            this.popupVisible = false;
                            this.loadDataInfo();
                        } else {

                            this.type = 'error';
                            this.message = 'Ocurrio un error ' + data.msg;
                            this.isVisible = true;
                            notify({
                                message: 'Ocurrio un error ' + data.msg,
                            }, this.type, 4000);


                        }
                        //this.loadDataInfo();

                    });
                } else {
                    this.type = 'error';
                    this.message = msgerror;
                    this.isVisible = true;
                    notify({
                        message: this.message,
                    }, this.type, 4000);

                }
                e.cancel = true;
            }



        },
        onTaskUpdating(e) {



            let err = 0;
            let msgerror = '';

            let vfechainicio = '';
            let vfechafin = '';
            let vprogreso = '';
            let vdescripcion = '';



            // Si esta indefinido no hubo cambio
            // De lo contrario si hubo cambio

            if (e.newValues.title === undefined || e.newValues.title === '' || e.newValues.title === 'null' || e.newValues.title === null) {
                //vfechainicio = e.values.start.getFullYear() + '-' + (e.values.start.getMonth() + 1) + '-' + e.values.start.getDate();
                vdescripcion = e.values.title;

            }
            else {
                if (e.newValues.title == undefined || e.newValues.title == null || e.newValues.title == '') {
                    err = 1;
                    msgerror = 'Ingresar descripción';
                }
                else {
                    vdescripcion = e.newValues.title;

                }
            }


            if (e.newValues.start === undefined || e.newValues.start === '' || e.newValues.start === 'null' || e.newValues.start === null) {
                //vfechainicio = e.values.start.getFullYear() + '-' + (e.values.start.getMonth() + 1) + '-' + e.values.start.getDate();
                vfechainicio = moment(e.values.start).format('YYYY-MM-DD');

            }
            else {
                if (e.newValues.start == undefined || e.newValues.start == null || e.newValues.start == '') {
                    err = 1;
                    msgerror = 'Ingresar fecha inicio';
                }
                else {
                    vfechainicio = moment(e.newValues.start).format('YYYY-MM-DD');

                }
            }

            if (e.newValues.end === undefined || e.newValues.end === '' || e.newValues.end === 'null' || e.newValues.end === null) {
                //vfechafin =  e.values.end.getFullYear() + '-' + (e.values.end.getMonth() + 1) + '-' + e.values.end.getDate();
                vfechafin = moment(e.values.end).format('YYYY-MM-DD');
            }
            else {
                if (e.newValues.end == undefined || e.newValues.end == null || e.newValues.end == '') {
                    err = 1;
                    msgerror = 'Ingresar fecha fin';
                }
                else {
                    //vfechafin = e.newValues.end.getFullYear() + '-' + (e.newValues.end.getMonth() + 1) + '-' + e.newValues.end.getDate();
                    vfechafin = moment(e.newValues.end).format('YYYY-MM-DD');
                }
            }

            if (e.newValues.progress === undefined || e.newValues.progress === '' || e.newValues.progress === 'null' || e.newValues.progress === null) {


                vprogreso = e.values.progress;
            }
            else {
                if (e.newValues.progress === undefined || e.newValues.progress == null || e.newValues.progress == '') {
                    err = 1;
                    msgerror = 'Ingresar progreso';
                }
                else {

                    vprogreso = e.newValues.progress;
                }
            }



            // let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let myJSON = JSON.stringify({
                pid: e.key,
                pordentrabajoid: this.vidot,
                pdescripcion: vdescripcion,
                pfechainicio: vfechainicio,
                pfechafin: vfechafin,
                pprogreso: vprogreso
            });



            if (e.key != 0) {

                if (err == 0) {
                    this.ordentrabajoplanService.updateOrdenTrabajoPlan(myJSON).then(data => {

                        if (data.err == 0) {
                            this.type = 'success';
                            this.message = 'Se ha actualizado el registro';
                            this.isVisible = true;

                            notify({
                                message: this.message,
                            }, this.type, 5000);
                            this.popupVisible = false;
                            this.loadDataInfo();
                        } else {

                            this.type = 'error';
                            this.message = 'Ocurrio un error ' + data.msg;
                            this.isVisible = true;
                            notify({
                                message: 'Ocurrio un error ' + data.msg,
                            }, this.type, 4000);


                        }
                        //this.loadDataInfo();

                    });
                } else {
                    this.type = 'error';
                    this.message = msgerror;
                    this.isVisible = true;
                    notify({
                        message: this.message,
                    }, this.type, 4000);

                }
                e.cancel = true;
            }
        },



        editarCondicion(e) {
            let myJSONID = JSON.stringify({
                pid: e.data.id,
                pnombre: e.data.nombre,
                pdescripcion: e.data.descripcion,
                pfechalimite: moment(e.data.fechalimite).format('YYYY-MM-DD'),
                pcompletado: e.data.completado,
                pobservacion: e.data.observacion,
                pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
                pevidencia_ruta: e.data.evidencia_ruta
            });

            this.ordentrabajoprocedimientocondicionalesService.updateCondicional(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Condicional ha sido actualizada',
                    }, 'success', 9000);
                    this.loadDataCondicionales();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }
            });


        },
        loadDataCondicionales() {
            let myJSON = JSON.stringify({
                pid: this.vidot
            });

            this.ordentrabajoprocedimientocondicionalesService.getCondicional(myJSON).then(data => {
                this.dataSourceCondicion = data;

            });
        },
        agregarPrograma(e) {
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;
            let myJSONID = JSON.stringify({
                pordentrabajoid: this.vidot,
                pusuarioregistroid: idusuario,
                pnombre: e.data.nombre,
                pdescripcion: e.data.descripcion,
                pfechalimite: moment(e.data.fechalimite).format('YYYY-MM-DD'),
                pcompletado: e.data.completado,
                pobservacion: e.data.observacion,
                pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
                pevidencia_ruta: e.data.evidencia_ruta
            });

            this.ordentrabajoprocedimientoprogramasService.createPrograma(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Programa ha sido agregado',
                    }, 'success', 9000);
                    this.loadDataProgramas();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }
            });


        },
        eliminarPrograma(e) {

            let myJSONID = JSON.stringify({
                pid: e.data.id
            });

            this.ordentrabajoprocedimientoprogramasService.deletePrograma(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'El termino ha sido eliminado',
                    }, 'success', 9000);
                    this.loadDataProgramas();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });

        },
        editarPrograma(e) {
            let myJSONID = JSON.stringify({
                pid: e.data.id,
                pnombre: e.data.nombre,
                pdescripcion: e.data.descripcion,
                pfechalimite: moment(e.data.fechalimite).format('YYYY-MM-DD'),
                pcompletado: e.data.completado,
                pobservacion: e.data.observacion,
                pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
                pevidencia_ruta: e.data.evidencia_ruta
            });

            this.ordentrabajoprocedimientoprogramasService.updatePrograma(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Termino ha sido actualizado',
                    }, 'success', 9000);
                    this.loadDataProgramas();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }
            });


        },
        loadDataProgramas() {
            let myJSON = JSON.stringify({
                pid: this.vidot
            });

            this.ordentrabajoprocedimientoprogramasService.getPrograma(myJSON).then(data => {
                this.dataSourcePrograma = data;

            });
        },
        loadDataInfo() {

            let myJSON2 = JSON.stringify({
                pid: this.vidot,
            });

            this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId(myJSON2).then(data => {
                this.customer = data
                this.pdfRazon = data.razonsocial;
                this.pdfFechaInicio = data.fechainicioplaneada;
            }

            );
            this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId2(myJSON2).then(data => {
                this.dataSourceCambioEstatus = data
            }

            );



            this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId2(myJSON2).then(data => {
                this.dataSourceEtapaCierre = data;
            }

            );


            this.tipodocumentoService.getTipoDocumento().then(data => {
                this.dataSourceDocumento = data;

            });



            //this.usuarioService.getUsuario().then(data => this.dataSourceUsuarios = data);
            let id = this.vidot;

            let myJSON = JSON.stringify({
                pid: id,

            });


           
            this.ordentrabajodocumentoService.getOrdenTrabajoDocumento(myJSON).then(data => {
                this.dataSourceDocumentacion = data;
            });


           

            /**
            this.ordentrabajoresultadoService.getOrdenTrabajoResultadoListadoDocumentacion(myJSON).then(data => {
                data.forEach(
                    element => {

                        if (element.entregado == 1) {
                            element.entregado = true;
                        } else {
                            element.entregado = false;
                        }
                        if (element.aplica == 1) {
                            element.aplica = true;
                        } else {
                            element.aplica = false;
                        }

                    });
                this.dataSourceListados = data;

            });


            this.ordentrabajoresultadoService.getOrdenTrabajoResultadoListadoInformacion(myJSON).then(data => {
                data.forEach(
                    element => {

                        if (element.entregado == 1) {
                            element.entregado = true;
                        } else {
                            element.entregado = false;
                        }
                        if (element.aplica == 1) {
                            element.aplica = true;
                        } else {
                            element.aplica = false;
                        }

                    });
                this.dataSourceEntrega = data;

            });


       
            this.ordentrabajoetapaService.getOrdenTrabajoEtapaConsulta(myJSON).then(data => {
                this.dataSourceEtapa = data;
            }
            );
            this.estatusService.getGrupoEstatusOrden(myJSON).then(data => this.dataSourceEstatus = data);
            **/
             
            ///SEGUIMIENTO
            this.loadDataAccion();
            // this.ordentrabajoprocedimientomedidasService.getAccion(myJSON).then(data => {
            //  this.dataSourceAccion = data;
            //});
            
            this.ordentrabajoprocedimientomedidasService.getTipoMedida().then(data => {
              this.dataSourceTipoMedida = data;
            });
      
            this.ordentrabajoprocedimientomedidasService.getFactorAmbiental().then(data => {
              this.dataSourceFactorAmbiental = data;
            });
      
            let myJSONID = JSON.stringify({
                pid: 0,
            });

            this.ordentrabajoprocedimientomedidasService.getAccionMedida(myJSONID).then(data => {
                this.dataSourceMedidasMitigacion = data;
            });
      
            this.ordentrabajoprocedimientomedidasService.getMedidaSeguimiento(myJSON).then(data => {
              this.dataSourceMedidasSeguimiento = data;
      
            })
      
            this.ordentrabajoprocedimientoterminosService.getTermino(myJSON).then(data => {
              this.dataSourceTermino = data;
      
            });
      
            this.ordentrabajoprocedimientocondicionalesService.getCondicional(myJSON).then(data => {
              this.dataSourceCondicion = data;
      
            });
      
            this.ordentrabajoprocedimientoprogramasService.getPrograma(myJSON).then(data => {
              this.dataSourcePrograma = data;
      
            });
            //////////////////////////////////

            // GANTT
            /**
            this.ordentrabajoplanService.getOrdenTrabajoPlanTareas(myJSON).then(data => {
                this.tasks = data;

            }
            );

            this.ordentrabajoplanService.getOrdenTrabajoPlanDependencias(myJSON).then(data => {
                this.dependencies = data
            }
            );


            this.ordentrabajoplanService.getOrdenTrabajoPlanContactoCliente(myJSON).then(data => { this.resources = data });


            this.ordentrabajoplanService.getOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
                this.resourceAssignments = data
            }
            );
            **/
            // FIN GANTT

        },
        passwordComparison() {
            return this.customer.Password;
        },
        checkComparison() {
            return true;
        },
        asyncValidation(params) {
            return sendRequest(params.value);
        },
        handleSubmit(e) {




            let myJSON = JSON.stringify({
                pid: this.customer.ID,
                pnombre: this.customer.Nombre,
                ppaterno: this.customer.Paterno,
                pmaterno: this.customer.Materno,
                pusuario: this.customer.Usuario,
                pcontrasena: this.customer.Password,
                pcorreo: this.customer.Email,
            });


            this.usuarioService.updateUsuarioPerfil(myJSON).then(data => {

                if (data.err == 0) {
                    Cookies.set("ets_user", "null");
                    let myJSON = JSON.stringify({
                        id: this.customer.ID,
                        nombre: this.customer.Nombre + ' ' + this.customer.Paterno + ' ' + this.customer.Materno,
                        correo: this.customer.Email,
                    });
                    Cookies.set("ets_user", myJSON);
                    auth.logIn(this.customer.Nombre + ' ' + this.customer.Paterno + ' ' + this.customer.Materno);
                    notify({
                        message: 'Perfil ha sido actualizado',
                    }, 'success', 9000);
                    document.location.reload();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                    document.location.reload();

                }


            });

            e.preventDefault();
        },
        downloadfile(e) {
            let name_file = e.row.data.ruta;


            axios({
                url: process.env.VUE_APP_APIURL + '/api/archivodownload?name_file=' + name_file,
                method: 'GET',
                responseType: 'blob',

            }).then((res) => {
                var url = window.URL.createObjectURL((res.data));
                var namepdf = name_file;
                //var cliente = "";
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', namepdf);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });



        },
        editProcedureListadoInformacion(e) {

            let ventregado = e.data.entregado == true ? 1 : 0;
            let vaplica = e.data.aplica == true ? 1 : 0;
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let myJSON;
            if (e.data.aplica) {
                myJSON = JSON.stringify({
                    pid: e.data.id,
                    pentregado: null,
                    pfechaentrega: '9999-01-01',
                    pfechacompromisoentrega: '9999-01-01',
                    pusuarioregistroid: idusuario,
                    pobservaciones: '',
                    precibio: '',
                    paplica: vaplica,
                });
            } else {
                myJSON = JSON.stringify({
                    pid: e.data.id,
                    pentregado: ventregado,
                    pfechaentrega: (e.data.fechaentrega == '') ? '9999-01-01' : moment(e.data.fechaentrega).format('YYYY-MM-DD'),
                    pfechacompromisoentrega: (e.data.fechacompromisoentrega == '') ? '9999-01-01' : moment(e.data.fechacompromisoentrega).format('YYYY-MM-DD'),
                    pusuarioregistroid: idusuario,
                    pobservaciones: (e.data.observaciones == '') ? '' : e.data.observaciones,
                    precibio: (e.data.recibio == '') ? '' : e.data.recibio,
                    paplica: vaplica,
                });
            }

            this.esCampoRequerido = true;

            this.ordentrabajoresultadoService.updateOrdenTrabajoListadoInformacion(myJSON).then(data => {

                if (data.err == 0) {
                    this.type = 'success';
                    this.message = 'Se ha actualizado el registro';
                    this.isVisible = true;

                    notify({
                        message: this.message,
                    }, this.type, 9000);
                    //this.loadDataInfo();
                } else {

                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                }
                this.loadDataInfo();

            });

        },
        editProcedureListadoDocumentacion(e) {

            let ventregado = e.data.entregado == true ? 1 : 0;
            let vaplica = e.data.aplica == true ? 1 : 0;
            let myJSON;
            if (e.data.aplica) {
                myJSON = JSON.stringify({
                    pid: e.data.id,
                    pentregado: null,
                    pfechaentrega: null,
                    paplica: vaplica,

                });
            } else {
                myJSON = JSON.stringify({
                    pid: e.data.id,
                    pentregado: ventregado,
                    pfechaentrega: moment(e.data.fechaentrega).format('YYYY-MM-DD'),
                    paplica: vaplica,

                });
            }

            this.esCampoRequerido2 = true;

            this.ordentrabajoresultadoService.updateOrdenTrabajoListadoDocumentacion(myJSON).then(data => {

                if (data.err == 0) {
                    this.type = 'success';
                    this.message = 'Se ha actualizado el registro';
                    this.isVisible = true;

                    notify({
                        message: this.message,
                    }, this.type, 9000);
                    this.loadDataInfo();
                } else {

                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                }
                this.loadDataInfo();

            });

        },
        editorPreparingListados(e) {
            if (e.dataField === 'aplica' && e.parentType === 'dataRow') {

                e.editorOptions.onValueChanged = (innerEvent) => {
                    //this.$forceUpdate();
                    // innerEvent.value contiene el nuevo valor del checkbox
                    let valor = innerEvent.value;
                    this.esCampoRequerido = !valor;
                    e.setValue(valor);

                };
            }
        },
        editorPreparingDocumentos(e) {
            if (e.dataField === 'aplica' && e.parentType === 'dataRow') {

                e.editorOptions.onValueChanged = (innerEvent) => {
                    //this.$forceUpdate();
                    // innerEvent.value contiene el nuevo valor del checkbox
                    let valor = innerEvent.value;
                    this.esCampoRequerido2 = !valor;
                    e.setValue(valor);

                };
            }
        },
        onInitNewRow(e) {
            e.data.entregado = true;
        },
        onInitNewRow_medida(e) {
            console.log(e);
        },
    },
};
