import axios from 'axios';

export default class OrdenTrabajoService {

    getOrdenTrabajo() {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajo`;    
        return axios.get(url).then(res=>res.data);
    }

  
    createOrdenTrabajo(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateOrdenTrabajo(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoedicion`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updatePolizaOT(info){
        const url = process.env.VUE_APP_APIURL + `/api/polizaedicionot`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updatePolizaOTDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/polizaedicionotdetalle`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    createPoliza(info){
        const url = process.env.VUE_APP_APIURL + `/api/polizaalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createPolizaDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/polizadetallealta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getPolizaDetallePorId(info){
        const url = process.env.VUE_APP_APIURL + `/api/polizadetalleconsultaporid`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteOrdenTrabajo(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deletePoliza(info){
        const url = process.env.VUE_APP_APIURL + `/api/polizabaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updatePoliza(info){
        const url = process.env.VUE_APP_APIURL + `/api/polizaedicion`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deletePolizaDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/polizadetallebaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getECAParaOT(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacion_consultaot`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}