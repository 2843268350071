import axios from 'axios';

export default class SolicitudService {

    getSolicitud() {            
        const url = process.env.VUE_APP_APIURL + `/api/solicitud`;    
        return axios.get(url).then(res=>res.data);
    }

    getSolicitudRevision() {            
        const url = process.env.VUE_APP_APIURL + `/api/solicitudrevision`;    
        return axios.get(url).then(res=>res.data);
    }
    updateSolicitudRevision(info){
        const url = process.env.VUE_APP_APIURL + `/api/solicitudrevisionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    
    getSolicitudCotizar(info){
        const url = process.env.VUE_APP_APIURL + `/api/solicitudconsultaacotizar`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getSolicitudDatosParaCotizacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/solicituddatoscotizacion`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateSolicitud(info){
        const url = process.env.VUE_APP_APIURL + `/api/solicitudedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createSolicitud(info){
        const url = process.env.VUE_APP_APIURL + `/api/solicitudalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteSolicitud(info){
        const url = process.env.VUE_APP_APIURL + `/api/solicitudbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


    getSolicitudDatosParaEdicion(info){
        const url = process.env.VUE_APP_APIURL + `/api/solicitudconsulta_edicion`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getSolicitudPartidasCotizar(info){
        const url = process.env.VUE_APP_APIURL + `/api/solicitudconsulta_partidascotizar`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


}