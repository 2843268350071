
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import HabilidadTab from './grupoetapadetalle.vue';

export default {
  components: {
    DxTabPanel, DxItem, HabilidadTab
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
};
