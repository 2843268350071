
//import { nullLiteral } from '@babel/types';
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import CompromisoPagoService from '../services/compromisopago-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {
      dataSourceCompany: null,
      empresa: {},
      events: [],
      rolService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.compromisopagoService = new CompromisoPagoService();
  },
  mounted() {
    this.loadDataInfo();

  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    }, loadDataInfo() {
      this.compromisopagoService.getCompromisoPago().then(data => {
        data.forEach(
          element => {

            if (element.bactivo == 1) {
              element.bactivo = true;
            } else {
              element.bactivo = false;
            }

          });
        this.dataSourceCompany = data;

      });
    },
    editRegister(e) {

      let vactivo = e.data.bactivo == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pid: e.data.id,
        pdescripcion: e.data.descripcion,
        pbactivo: vactivo,
      });


      this.compromisopagoService.updateCompromisoPago(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;

        }


      });
    },
    deleteRegister(e) {
      let myJSON = JSON.stringify({
        pid: e.data.id,
      });


      this.compromisopagoService.deleteCompromisoPago(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.loadDataInfo();
      });

    },
    createRegister(e) {


      let vactivo = e.data.bactivo == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pdescripcion: e.data.descripcion,
        pbactivo: vactivo,
      });

      /**
      this.compromisopagoService.createCompromisoPago(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.loadDataInfo();
      });
       */

      this.compromisopagoService.createCompromisoPago(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;

        }


      });

    },
    onInitNewRow(e) {
      e.data.serializable = true;
      e.data.bactivo = true;
      //e.data.id_equipo_padre = null;      
      //this.equipmentService.getProductoPadres().then(data => this.dataSourceFatherItems=data);
    },
  },
};
