
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxLookup,
    DxForm,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxFormItem
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import LeyService from '../services/ley-service';
import TipoLeyService from '../services/tipoley-service';
import 'devextreme-vue/text-area';
import Cookies from "js-cookie";
window.Cookies = Cookies;

export default {
    components: {
        DxDataGrid,
        DxEditing,
        DxColumn,
        DxValidationRule,
        DxPaging,
        DxLookup,
        DxForm,
        DxItem,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxSelection,
        DxToast,
        DxFormItem
    },
    data() {
        return {
            productos: null,
            dataSourceProducts: null,
            dataSourceTipoLey: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Leyes - Fundamentos',
                showTitle: true,
                width: 500,
                height: 'auto',
            },
            leyService: null,
            tipoleyService: null,
        };
    },
    created() {
        this.leyService = new LeyService();
        this.tipoleyService = new TipoLeyService();
    },
    mounted() {


        this.loadDataInfo();

    },

    methods: {
        loadDataInfo() {
            this.leyService.getLey().then(data => {
                this.tipoleyService.getTipoLey().then(data => this.dataSourceTipoLey = data);
                data.forEach(
                    element => {

                        if (element.bactivo == 1) {
                            element.bactivo = true;
                        } else {
                            element.bactivo = false;
                        }

                    });
                this.dataSourceProducts = data;

            });
        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },

        editRegister(e) {
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let vactivo = e.data.bactivo == true ? 1 : 0;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pdescripcioncorta: e.data.descripcioncorta,
                pdescripcionlarga: e.data.descripcionlarga,
                pusuarioregistroid: idusuario,
                pbactivo: vactivo,
                ptipoleyid:e.data.tipoleyid,
            });
            
            
            this.leyService.updateLey(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha actualizado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });
             
        },
        deleteRegister(e) {
            let myJSON = JSON.stringify({
                pid: e.data.id,
            });


            this.leyService.deleteLey(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        createRegister(e) {

            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let vactivo = e.data.bactivo == true ? 1 : 0;
            let myJSON = JSON.stringify({
                
                pdescripcioncorta: e.data.descripcioncorta,
                pdescripcionlarga: e.data.descripcionlarga,
                pusuarioregistroid: idusuario,
                pbactivo: vactivo,
                ptipoleyid:e.data.tipoleyid,
            });

            this.leyService.createLey(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },

        onInitNewRow(e) {
            e.data.serializable = true;
            e.data.bactivo = true;
            //e.data.id_equipo_padre = null;      
            //this.equipmentService.getProductoPadres().then(data => this.dataSourceFatherItems=data);
        },
    },
};
