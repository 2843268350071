import axios from 'axios';

export default class PuestoService {

    getPuesto() {            
        const url = process.env.VUE_APP_APIURL + `/api/puesto`;    
        return axios.get(url).then(res=>res.data);
    }

    updatePuesto(info){
        const url = process.env.VUE_APP_APIURL + `/api/puestoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createPuesto(info){
        const url = process.env.VUE_APP_APIURL + `/api/puestoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deletePuesto(info){
        const url = process.env.VUE_APP_APIURL + `/api/puestobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}