import axios from 'axios';

export default class ClienteService {

    getCliente() {            
        const url = process.env.VUE_APP_APIURL + `/api/cliente`;    
        return axios.get(url).then(res=>res.data);
    }

    updateCliente(info){
        const url = process.env.VUE_APP_APIURL + `/api/clienteedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createCliente(info){
        const url = process.env.VUE_APP_APIURL + `/api/clientealta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteCliente(info){
        const url = process.env.VUE_APP_APIURL + `/api/clientebaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getClientePorNombre(info){
        const url = process.env.VUE_APP_APIURL + `/api/clientepornombre`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

}