import axios from 'axios';

export default class OrdenTrabajoProcedimientoTerminosService {

    getTermino(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudiotermino`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createTermino(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioterminoalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteTermino(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioterminobaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateTermino(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioterminoedicion`;    
        return axios.patch(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

}