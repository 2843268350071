import axios from "axios";
export default class NotificationService {
  registerNotification(usuario) {
    const url = process.env.VUE_APP_APIURL + `/api/indexrevisanotificacion`;
    return axios.post(url, usuario).then((res) => res.data);
  }

  existsNotification(usuario) {
    const url =
      process.env.VUE_APP_APIURL + `/api/indexrevisanotificacionexiste`;
    return axios.post(url, usuario).then((res) => res.data);
  }

 

updateNotification(info){
    const url = process.env.VUE_APP_APIURL + `/api/notificacionesleer`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}



}
