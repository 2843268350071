
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,

} from 'devextreme-vue/data-grid';


import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import CumplimientoService from '../services/cumplimiento-service';




export default {
  components: {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,

    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,

  },
  data() {
    return {
      productos: null,
      dataSourceProducts: null,
      dataSourceListado: null,
      dataSourceGroupProducts: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Servicios',
        showTitle: true,
        width: 350,
        height: 500,
      },
      suppliersData: createStore({
        key: 'SupplierID',
      }),
      servicioService: null,
      labelMode: 'floating',
      labelLocation: 'left',
    };
  },
  created() {
    this.cumplimientoService = new CumplimientoService();

  },
  mounted() {
    this.loadDataInfo();
  },

  methods: {
    loadDataInfo() {
      this.cumplimientoService.getCumplimientoConsulta().then(data => {

        this.dataSourceProducts = data;

      });


    },
    onSaved() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onEditCanceled() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },

    editRegister(e) {


      let myJSON = JSON.stringify({
        pid: e.data.id,
        pdescripcion: e.data.descripcion,
        pvalorsi: e.data.valorsi,
        pvalorno: e.data.valorno,
        pvalorparcial: e.data.valorparcial,
        pnoaplica: e.data.noaplica,

      });



      this.cumplimientoService.updateCumplimiento(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;

        }


      });
    },
    deleteRegister(e) {
      let myJSON = JSON.stringify({
        pid: e.data.id,
      });


      this.cumplimientoService.deleteTCumplimiento(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;

        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;

        }
        this.loadDataInfo();

      });
    },
    createRegister(e) {


      let myJSON = JSON.stringify({
        pdescripcion: e.data.descripcion,
        pvalorsi: e.data.valorsi,
        pvalorno: e.data.valorno,
        pvalorparcial: e.data.valorparcial,
        pnoaplica: e.data.noaplica,

      });



      this.cumplimientoService.createCumplimiento(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;

        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;

        }
        this.loadDataInfo();

      });


    },

    onInitNewRow(e) {
      e.data.serializable = true;
      e.data.bactivo = true;

    },
  },
};
