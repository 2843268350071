
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import CompanyService from '../services/company-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {
      dataSourceCompany: null,
      empresa: {},
      events: [],
      companyService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.companyService = new CompanyService();
  },
  mounted() {    
    this.companyService.getEmpresas().then(data => this.dataSourceCompany=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editCompany(e) {
			this.companyService.updateEmpresa(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteCompany(e) {
			this.companyService.deleteEmpresa(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createCompany(e) {
			this.companyService.createEmpresa(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
