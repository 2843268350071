import axios from 'axios';

export default class ClienteContactoService {

    getClienteContacto() {            
        const url = process.env.VUE_APP_APIURL + `/api/clientecontacto`;    
        return axios.get(url).then(res=>res.data);
    }

    updateClienteContacto(info){
        const url = process.env.VUE_APP_APIURL + `/api/clientecontactoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createClienteContacto(info){
        const url = process.env.VUE_APP_APIURL + `/api/clientecontactoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteClienteContacto(info){
        const url = process.env.VUE_APP_APIURL + `/api/clientecontactobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getClienteContactoCliente(info){
        const url = process.env.VUE_APP_APIURL + `/api/clientecontactoconsultaporid`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getClienteContactoCotizacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/clientecontactocotizacion`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getClienteContactoCotizacionporid(info){
        const url = process.env.VUE_APP_APIURL + `/api/clientecontactocotizacionporid`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}