
 
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,

  DxFormItem,
  DxButton
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import LeyDetalleService from '../services/leydetalle-service';
import notify from 'devextreme/ui/notify';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxTextArea from 'devextreme-vue/text-area';
import { DxTextBox } from 'devextreme-vue/text-box';
import DxSelectBox from 'devextreme-vue/select-box';
export default {

  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxTextArea,
    DxButton,

    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxFormItem,
    DxPopup,
    DxScrollView,
    DxToolbarItem,
    DxTextBox,
    DxSelectBox



  },
  data() {
    return {
      dataSourceCompany: null,
      empresa: {},
      events: [],
      puestoService: null,
      isVisible: false,
      message: '',
      type: 'info',
      v_valor: null,
      popupOptions: {
        title: 'Detalle',
        showTitle: true,
        width: 950,
        height: 400,
      },

      popupVisible: false,
      popupTitle: "Edicion Ley",
      loading: true,
      frm_ley_descripcion: null,
      frm_ley_larga: null,
      frm_ley_parte: null,
      frm_ley_id: null,
      frm_ley: null,


      popupVisible2: false,
      popupTitle2: "Asignacion Ley a ECA",
      loading2: true,
      frm_eca_id: null,
      frm_estandar_id: null,
      frm_estandar: null,
      dataSourceECA: null,
      dataSourceEstandar: null,


      popupVisible3: false,
      popupTitle3: "Asignacion Ley a Procedimiento",
      loading3: true,
      frm_procedimiento_id: null,
      frm_irregularidad_id: null,
      dataSourceProcedimiento: null,
      dataSourceIrregularidad: null,

    };
  },
  created() {
    this.leydetalleService = new LeyDetalleService();
  },
  mounted() {


  },
  methods: {
    onValueChanged(e) {

      if (this.frm_eca_id != null) {
        let myJSON = JSON.stringify({
          pideca: e.value,
        });
        this.leydetalleService.getLeyDetalleConsultaGeneralECAEstandar(myJSON).then(data => this.dataSourceEstandar = data);
      }
      else {
        this.frm_estandar_id = null;
      }
    },
    onValueChanged3(e) {

      if (this.frm_procedimiento_id != null) {
        let myJSON = JSON.stringify({
          pid: e.value,
        });
        this.leydetalleService.getLeyDetalleConsultaGeneralProcedimientoIrregularidad(myJSON).then(data => this.dataSourceIrregularidad = data);
      }
      else {
        this.frm_irregularidad_id = null;
      }
    },

    cargaLey(e) {

      this.frm_ley_descripcion = e.row.data.descripcion;
      this.frm_ley_larga = e.row.data.descripcionlarga;
      this.frm_ley_parte = e.row.data.parteley;
      this.frm_ley_id = e.row.data.id;
      this.frm_ley = e.row.data.ley;
      this.loading = true;
      this.popupVisible = true;

    },
    cargaLeyECA(e) {
      this.frm_eca_id = null;
      this.frm_ley_descripcion = e.row.data.descripcion;
      this.frm_ley_parte = e.row.data.parteley;
      this.frm_ley_id = e.row.data.id;
      this.frm_ley = e.row.data.ley;
      this.loading2 = true;
      this.popupVisible2 = true;
      this.leydetalleService.getLeyDetalleConsultaGeneralECA().then(data => { this.dataSourceECA = data; });


    },
    cargarProcedimiento(e) {
      this.frm_procedimiento_id = null;
      this.frm_irregularidad_id = null;
      this.frm_ley_descripcion = e.row.data.descripcion;
      this.frm_ley_parte = e.row.data.parteley;
      this.frm_ley_id = e.row.data.id;
      this.frm_ley = e.row.data.ley;
      this.loading3 = true;
      this.popupVisible3 = true;
      this.leydetalleService.getLeyDetalleConsultaGeneralProcedimiento().then(data => { this.dataSourceProcedimiento = data; });


    },
    saveAsignacionLeyProcedimiento() {


      let err = 0;
      let msgerror = '';


      if (this.frm_procedimiento_id == null) {
        err = 1;
        msgerror = 'Ingresar procedimiento';
      }
      if (err == 0) {
        if (this.frm_irregularidad_id == null) {
          err = 1;
          msgerror = 'Ingresar irregularidad';
        }
      }
      let myJSON = JSON.stringify({
        pirregularidadid: this.frm_irregularidad_id,
        pleydetalleid: this.frm_ley_id,

      });



      if (err == 0) {
        this.leydetalleService.updateLeyDetalleAsignaIrregularidad(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible3 = false;
            this.clickBuscar();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }
          //this.loadDataInfo();

        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }




    },
    saveAsignacionLeyECA() {


      let err = 0;
      let msgerror = '';


      if (this.frm_eca_id == null) {
        err = 1;
        msgerror = 'Ingresar ECA';
      }
      if (err == 0) {
        if (this.frm_estandar_id == null) {
          err = 1;
          msgerror = 'Ingresar estandar';
        }
      }
      let myJSON = JSON.stringify({
        pestandarid: this.frm_estandar_id,
        pleydetalleid: this.frm_ley_id,

      });



      if (err == 0) {
        this.leydetalleService.updateLeyDetalleAsignaECA(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible2 = false;
            this.clickBuscar();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }
          //this.loadDataInfo();

        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }




    },
    saveLey() {


      let err = 0;
      let msgerror = '';


      if (this.frm_ley_larga == null) {
        err = 1;
        msgerror = 'Ingresar descripcion de ley';
      }


      let myJSON = JSON.stringify({
        pid: this.frm_ley_id,
        pdescripcionlarga: this.frm_ley_larga,

      });




      if (err == 0) {
        this.leydetalleService.updateLeyDetalleGeneral(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.clickBuscar();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }
          //this.loadDataInfo();

        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }




    },
    clickBuscar() {
      let myJSON = JSON.stringify({

        pdescripcion: this.v_valor,

      });

      if (this.v_valor != null) {
        this.leydetalleService.getLeyDetalleConsultaGeneral(myJSON).then(data => {

          this.dataSourceCompany = data;

        });
      }
    },

  },
};
