
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxValidationRule,

  DxPaging,

  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  //DxLookup,
  DxButton
} from 'devextreme-vue/data-grid';

import { DxToast } from 'devextreme-vue/toast';
import MultaTemaService from '../services/multatema-service';

import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
 
import DxTextArea from 'devextreme-vue/text-area';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
//import moment from 'moment';
import notify from 'devextreme/ui/notify';
import Cookies from "js-cookie";
window.Cookies = Cookies;


export default {
  components: {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,


    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxButton,
    DxPopup,
    DxScrollView,
    DxToolbarItem,
    
    DxTextArea,
    DxSelectBox,
    DxNumberBox,

  },
  data() {
    return {
      popupVisible: false,
      loading: true,
      popupTitle: "Alta",
      popupTitle2: "Edicion",
      popupVisible2: false,
      loading2: true,
      productos: null,
      dataSourceProducts: null,
      dataSourceLey: null,
      dataSourceLeyDetalle: null,
      dataSourceObligacion: null,
      dataSourceInfraccion: null,

      dataSourceFactor: [{
                                            tipo: 'UMA',
                                            descripcion: 'UMA'
                                            }, {
                                                tipo: 'SALMIN',
                                            descripcion: 'SALARIOS MINIMOS'
                                            }],
      // ECA
      dataSourceECA: null,
      dataSourceECAEstandar: null,
      dataSourceECAEstandarLista: null,
      popupTitle3: "Vinculación ECA - Estandar",
      popupVisible3: false,
      loading3: true,
      popupTitle4: "Asignación ECA - Estandar",
      popupVisible4: false,
      loading4: true,
      frm_infraccion_id: null,
      frm_tema:null,
      frm_ecaid: null,
      frm_eca: null,
      frm_ecaestandarid: null,
      frm_ecaestandar: null,
      
      frm_e_ecaid: null,
      frm_e_eca: null,
      frm_e_ecaestandarid: null,
      frm_e_ecaestandar: null,
      
      frm_Infraccion:null,
      frm_id: null,
      frm_leyid: null,
      frm_leydetalleid: null,
      frm_idInfraccion: null,
      frm_idObligacion: null,
      frm_actividad: null,
      frm_FundamentoLegal: null,
      frm_Artículo: null,
      frm_Fraccion: null,
      frm_FraccionNumero: null,
      frm_Inciso: null,
      frm_bajaMin: null,
      frm_bajaMax: null,
      frm_mediaMin: null,
      frm_mediaMax: null,
      frm_altaMin: null,
      frm_altaMax: null,
      frm_unidadMedida: null,

      frm_ed_leyid: null,
      frm_ed_leydetalleid: null,
      frm_ed_idInfraccion: null,
      frm_ed_idObligacion: null,
      frm_ed_actividad: null,
      frm_ed_FundamentoLegal: null,
      frm_ed_Artículo: null,
      frm_ed_Fraccion: null,
      frm_ed_FraccionNumero: null,
      frm_ed_Inciso: null,
      frm_ed_bajaMin: null,
      frm_ed_bajaMax: null,
      frm_ed_mediaMin: null,
      frm_ed_mediaMax: null,
      frm_ed_altaMin: null,
      frm_ed_altaMax: null,
      frm_ed_unidadMedida: null,

      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Multa',
        showTitle: true,
        width: 1000,
        height: 600,
      },
      servicioService: null,
      labelMode: 'floating',
      labelLocation: 'left',
    };
  },
  created() {
    this.multatemaService = new MultaTemaService();

  },
  mounted() {
    this.loadDataInfo();
  },

  methods: {

    onValueChangedECA(e) {
      this.dataSourceECAEstandar=null;
      let myJSON = JSON.stringify({
        pid: e.value,
        });
      this.multatemaService.getMultaECAEstandar(myJSON).then(data => {
          this.dataSourceECAEstandar = data;
        });
         
},


    onValueChanged(e) {

      if (this.frm_leyid != null) {
        let myJSON = JSON.stringify({
          pid: 0,
          pleyid: e.value,
        });
        this.multatemaService.getMultaLeyDetalle1(myJSON).then(data => {
          this.dataSourceLeyDetalle = data;
        });

      }
      else {
        this.frm_leyid = null;
        this.frm_leydetalleid = null;
        this.dataSourceLeyDetalle = null;

      }
    },
    onValueChanged2(e) {

      if (this.frm_ed_leyid != null) {
        let myJSON = JSON.stringify({
          pid: 0,
          pleyid: e.value,
        });
        this.multatemaService.getMultaLeyDetalle1(myJSON).then(data => {
          this.dataSourceLeyDetalle = data;
        });

      }
      else {
        this.frm_ed_leyid = null;
        this.frm_ed_leydetalleid = null;
        this.dataSourceLeyDetalle = null;

      }
    },
    editar(e) {

      this.dataSourceLey = null;
      this.dataSourceLeyDetalle = null;
      this.dataSourceObligacion = null;
      this.dataSourceInfraccion = null;


      this.multatemaService.getMultaTemaInfraccion().then(data => {
        this.dataSourceInfraccion = data;
      });
      this.multatemaService.getMultaTemaObligacion().then(data => {
        this.dataSourceObligacion = data;
      });

      let myJSON = JSON.stringify({
        pid: e.row.data.leyid,
      });
      this.multatemaService.getMultaLey(myJSON).then(data => this.dataSourceLey = data);


      let myJSON2 = JSON.stringify({
        pid: e.row.data.leydetalleid,
        pleyid: e.row.data.leyid,
      });

      if (e.row.data.leyid != 0) {
        this.multatemaService.getMultaLeyDetalle1(myJSON2).then(data => {
          this.dataSourceLeyDetalle = data;
        });
      }




      this.frm_id = e.row.data.id;
      this.frm_ed_leyid = e.row.data.leyid;
      this.frm_ed_leydetalleid = e.row.data.leydetalleid;
      this.frm_ed_idInfraccion = e.row.data.idInfraccion;
      this.frm_ed_idObligacion = e.row.data.idObligacion;
      this.frm_ed_actividad = e.row.data.actividad;
      this.frm_ed_FundamentoLegal = e.row.data.FundamentoLegal;
      this.frm_ed_Artículo = e.row.data.Artículo;
      this.frm_ed_Fraccion = e.row.data.Fraccion;
      this.frm_ed_FraccionNumero = e.row.data.FraccionNumero;
      this.frm_ed_Inciso = e.row.data.Inciso;
      this.frm_ed_bajaMin = e.row.data.bajaMin;
      this.frm_ed_bajaMax = e.row.data.bajaMax;
      this.frm_ed_mediaMin = e.row.data.mediaMin;
      this.frm_ed_mediaMax = e.row.data.mediaMax;
      this.frm_ed_altaMin = e.row.data.altaMin;
      this.frm_ed_altaMax = e.row.data.altaMax;
      this.frm_ed_unidadMedida = e.row.data.unidadMedida;



      this.loading2 = true;
      this.popupVisible2 = true;



    },
   

    vereca(e) {
      this.verecafunction(e.row.data.id,e.row.data.Infraccion,e.row.data.tema,1);
    },

    verecafunction(parid,parinfra,partema,abre){
      this.frm_tema=partema;
      this.frm_Infraccion=partema + '-'+parinfra;
      this.frm_infraccion_id=parid;
      let myJSON = JSON.stringify({
        pidListaInfraccion: this.frm_infraccion_id,
      });
      
      this.multatemaService.getMultaTemaEca(myJSON).then(data => {
          this.dataSourceECAEstandarLista = data;
        });
        if(abre==1){
          this.popupVisible3 = true;
        }
        
       
    },

    agregar() {
      this.frm_id = null;
      this.frm_leyid = null;
      this.frm_leydetalleid = null;
      this.frm_idInfraccion = null;
      this.frm_idObligacion = null;
      this.frm_actividad = null;
      this.frm_FundamentoLegal = null;
      this.frm_Artículo = null;
      this.frm_Fraccion = null;
      this.frm_FraccionNumero = null;
      this.frm_Inciso = null;
      this.frm_bajaMin = null;
      this.frm_bajaMax = null;
      this.frm_mediaMin = null;
      this.frm_mediaMax = null;
      this.frm_altaMin = null;
      this.frm_altaMax = null;
      this.frm_unidadMedida = null;

      this.dataSourceLey = null;
      this.dataSourceLeyDetalle = null;
      this.dataSourceObligacion = null;
      this.dataSourceInfraccion = null;

      

      this.multatemaService.getMultaTemaInfraccion().then(data => {
        this.dataSourceInfraccion = data;
      });
      this.multatemaService.getMultaTemaObligacion().then(data => {
        this.dataSourceObligacion = data;
      });


      this.loading = true;
      this.popupVisible = true;

    },


    

    agregareca() {
      this.frm_ecaid = null;
      this.frm_ecaestandarid = null;
 

      this.multatemaService.getMultaECA().then(data => {
        this.dataSourceECA = data;
      });
       
      this.popupVisible4 = true;

    },

    saveRegisterECA() {


              let err = 0;
              let msgerror = '';



              if (this.frm_ecaid == null) {
                err = 1;
                msgerror = 'Ingresar ECA';
              }

              if (err == 0) {
                if (this.frm_ecaestandarid == null) {
                  err = 1;
                  msgerror = 'Ingresar Estandar';
                }
              }


              let myJSON = JSON.stringify({
                pidListaInfraccion: this.frm_infraccion_id,
                pguiaevaluacionid: this.frm_ecaid,
                pguiaevaluacionestandarid: this.frm_ecaestandarid,
                
              });



              if (err == 0) {
                this.multatemaService.createMultaTemaEca(myJSON).then(data => {

                  if (data.err == 0) {
                    this.type = 'success';
                    this.message = 'Se ha guardado el registro';
                    this.isVisible = true;

                    notify({
                      message: this.message,
                    }, this.type, 5000);
                     
                    this.popupVisible4 = false;
                    //this.loadDataInfo();
                    this.verecafunction(this.frm_infraccion_id,this.frm_Infraccion,this.frm_tema,0);
                  } else {

                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                    notify({
                      message: 'Ocurrio un error ' + data.msg,
                    }, this.type, 4000);


                  }
                  //this.loadDataInfo();

                });
              } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                  message: this.message,
                }, this.type, 4000);

              }




              },

    saveRegister() {


      let err = 0;
      let msgerror = '';


      /**
      if (this.frm_leyid == null) {
        err = 1;
        msgerror = 'Ingresar fundamento';
      }

      if (err == 0) {
        if (this.frm_leydetalleid == null) {
          err = 1;
          msgerror = 'Ingresar articulo';
        }
      }
 */
      if (err == 0) {
        if (this.frm_idInfraccion == null) {
          err = 1;
          msgerror = 'Ingresar infraccion';
        }
      }
     /**
      if (err == 0) {
        if (this.frm_idObligacion == null) {
          err = 1;
          msgerror = 'Ingresar obligacion';
        }
      }
    
      if (err == 0) {
        if (this.frm_actividad == null || this.frm_actividad == '') {
          err = 1;
          msgerror = 'Ingresar actividad';
        }
      }
  */
      if (err == 0) {
        if (this.frm_FundamentoLegal == null || this.frm_FundamentoLegal == '') {
          err = 1;
          msgerror = 'Ingresar fundamento legal';
        }
      }

  

      if (err == 0) {
        if (this.frm_bajaMin == null || this.frm_bajaMin == '') {
          err = 1;
          msgerror = 'Ingresar baja min';
        }
      }

      if (err == 0) {
        if (this.frm_bajaMax == null || this.frm_bajaMax == '') {
          err = 1;
          msgerror = 'Ingresar baja max';
        }
      }



      if (err == 0) {
        if (this.frm_mediaMin == null || this.frm_mediaMin == '') {
          err = 1;
          msgerror = 'Ingresar media min';
        }
      }

      if (err == 0) {
        if (this.frm_mediaMax == null || this.frm_mediaMax == '') {
          err = 1;
          msgerror = 'Ingresar media max';
        }
      }


      if (err == 0) {
        if (this.frm_altaMin == null || this.frm_altaMin == '') {
          err = 1;
          msgerror = 'Ingresar alta min';
        }
      }

      if (err == 0) {
        if (this.frm_altaMax == null || this.frm_altaMax == '') {
          err = 1;
          msgerror = 'Ingresar alta max';
        }
      }



      if (err == 0) {
        if (this.frm_unidadMedida == null || this.frm_unidadMedida == '') {
          err = 1;
          msgerror = 'Ingresar unidad de medida';
        }
      }

      let myJSON = JSON.stringify({
        //pleyid: this.frm_leyid,
        //pleydetalleid: this.frm_leydetalleid,
        pidInfraccion: this.frm_idInfraccion,
        pidObligacion: 0,
        pactividad: 0,
        pFundamentoLegal: this.frm_FundamentoLegal,
        //pArtículo: this.frm_Artículo == null || this.frm_Artículo == '' ? 0 : this.frm_Artículo,
       // pFraccion: this.frm_Fraccion == null || this.frm_Fraccion == '' ? 0 : this.frm_Fraccion,
        //pFraccionNumero: this.frm_FraccionNumero == null || this.frm_FraccionNumero == '' ? 0 : this.frm_FraccionNumero,
       // pInciso: this.frm_Inciso == null || this.frm_Inciso == '' ? 0 : this.frm_Inciso,
        pbajaMin: this.frm_bajaMin,
        pbajaMax: this.frm_bajaMax,
        pmediaMin: this.frm_mediaMin,
        pmediaMax: this.frm_mediaMax,
        paltaMin: this.frm_altaMin,
        paltaMax: this.frm_altaMax,
        punidadMedida: this.frm_unidadMedida,
      });



      if (err == 0) {
        this.multatemaService.createMulta(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha guardado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }
          //this.loadDataInfo();

        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }




    },

    saveEdit() {


      let err = 0;
      let msgerror = '';


      /**
      if (this.frm_ed_leyid == null) {
        err = 1;
        msgerror = 'Ingresar fundamento';
      }

      if (err == 0) {
        if (this.frm_ed_leydetalleid == null) {
          err = 1;
          msgerror = 'Ingresar articulo';
        }
      }
 */
      if (err == 0) {
        if (this.frm_ed_idInfraccion == null) {
          err = 1;
          msgerror = 'Ingresar infraccion';
        }
      }
 /**
      if (err == 0) {
        if (this.frm_ed_idObligacion == null) {
          err = 1;
          msgerror = 'Ingresar obligacion';
        }
      }

      if (err == 0) {
        if (this.frm_ed_actividad == null || this.frm_ed_actividad == '') {
          err = 1;
          msgerror = 'Ingresar actividad';
        }
      }
**/
     
      if (err == 0) {
        if (this.frm_ed_FundamentoLegal == null || this.frm_ed_FundamentoLegal == '') {
          err = 1;
          msgerror = 'Ingresar fundamento legal';
        }
      }

  

      if (err == 0) {
        if (this.frm_ed_bajaMin == null || this.frm_ed_bajaMin == '') {
          err = 1;
          msgerror = 'Ingresar baja min';
        }
      }

      if (err == 0) {
        if (this.frm_ed_bajaMax == null || this.frm_ed_bajaMax == '') {
          err = 1;
          msgerror = 'Ingresar baja max';
        }
      }



      if (err == 0) {
        if (this.frm_ed_mediaMin == null || this.frm_ed_mediaMin == '') {
          err = 1;
          msgerror = 'Ingresar media min';
        }
      }

      if (err == 0) {
        if (this.frm_ed_mediaMax == null || this.frm_ed_mediaMax == '') {
          err = 1;
          msgerror = 'Ingresar media max';
        }
      }


      if (err == 0) {
        if (this.frm_ed_altaMin == null || this.frm_ed_altaMin == '') {
          err = 1;
          msgerror = 'Ingresar alta min';
        }
      }

      if (err == 0) {
        if (this.frm_ed_altaMax == null || this.frm_ed_altaMax == '') {
          err = 1;
          msgerror = 'Ingresar alta max';
        }
      }



      if (err == 0) {
        if (this.frm_ed_unidadMedida == null || this.frm_ed_unidadMedida == '') {
          err = 1;
          msgerror = 'Ingresar unidad de medida';
        }
      }

      let myJSON = JSON.stringify({
        pid: this.frm_id,
        //pleyid: this.frm_ed_leyid,
        //pleydetalleid: this.frm_ed_leydetalleid,
        pidInfraccion: this.frm_ed_idInfraccion,
        pidObligacion: 0,
        pactividad: 0,
       pFundamentoLegal: this.frm_ed_FundamentoLegal,
       // pArtículo: this.frm_ed_Artículo == null || this.frm_ed_Artículo == '' ? 0 : this.frm_ed_Artículo,
        //pFraccion: this.frm_ed_Fraccion == null || this.frm_ed_Fraccion == '' ? 0 : this.frm_ed_Fraccion,
       // pFraccionNumero: this.frm_ed_FraccionNumero == null || this.frm_ed_FraccionNumero == '' ? 0 : this.frm_ed_FraccionNumero,
       // pInciso: this.frm_ed_Inciso == null || this.frm_ed_Inciso == '' ? 0 : this.frm_ed_Inciso,
        pbajaMin: this.frm_ed_bajaMin,
        pbajaMax: this.frm_ed_bajaMax,
        pmediaMin: this.frm_ed_mediaMin,
        pmediaMax: this.frm_ed_mediaMax,
        paltaMin: this.frm_ed_altaMin,
        paltaMax: this.frm_ed_altaMax,
        punidadMedida: this.frm_ed_unidadMedida,
      });



      if (err == 0) {
        this.multatemaService.updateMulta(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha guardado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible2 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }
          //this.loadDataInfo();

        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }




    },

    loadDataInfo() {
      this.multatemaService.getMulta().then(data => {
        this.dataSourceProducts = data;
      });

     

    },
    onSaved() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onEditCanceled() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },

    editRegister(e) {



      let myJSON = JSON.stringify({
        pid: e.data.id,
        pdescripcion: e.data.descripcion,
        pmultatemaid: e.data.multatemaid,
      });



      this.multatemaService.updateMultaTemaObligacion(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;

        }


      });
    },

    deleteRegisterECA(e) {
      let myJSON = JSON.stringify({
        pid: e.data.id,
      });


      this.multatemaService.deleteMultaTemaEca(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;

        }


      });

    },


    deleteRegister(e) {
      let myJSON = JSON.stringify({
        pid: e.data.id,
      });


      this.multatemaService.deleteMulta(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;

        }


      });

    },
    createRegister(e) {




      let myJSON = JSON.stringify({
        pdescripcion: e.data.descripcion,
        pmultatemaid: e.data.multatemaid,
      });



      this.multatemaService.createMultaTemaObligacion(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          //this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
          // this.loadDataInfo();
        }
        this.loadDataInfo();

      });


    },


  },
};
