
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
 
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxValidationRule,
 
  DxFormItem
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import AlertaService from '../services/alerta-service';
 

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
 
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxValidationRule,
    DxFormItem
   
  },
  data() {
    return {
      customer: null,
      procedimientos: null,
       
      dataSourceConfiguracion: null,
      
      
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Cliente',
        showTitle: true,
        width: 800,
        height: 'auto',
      },
      opcionesColumna: {
        maxLength: 3
      },
      setStateValueGiro(rowData, value) {
        rowData.subgiroid = null;
        this.defaultSetCellValue(rowData, value);
      },
    };
  },
  
  created() {
    this.AlertaService = new AlertaService();
    
  },
  mounted() {
    this.loadDataInfo();
  },
  methods: {
    loadDataInfo() {
      this.AlertaService.consultarAlertasConfiguracion().then(data => this.dataSourceConfiguracion = data);
      
    },
    onSaved() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onEditCanceled() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editProcedure(e) {
 

      let vvalor = e.data.valor == true ? 1 : 0;
      let myJSON = JSON.stringify({
         pid: e.data.id,
        pvalor: vvalor,
        
      });
      console.log(myJSON);

     
      
      this.AlertaService.updateAlertasConfiguracion(myJSON).then(data => {
        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
        }
      });
    },
     
    
    onInitNewRow(e) {
      e.data.valor = false;
      
    },
  },
};
