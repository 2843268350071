
//import { nullLiteral } from '@babel/types';
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import SemaforoProyectoService from '../services/semaforoproyecto-service';
import notify from 'devextreme/ui/notify';
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {
      dataSourceCompany: null,
      empresa: {},
      events: [],
      semaforoService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.semaforoService = new SemaforoProyectoService();
  },
  mounted() {
    this.semaforoService.getSemaforoProyecto().then(data => {
      this.dataSourceCompany = data;
    });

  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editRegister(e) {

      let myJSON = JSON.stringify({
        pid: e.data.id,
        pdescripcion: e.data.descripcion,
        pvalorinicial: e.data.valorinicial,
        pvalorfinal: e.data.valorfinal,
      });

      this.semaforoService.updateSemaforoProyecto(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });

    },
    deleteRegister(e) {

      let myJSON = JSON.stringify({
        pid: e.data.id,
      });
      console.log(myJSON);
      this.semaforoService.deleteSemaforoProyecto(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
    },
    createRegister(e) {

      let myJSON = JSON.stringify({
        pdescripcion: e.data.descripcion,
        pvalorinicial: e.data.valorinicial,
        pvalorfinal: e.data.valorfinal,
      });
      console.log(myJSON);
      if (e.data.descripcion == '' || e.data.descripcion == null) {
        notify('Capture la descripcion', "error", 2000);
      } else {
        this.semaforoService.createSemaforoProyecto(myJSON).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
        });
      }


    },
  },
};
