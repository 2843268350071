
//import { nullLiteral } from '@babel/types';
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import ConsideracionService from '../services/consideracion-service';
import Cookies from "js-cookie";
window.Cookies = Cookies;

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {
      dataSourceCompany: null,
      empresa: {},
      events: [],
      estatusService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.consideracionService = new ConsideracionService();
  },
  mounted() {
    this.loadinfo();
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    loadinfo() {


      //this.consideracionService.getConsideracion().then(data => this.dataSourceCompany=data);

      this.consideracionService.getConsideracion().then(data => {
        data.forEach(
          element => {

            if (element.bactivo == 1) {
              element.bactivo = true;
            } else {
              element.bactivo = false;
            }

          });
        this.dataSourceCompany = data;

      });
    },
    editRegister(e) {
      this.consideracionService.updateConsideracion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });

    },
    deleteRegister(e) {

      this.consideracionService.deleteConsideracion(e.data).then(() => {

        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.loadinfo();
      });


    },
    createRegister(e) {

      this.consideracionService.createConsideracion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.loadinfo();
      });



    },

    onInitNewRow(e) {
      e.data.bactivo = 1;
    }
  },
};
