
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import OrdersTab from './parametrizacionleytitulo.vue';
import AddressTab from './parametrizacionleycapitulo.vue';
import SeccTab from './parametrizacionleyseccion.vue';
import ArtTab from './parametrizacionleyarticulo.vue';
import ParrTab from './parametrizacionleyparrafo.vue';
import IncTab from './parametrizacionleyinciso.vue';
 

export default {
  components: {
    DxTabPanel, DxItem, OrdersTab, AddressTab, SeccTab, ArtTab, ParrTab, IncTab
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
};
