import axios from 'axios';

export default class TipoLeyService {

    getTipoLey() {            
        const url = process.env.VUE_APP_APIURL + `/api/tipoley`; 
        return axios.get(url).then(res=>res.data);
    }

    updateTipoLey(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipoleyedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createTipoLey(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipoleyalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteTipoLey(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipoleybaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}