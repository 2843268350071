import axios from 'axios';

export default class ReportesService {

    getReporteVisitaCampo(info) {            
         const url = process.env.VUE_APP_APIURL + `/api/visitacampo`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getDetalleReporteVisitaCampo(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/visitacampodetalleporid`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getDetalleReporteVisitaCampoParaPDF(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/visitacampoparapdf`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getDetalleReporteVisitaCampoParaPDFGenerales(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/visitacampoparapdfgenerales`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getDetalleReporteVisitaPorCliente(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/visitacampoporcliente`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

     

}