import axios from 'axios';

export default class OrdenTrabajoProcedimientoCondicionalesService {

    getCondicional(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudiocondicionante`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createCondicional(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudiocondicionantealta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteCondicional(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudiocondicionantebaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateCondicional(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudiocondicionanteedicion`;    
        return axios.patch(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

}