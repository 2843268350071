import axios from 'axios';

export default class FolioDocumentoService {

    getFolioDocumento() {            
        const url = process.env.VUE_APP_APIURL + `/api/foliodocumento`;    
        return axios.get(url).then(res=>res.data);
    }

    updateFolioDocumento(info){
        const url = process.env.VUE_APP_APIURL + `/api/foliodocumentoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createFolioDocumento(info){
        const url = process.env.VUE_APP_APIURL + `/api/foliodocumentoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteFolioDocumento(info){
        const url = process.env.VUE_APP_APIURL + `/api/foliodocumentobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}