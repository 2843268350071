import axios from 'axios';

export default class PlazoFacturacionService {

    getPlazoFacturacion() {            
        const url = process.env.VUE_APP_APIURL + `/api/plazofacturacion`;    
        return axios.get(url).then(res=>res.data);
    }

    updatePlazoFacturacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/plazofacturacionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createPlazoFacturacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/plazofacturacionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deletePlazoFacturacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/plazofacturacionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}