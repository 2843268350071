
import {
  DxGantt,
  DxTasks,
  DxDependencies,
  DxResources,
  DxResourceAssignments,
  DxColumn,
  DxEditing,
} from 'devextreme-vue/gantt';

import {
  tasks,
  dependencies,
  resources,
  resourceAssignments,
} from './../data/datainfo.js';


import OrdenTrabajoPlanService from '../services/ordentrabajoplan-service';

export default {
  components: {
    DxGantt,
    DxTasks,
    DxDependencies,
    DxResources,
    DxResourceAssignments,
    DxColumn,
    DxEditing,
  },
  data() {
    return {
      tasks,
      dependencies,
      resources,
      resourceAssignments,
      vidot: null,
    };
  },
  created() {
    this.ordentrabajoplanService = new OrdenTrabajoPlanService();
    this.vidot = this.$route.params.id;

  },
  mounted() {
    //let id = JSON.parse(Cookies.get("ets_user")).id;//1;

    let myJSON = JSON.stringify({
      pid: this.vidot
    });

    this.ordentrabajoplanService.getOrdenTrabajoPlanTareas(myJSON).then(data => {
      this.tasks = data
    }
    );

    this.ordentrabajoplanService.getOrdenTrabajoPlanDependencias(myJSON).then(data => {
      this.dependencies = data
    }
    );
  },
  methods: {


  },


};
