import axios from 'axios';

export default class OrdenTrabajoEtapaService {

     
    getOrdenTrabajoEtapaConsulta(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoetapaconsulta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    UpdateOrdenTrabajoEtapa(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoetapaedicion`;    
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    
  
}