import axios from 'axios';

export default class ListadoInformacionService {

    getListadoInformacion() {            
        const url = process.env.VUE_APP_APIURL + `/api/listadoinformacion`;    
        return axios.get(url).then(res=>res.data);
    }

    updateListadoInformacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/listadoinformacionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createListadoInformacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/listadoinformacionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteListadoInformacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/listadoinformacionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    // Documento

    
    getListadoDocumento(info){
        const url = process.env.VUE_APP_APIURL + `/api/listadoinformaciondocumento`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     
    createListadoDocumento(info){
        const url = process.env.VUE_APP_APIURL + `/api/listadoinformaciondocumentoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteListadoDocumento(info){
        const url = process.env.VUE_APP_APIURL + `/api/listadoinformaciondocumentobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}