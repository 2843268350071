
import {
    DxFormItem,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxValidationRule,
    DxMasterDetail,
   
     DxGrouping,
  DxGroupPanel,
  DxButton

} from 'devextreme-vue/data-grid';
 import 'devextreme-vue/text-area';
 import DxPopup, { DxToolbarItem }from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import { DxTextBox } from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import DxSelectBox from 'devextreme-vue/select-box';
 
import { DxToast } from 'devextreme-vue/toast';
import moment from 'moment';
import SolicitudServicioService from '../services/solicitudservicio-service';
import SolicitudService from '../services/solicitud-service';
import CompromisoService from '../services/compromisopago-service';
import CondicionService from '../services/condicionpago-service';
import FormaService from '../services/formapago-service';
import PlazoService from '../services/plazofacturacion-service';
import ClienteService from '../services/cliente-service';
import ContactoService from '../services/clientecontacto-service';
import EstatusService from '../services/estatus-service';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import MasterDetail from './solicitudrevisionMasterDetail.vue';
import Cookies from "js-cookie";
window.Cookies = Cookies;
import notify from 'devextreme/ui/notify';

let dataContact = [{ clienteid: 1, descripcion: '', id: 0, }];//new ContactoService().getClienteContacto();   
let contactoService = new ContactoService();
contactoService.getClienteContacto().then(data => dataContact = data);
/**
console.log("valores*******");
console.log(contactoService.getClienteContacto().then(data => dataContact = data));
console.log(dataContact);
**/
export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxLookup,
        DxFormItem,
        DxFilterRow,
        DxSearchPanel,
        
        DxToast,
       
        DxValidationRule,
        DxMasterDetail,
        MasterDetail,
         DxGrouping,
        DxGroupPanel,
        DxButton,
        DxPopup,
        DxScrollView,
        DxToolbarItem,
        DxTextBox,
        DxTextArea,
        DxSelectBox
    },
    data() {

        return {

            frm_id:null,
            frm_estatusactual: null,
            frm_estatusnuevo: null,
            frm_observaciones: null,
            popupVisible:false,
            popupTitle: "Revisión Solicitud",
            loading: true,

            dataSourceEstatusEdicion:null,
            dataSourceSolicitud: null,
            dataSourceCondicion: null,
            dataSourceCompromiso: null,
            dataSourceForma: null,
            dataSourcePlazo: null,
            dataSourceCliente: null,
            dataSourceContacto: null,
            dataSourceEstatus: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Revisión Solicitudes',
                showTitle: true,
                width: 300,
                height: 200,
            },
            suppliersData: createStore({
                key: 'SupplierID',
            }),
            setStateValue(rowData, value) {
                rowData.clientecontactoid = null;
                this.defaultSetCellValue(rowData, value);
            },
        };
    },
    created() {
        this.solicitudservicioService = new SolicitudServicioService();
        this.solicitudService = new SolicitudService();
        this.compromisoService = new CompromisoService();
        this.condicionService = new CondicionService();
        this.formaService = new FormaService();
        this.plazoService = new PlazoService();
        this.clienteService = new ClienteService();

        this.estatusService = new EstatusService();
        //this.contactoService = new ContactoService();   
        //this.contactoService.getClienteContacto().then(data => this.dataSourceContacto = data);

    },
    mounted() {
        this.loadDataInfo();
    },
    methods: {
         editEstatus(e) {
             
             let myJSON = JSON.stringify({
                 ptipo: 2,
             });
             
             this.estatusService.getEstatusParaEdicion(myJSON).then(data => this.dataSourceEstatusEdicion = data);
               
             this.frm_id = e.row.data.id;
             this.frm_estatusactual = e.row.data.estatus;
             this.frm_estatusnuevo = null;
             this.frm_observaciones = null;
             
             this.loading = true;      
             this.popupVisible = true;
         
         },
        loadDataInfo() {

            this.solicitudService.getSolicitudRevision().then(data => this.dataSourceSolicitud = data);
            this.condicionService.getCondicionPago().then(data => this.dataSourceCondicion = data);
            this.compromisoService.getCompromisoPago().then(data => this.dataSourceCompromiso = data);
            this.formaService.getFormaPago().then(data => this.dataSourceForma = data);
            this.plazoService.getPlazoFacturacion().then(data => this.dataSourcePlazo = data);
            this.clienteService.getCliente().then(data => this.dataSourceCliente = data);
            //this.contactoService.getClienteContacto().then(data => this.dataSourceContacto = data);
            this.estatusService.getEstatus().then(data => this.dataSourceEstatus = data.data);

        },

        onEditorPreparing(e) {
                 
            if (e.parentType === 'dataRow' && e.dataField === 'estatusidx') {
                e.editorOptions.disabled = false;
            } 
             
        },
        getFilteredCities: (options) => (
            {
                store: dataContact,//JSON.stringify(this.dataSourceContacto),
                filter: options.data ? ['clienteid', '=', options.data.clienteid] : null,
            }
        ),
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },
        
        editRegister() {
            
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;

           
            let myJSON = JSON.stringify({
                pid: this.frm_id,
                pestatusid: this.frm_estatusnuevo,
                pusuarioregistroid: idusuario,
                pobservaciones: this.frm_observaciones,
            });

 
            
            let err = 0;
            let msgerror = '';

            if(this.frm_id == null){
                err = 1;
                msgerror = 'Seleccionar registro';
            }  

            if(err == 0){
                if(this.frm_estatusnuevo == null){
                    err = 1;
                    msgerror = 'Seleccionar estatus nuevo';
                }
            }

           

            if(err==0){
                //this.solicitudservicioService.updateSolicitudServicioEstatus(myJSON).then(data => {
                    this.solicitudService.updateSolicitudRevision(myJSON).then(data => {
                            if (data.err == 0) {
                            this.type = 'success';
                            this.message = 'Se ha guardado el registro';
                            this.isVisible = true;

                            notify({
                                message: this.message,
                            }, this.type, 5000);
                            this.popupVisible = false;
                            this.loadDataInfo();
                            
                           
                            //this.$router.push('solicitud')
                            } else {

                            this.type = 'error';
                            this.message = 'Ocurrio un error ' + data.msg;
                            this.isVisible = true;
                            notify({
                                message: 'Ocurrio un error ' + data.msg,
                            }, this.type, 4000);

                            
                            }
                            //this.loadDataInfo();

                            });
                        }else{
                            this.type = 'error';
                            this.message = msgerror;
                            this.isVisible = true;
                            notify({
                                message: this.message,
                            }, this.type, 4000);

                        }

        } ,
        deleteProcedure(e) {
            let myJSON = JSON.stringify({
                pid: e.data.id,
            });
            console.log("delete solicitud", myJSON);
            this.solicitudService.deleteSolicitud(myJSON).then(data => {
                if (data.err == 0) {
                    this.type = 'success';
                    this.message = 'Se ha eliminado el registro';
                    this.isVisible = true;
                    this.loadDataInfo();
                } else {

                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                }
            });
        },
        createProcedure(e) {


            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;

            let myJSON = JSON.stringify({
                pnumerosolicitudcliente: e.data.numerosolicitudcliente,
                pfechasolicitud: moment(e.data.fechasolicitud).format('YYYY-MM-DD'),
                pfechadeseadaservicio: moment(e.data.fechadeseadaservicio).format('YYYY-MM-DD'),
                ptiempoentrega: e.data.tiempoentrega,
                /**
                pcompromisopagoid: e.data.compromisopagoid,
                pcondicionpagoid: e.data.condicionpagoid,
                pformapagoid: e.data.formapagoid,
                pplazofacturacionid: e.data.plazofacturacionid,
                 */
                pcompromisopagoid: 0,
                pcondicionpagoid: 0,
                pformapagoid: 0,
                pplazofacturacionid: 0,
                pobservaciones: e.data.observaciones,
                pclienteid: e.data.clienteid,
                pclientecontactoid: e.data.clientecontactoid,
                pusuarioregistroid: idusuario,
            });

            /**
            this.solicitudService.createSolicitud(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });
             */

            this.solicitudService.createSolicitud(myJSON).then(data => {
                if (data.err == 0) {
                    this.type = 'success';
                    this.message = 'Se ha creado el registro';
                    this.isVisible = true;
                    this.loadDataInfo();
                } else {

                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                }
            });

        },
        onInitNewRow(e) {
            e.data.bactivo = true;
        },
        computedDatesFormat(value) {
            return moment(value).format('DD/MM/YYYY')
        }
    },
};
