
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxLookup,
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,
    
    DxEmailRule,
    DxToolbar,
    //DxAsyncRule,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import { DxButton as DxButtonButton } from 'devextreme-vue/button';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import RolService from '../services/roles-service';
import UsuarioService from '../services/usuario-service';
 import { createStore } from 'devextreme-aspnet-data-nojquery';
 
import Cookies from "js-cookie";
import ClienteService from '../services/cliente-service';
window.Cookies = Cookies;

 
 


export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxLookup,
        DxForm,
        DxItem,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule,
        DxToolbar,
        DxButtonButton,
        DxPopup,
        DxToolbarItem,
        DxSelectBox,
        DxEmailRule,
        DxTextBox,
        DxCheckBox
        //DxAsyncRule,
    },
    data() {
        return {
            name:null,
            procedimientos: null,
            dataSourceUsuarios: null,
            dataSourceRoles: null,
            dataSourceClientes: null,
            datosCliente: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            popupVisible: false,

            popupCliente: null,
            popupCorreo: null,
            popupNombre: null,
            popupActivo: true,
            popupUsuario: null,

            message: '',
            type: 'info',
            popupOptions: {
                title: 'Usuarios',
                showTitle: true,
                width: 800,
                height: 450,
            },
            suppliersData: createStore({
                key: 'SupplierID',
            }),
        };
    },
    created() {
        this.usuarioService = new UsuarioService();
        this.rolService = new RolService();
        this.clienteService = new ClienteService();
        this.name = this.$route.params.name;
    },
     
    mounted() {
        this.loadDataInfo();
       
        console.log('*********************',this.name);
         //this.name = this.$router.params.name;
      
    },
    methods: {
        loadDataInfo() {

            this.rolService.getRoles().then(data => this.dataSourceRoles = data);
            this.usuarioService.getUsuarioEmpresa().then(data => this.dataSourceUsuarios = data);


           
        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },
        editProcedure(e) {
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let vactivo = e.data.bactivo == true ? 1 : 0;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pnombre: e.data.nombre,
                ppaterno: '',
                pmaterno: '',
                pusuario: e.data.usuario,
                pcontrasena: '12345',
                pcorreo: e.data.correo,
                pusuarioregistroid: idusuario,
                pbactivo: vactivo,
            });
             

            this.usuarioService.updateUsuarioEmpresa(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha actualizado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });
        },
        deleteProcedure(e) {
            let myJSON = JSON.stringify({
                pid: e.data.id,
            });


            this.usuarioService.deleteUsuario(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });
        },
        saveConfiguracion() {


            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let vactivo = this.popupActivo == true ? 1 : 0;

            let myJSON = JSON.stringify({
                pnombre: this.popupNombre,
                ppaterno: "",
                pmaterno: "",
                pusuario: this.popupUsuario,
                pcorreo: this.popupCorreo,
                pusuarioregistroid: idusuario,
                pclienteid: this.datosCliente.id,
                pbactivo: vactivo,
            });


            this.usuarioService.createUsuarioEmpresa(myJSON).then(data => {
                //console.log(data);
                if(data.err == 0){
                    this.type = 'success';
                    this.message = 'Se ha creado el registro';
                    this.isVisible = true;
                    this.popupVisible = false;
                    this.enviaCredenciales(data);

                } else {
                    
                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                }
                
            });
        },
        agregarRegistro(){
            
            this.clienteService.getCliente().then(data =>{
                
                this.dataSourceClientes = data.filter(item1 =>
                    this.dataSourceUsuarios.every(item2 => item1.id !== item2.clienteid));
    
            });

            this.popupActivo = true;

            this.popupNombre = null;
            this.popupCorreo = null;
            this.popupUsuario = null;

            this.popupVisible = true;

        },
        enviaCredenciales(datos){

            let myJSON = {
                correousuario: datos.correodestino,
                titulo: datos.msgcorreotitulo,
                nombre: datos.nombre,
                usuario: datos.usuario,
                contrasena: datos.contrasena
            }

            
            this.usuarioService.enviaCredenciales(myJSON).then(()=>{
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
                this.loadDataInfo();

            });
        },
        
        onValueChanged(e){
            this.datosCliente = this.dataSourceClientes.find(item => item.id === e.value);

            this.popupNombre = this.datosCliente.nombre;
            this.popupUsuario = this.datosCliente.codigocliente;
            this.popupCorreo = this.datosCliente.correo;
        },
        onInitNewRow(e) {
            e.data.bactivo = true;
        },
  },
};
