import axios from 'axios';

export default class ConsideracionService {

    getConsideracion() {            
        const url = process.env.VUE_APP_APIURL + `/api/consideracion`;    
        return axios.get(url).then(res=>res.data.data);
    }

  

    updateConsideracion(datos){
        const url = process.env.VUE_APP_APIURL + `/api/consideracionedicion/${datos.id}`;
        return axios.put(url,datos).then(res=>res.data.data);

    }

   

    createConsideracion(datos){
        const url = process.env.VUE_APP_APIURL + `/api/consideracionalta`; 
        //console.log(axios.post(url,datos).then(res=>res.data.data));    
        return axios.post(url,datos).then(res=>res.data.data);
        //return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteConsideracion(datos){
       
        const url = process.env.VUE_APP_APIURL + `/api/consideracionbaja/${datos.id}`;
        //return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
       
        return axios.delete(url).then(res=>res.data.data);
    }


    getConsideracionCotizacion(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionconsideracion`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    createConsideracionCotizacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionconsideracionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteConsideracionCotizacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionconsideracionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    
}