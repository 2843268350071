
import {
  DxGantt,
  DxTasks,
  DxDependencies,
  DxResources,
  DxResourceAssignments,
  DxColumn,
  DxEditing,
} from 'devextreme-vue/gantt';
import DxCheckBox from 'devextreme-vue/check-box';
import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/date-box';

import {
  tasks,
  dependencies,
  resources,
  resourceAssignments,
} from './../data/data3.js';

export default {
  components: {
    DxGantt,
    DxTasks,
    DxDependencies,
    DxResources,
    DxResourceAssignments,
    DxColumn,
    DxEditing,
    DxCheckBox,
    DxSelectBox,
    DxDateBox,
  },
  data() {
    return {
      tasks,
      dependencies,
      resources,
      resourceAssignments,
      scaleType: 'months',
      taskTitlePosition: 'outside',
      showResources: true,
      showDependencies: true,
      showCustomTaskTooltip: true,
      startDateRange: new Date(2018, 11, 1),
      endDateRange: new Date(2019, 11, 1),
    };
  },
  methods: {
    getTimeEstimate(task) {
      return Math.abs(task.start - task.end) / 36e5;
    },
    getTimeLeft(task) {
      const timeEstimate = Math.abs(task.start - task.end) / 36e5;
      return Math.floor(((100 - task.progress) / 100) * timeEstimate);
    },
    getTime(date) {
      return date.toLocaleString();
    },
  },
};
