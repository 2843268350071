import axios from 'axios';

export default class GuiaEvaluacionService {

    getGuiaEvaluacion() {            
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacion`;    
        return axios.get(url).then(res=>res.data);
    }

  
    updateGuiaEvaluacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createGuiaEvaluacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteGuiaEvaluacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    
    getGuiaEvaluacionBase() {            
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionbase`;    
        return axios.get(url).then(res=>res.data);
    }
}