import axios from 'axios';

export default class SolicitudServicioService {

    getSolicitudServicio(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/solicitudservicio`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateSolicitudServicio(info){
        const url = process.env.VUE_APP_APIURL + `/api/solicitudservicioedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateSolicitudServicioEstatus(info){
        const url = process.env.VUE_APP_APIURL + `/api/solicitudservicioedicionestatus`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createSolicitudServicio(info){
        const url = process.env.VUE_APP_APIURL + `/api/solicitudservicioalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteSolicitudServicio(info){
        const url = process.env.VUE_APP_APIURL + `/api/solicitudserviciobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}