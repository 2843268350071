import axios from 'axios';

export default class EtapaService {

    getEtapa() {            
        const url = process.env.VUE_APP_APIURL + `/api/etapa`;    
        return axios.get(url).then(res=>res.data);
    }

    updateEtapa(info){
        const url = process.env.VUE_APP_APIURL + `/api/etapaedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createEtapa(info){
        const url = process.env.VUE_APP_APIURL + `/api/etapaalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteEtapa(info){
        const url = process.env.VUE_APP_APIURL + `/api/etapabaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}