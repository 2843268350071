
  //import { nullLiteral } from '@babel/types';
  import {
    DxDataGrid, DxColumn, DxEditing, DxPaging
  } from 'devextreme-vue/data-grid';
  import { DxToast } from 'devextreme-vue/toast';
  //import EstatusService from '../services/estatus-service';
  import TipoFolioService from '../services/tipofolio-service';
  import Cookies from "js-cookie";
//import { data } from '../data/data';
  window.Cookies = Cookies;
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxEditing,
      DxPaging,
      DxToast,
    },
    data() {
      return {
        dataSourceCompany: null,
        empresa: {},
        events: [],
        tipoFolioService: null,
        isVisible: false,
        message: '',
        type: 'info',
        popupOptions: {
          title: 'Tipo Folio',
          showTitle: true,
          width: 420,
          height: 250,
        },
      };
    },
    created() {
      //this.estatusService = new EstatusService();
      this.tipoFolioService = new TipoFolioService(); 
    },
    mounted() {
      this.loadinfo();
      
    },
    methods: {
      logEvent(eventName) {
        this.events.unshift(eventName);
      },
      loadinfo() {
  
        this.useconver = Cookies.get("user");
        
  
        this.tipoFolioService.getTipoFolio().then(data => this.dataSourceCompany = data);
      },
      editRegister(e) {

        let myJSON = JSON.stringify({
          pid: e.data.id,
          pdescripcion: e.data.descripcion
        });

        this.tipoFolioService.updateTipoFolio(myJSON).then(data => {
          
            if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;
            this.loadinfo();
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
  
          }
        });
      },
      deleteRegister(e) {

        let myJSON = JSON.stringify({
          pid: e.data.id,
        });
  
        this.tipoFolioService.deleteTipoFolio(myJSON).then(data => {
  
            if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
            this.loadinfo();
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            this.loadinfo();
  
          }
        });
  
  
      },
      createRegister(e) {

        let myJSON = JSON.stringify({
          pdescripcion: e.data.descripcion,
          pbrequiereservicios: 1,
        });
  
        this.tipoFolioService.createTipoFolio(myJSON).then(data => {
            if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
            
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            
          }
          this.loadinfo();
        });
  
  
  
      },
      onInitNewRow(e) {
        e.data.brequiereservicios = true;
      },
    },
  };
  