
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,
    DxMasterDetail
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import LeyService from '../services/ley-service';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import MasterDetail from './parametrizacionleyMasterDetail.vue';
import Cookies from "js-cookie";
window.Cookies = Cookies;



export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule,
        DxMasterDetail,
        MasterDetail
    },
    data() {
        return {
            procedimientos: null,
            dataSourceUsuarios: null,
            dataSourceRoles: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Leyes y Fundamentos',
                showTitle: true,
                width: 800,
                height: 450,
            },
            suppliersData: createStore({
                key: 'SupplierID',
            }),
        };
    },
    created() {
        this.leyService = new LeyService();

    },
    mounted() {
        this.loadDataInfo();
    },
    methods: {
        loadDataInfo() {

            this.leyService.getLey().then(data => this.dataSourceUsuarios = data);

        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },
        editProcedure(e) {

            let vactivo = e.data.bactivo == true ? 1 : 0;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pnombre: e.data.nombre,
                ppaterno: e.data.paterno,
                pmaterno: e.data.materno,
                pusuario: e.data.usuario,
                pcontrasena: e.data.contrasena,
                pcorreo: e.data.correo,
                prolid: e.data.rolid,
                pbactivo: vactivo,
                ppuesto: '',
                phabilidad: '',
            });


            this.usuarioService.updateUsuario(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha actualizado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });
        },
        deleteProcedure(e) {
            let myJSON = JSON.stringify({
                pid: e.data.id,
            });


            this.usuarioService.deleteUsuario(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });
        },
        createProcedure(e) {


            let idusuario = JSON.parse(Cookies.get("ets_user")).id; 
            let vactivo = e.data.bactivo == true ? 1 : 0;
            let myJSON = JSON.stringify({
                pnombre: e.data.nombre,
                ppaterno: e.data.paterno,
                pmaterno: e.data.materno,
                pusuario: e.data.usuario,
                pcontrasena: e.data.contrasena,
                pcorreo: e.data.correo,
                pusuarioregistroid: idusuario,
                prolid: e.data.rolid,
                pbactivo: vactivo,
                ppuesto: '',
                phabilidad: '',
            });


            this.usuarioService.createUsuario(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });
        },
        onInitNewRow(e) {
            e.data.bactivo = true;
        },
    },
};
