
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxLookup,
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,
    DxFormItem,
    DxGrouping,
    DxGroupPanel
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import 'devextreme-vue/text-area';

import CumplimientoService from '../services/cumplimiento-service';
import GuiaEvaluacionEstandarService from '../services/guiaevaluacionestandar-service';
import GuiaEvaluacionCapituloService from '../services/guiaevaluacioncapitulo-service';
import ClasificacionService from '../services/clasificacion-service';



export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxLookup,
        DxForm,
        DxItem,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule,
        DxFormItem,
        DxGrouping,
        DxGroupPanel
    },
    props: {
        supplierId: {
            type: Number,
            default: null,
        },
    },
    data() {

        return {
            dataClasificacion: null,
            procedimientos: null,
            dataSourceEstandar: null,
            dataSourceCapitulo: null,
            dataSourceCumplimiento: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Estandar - Guia',
                showTitle: true,
                width: 650,
                height: 650,
            },



        };
    },
    created() {
        this.GuiaEvaluacionEstandarService = new GuiaEvaluacionEstandarService();
        this.GuiaEvaluacionCapituloService = new GuiaEvaluacionCapituloService();
        this.CumplimientoService = new CumplimientoService();
        this.ClasificacionService = new ClasificacionService();

    },
    mounted() {

        this.loadDataInfo();
    },
    methods: {
        loadDataInfo() {



            let id = this.supplierId;
            this.CumplimientoService.getCumplimiento().then(data => this.dataSourceCumplimiento = data);

            let myJSON = JSON.stringify({
                pguiaevaluacionid: id,

            });

            this.GuiaEvaluacionCapituloService.getGuiaEvaluacionCapitulo(myJSON).then(data => this.dataSourceCapitulo = data);
            this.GuiaEvaluacionEstandarService.getGuiaEvaluacionEstandar(myJSON).then(data => this.dataSourceEstandar = data);
            this.ClasificacionService.getClasificacion().then(data => this.dataClasificacion = data);
            
        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },

        deleteRegister(e) {

            let myJSON = JSON.stringify({
                pid: e.data.id,
            });


            this.GuiaEvaluacionEstandarService.deleteGuiaEvaluacionEstandar(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        editRegister(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,
                pguiaevaluacioncapituloid: e.data.guiaevaluacioncapituloid,
                pdescripcion: e.data.descripcion,
                porden: e.data.orden,
                pcumplimientoid: e.data.cumplimientoid,
                pclasificacionid:e.data.clasificacionid,
                pclasificaciondescripcion:e.data.clasificaciondescripcion,
            });


            this.GuiaEvaluacionEstandarService.updateGuiaEvaluacionEstandar(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha actualizado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        createRegister(e) {

            let myJSON = JSON.stringify({
                pguiaevaluacioncapituloid: e.data.guiaevaluacioncapituloid,
                pdescripcion: e.data.descripcion,
                porden: e.data.orden,
                pcumplimientoid: e.data.cumplimientoid,
                pclasificacionid:e.data.clasificacionid,
                pclasificaciondescripcion:e.data.clasificaciondescripcion,
            });


            this.GuiaEvaluacionEstandarService.createGuiaEvaluacionEstandar(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        onInitNewRow(e) {
            e.data.bactivo = true;
        },
    },
};
