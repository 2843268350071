
//import { nullLiteral } from '@babel/types';
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import RolesService from '../services/roles-service';
import notify from 'devextreme/ui/notify';
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {
      dataSourceCompany: null,
      empresa: {},
      events: [],
      rolService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.rolService = new RolesService();
  },
  mounted() {
    this.rolService.getRoles().then(data => {
      data.forEach(
        element => {

          if (element.bactivo == 1) {
            element.bactivo = true;
          } else {
            element.bactivo = false;
          }

        });
      this.dataSourceCompany = data;

    });

  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editRegister(e) {
      let vactivo = e.data.bactivo == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pid: e.data.id,
        pdescripcion: e.data.descripcion,
        pbactivo: vactivo,
      });

      this.rolService.updateRoles(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });

    },
    deleteRegister(e) {

      let myJSON = JSON.stringify({
        pid: e.data.id,
      });
      console.log(myJSON);
      this.rolService.deleteRoles(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
    },
    createRegister(e) {
      let vactivo = e.data.bactivo == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pdescripcion: e.data.descripcion,
        pbactivo: vactivo,
      });

      if (e.data.descripcion == '' || e.data.descripcion == null) {
        notify('Capture la descripcion', "error", 2000);
      } else {
        this.rolService.createRoles(myJSON).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
        });
      }


    },
  },
};
