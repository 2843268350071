import axios from 'axios';

export default class GrupoListadoInformacionDetalleService {

    getGrupoListadoInformacionDetalle(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/grupolistadoinformaciondetalle`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateGrupoListadoInformacionDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupolistadoinformaciondetalleedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createGrupoListadoInformacionDetalle(info){
        

        const url = process.env.VUE_APP_APIURL + `/api/grupolistadoinformaciondetallealta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteGrupoListadoInformacionDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupolistadoinformaciondetallebaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}