
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxLookup,
    DxForm,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxFormItem,
    DxButton
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import LeyService from '../services/ley-service';
import TipoLeyService from '../services/tipoley-service';
import 'devextreme-vue/text-area';
import Cookies from "js-cookie";
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import DxCheckBox from 'devextreme-vue/check-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import DxTextArea from 'devextreme-vue/text-area';
window.Cookies = Cookies;

export default {
    components: {
        DxDataGrid,
        DxEditing,
        DxColumn,
        DxValidationRule,
        DxPaging,
        DxLookup,
        DxForm,
        DxItem,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxSelection,
        DxToast,
        DxFormItem,
        DxPopup,
        DxButton,
        DxToolbarItem,
        DxFileUploader,
        DxTextBox,
        DxSelectBox,
        DxTextArea,
        DxCheckBox,
    },
    data() {
        return {
            popupTitle: "Fundamentos",
            popupVisible: false,
            v_nombrelargo: null,
            v_nombrecorto: null,
            v_tipofundamento:null,

            popupTitle2: "Fundamentos Edición",
            popupVisible2: false,
            v_leyid :null,
            v_nombrecortoedicion :null,
            v_nombrelargoedicion:null,
            v_tipofundamentoedicion:null,
            v_tienearchivoedicion:null,
            v_activo:null,
            v_ruta:null,


            productos: null,
            dataSourceProducts: null,
            dataSourceTipoLey: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Leyes - Fundamentos',
                showTitle: true,
                width: 500,
                height: 'auto',
            },
            leyService: null,
            tipoleyService: null,
            backendURL: process.env.VUE_APP_APIURL,
        };
    },
    created() {
        this.leyService = new LeyService();
        this.tipoleyService = new TipoLeyService();
    },
    mounted() {


        this.loadDataInfo();

    },

    methods: {
        loadDataInfo() {
            this.leyService.getLey().then(data => {
                this.tipoleyService.getTipoLey().then(data => this.dataSourceTipoLey = data);
                data.forEach(
                    element => {

                        if (element.bactivo == 1) {
                            element.bactivo = true;
                        } else {
                            element.bactivo = false;
                        }

                    });
                this.dataSourceProducts = data;

            });
        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },


        showPopup1() {

           
            this.popupVisible = true;
            this.v_nombrecorto = '';
            this.v_nombrelargo = '';
            this.v_tipofundamento = null;

        },
        editLey(e){


        this.popupVisible2 = true;
        this.v_nombrecortoedicion = e.row.data.descripcioncorta;
        this.v_nombrelargoedicion= e.row.data.descripcionlarga;
        this.v_tipofundamentoedicion = e.row.data.tipoleyid;
        this.v_tienearchivoedicion = e.row.data.tienearchivo;
        this.v_activo = e.row.data.bactivo;
        this.v_ruta = e.row.data.ruta;
        this.v_leyid = e.row.data.id;

        },
                
        onUploaded(e) {




            let vactivo = 1;
            let res = JSON.parse(e.request.responseText);
            let archivosubido = res.image_name;
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;
            let myJSON = JSON.stringify({
                pdescripcioncorta: this.v_nombrecorto,
                pdescripcionlarga: this.v_nombrelargo,
                pusuarioregistroid: idusuario,
                pbactivo: vactivo,
                ptipoleyid: this.v_tipofundamento,
                pruta: archivosubido,
            });
        
            let err = 0;
            let msgerror = '';

            if (this.v_tipofundamento == null || this.v_tipofundamento == '' || this.v_tipofundamento == 'undefined') {
                err = 1;
                msgerror = 'Ingresar tipo fundamento';
            }

            if (err == 0) {
                if (this.v_nombrecorto == null || this.v_nombrecorto == '' || this.v_nombrecorto == 'undefined') {
                    err = 1;
                    msgerror = 'Ingresar nombre corto';
                }
            }

            if (err == 0) {
                if (this.v_nombrelargo == null || this.v_nombrelargo == '' || this.v_nombrelargo == 'undefined') {
                    err = 1;
                    msgerror = 'Ingresar nombre largo';
                }
            }


            if (err == 0) {
                this.leyService.createLey(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 50000);
                        this.popupVisible = false;
                         
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }


                });
            } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                    message: this.message,
                }, this.type, 4000);

            }
        },
        onUploadError(e) {
            let xhttp = e.request;
            if (xhttp.status === 400) {
                e.message = e.error.responseText;
            }
            if (xhttp.readyState === 4 && xhttp.status === 0) {
                e.message = "Conexión rechazada";
            }
        },

        downloadfile() {
            let name_file = this.v_ruta;//e.row.data.ruta;

            axios({
                url: process.env.VUE_APP_APIURL + '/api/archivodownload?name_file=' + name_file,
                method: 'GET',
                responseType: 'blob',

            }).then((res) => {
                var url = window.URL.createObjectURL((res.data));
                var namepdf = name_file;

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', namepdf);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });



        },

        onUploadededicion(e) {

            let res = JSON.parse(e.request.responseText);
            let archivosubido = res.image_name;
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;
            let myJSON = JSON.stringify({
                pid: this.v_leyid,
                pdescripcioncorta: this.v_nombrecortoedicion,
                pdescripcionlarga: this.v_nombrelargoedicion,
                pusuarioregistroid: idusuario,
                pbactivo: this.v_activo,
                ptipoleyid: this.v_tipofundamentoedicion,
                pruta: archivosubido,
            });

            let err = 0;
            let msgerror = '';

            if (this.v_tipofundamentoedicion == null || this.v_tipofundamentoedicion == '' || this.v_tipofundamentoedicion == 'undefined') {
                err = 1;
                msgerror = 'Ingresar tipo fundamento';
            }

            if (err == 0) {
                if (this.v_nombrecortoedicion == null || this.v_nombrecortoedicion == '' || this.v_nombrecortoedicion == 'undefined') {
                    err = 1;
                    msgerror = 'Ingresar nombre corto';
                }
            }

            if (err == 0) {
                if (this.v_nombrelargoedicion == null || this.v_nombrelargoedicion == '' || this.v_nombrelargoedicion == 'undefined') {
                    err = 1;
                    msgerror = 'Ingresar nombre largo';
                }
            }


            if (err == 0) {
                this.leyService.updateLey(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 50000);
                        this.popupVisible2 = false;
                        
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);

                        
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;

                        
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }


                });
            } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                    message: this.message,
                }, this.type, 4000);

            }
            },

        actualizarDatos(){
            
          
            
            //let res = JSON.parse(e.request.responseText);
            //let archivosubido = res.image_name;
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;
            let myJSON = JSON.stringify({
                pid: this.v_leyid,
                pdescripcioncorta: this.v_nombrecortoedicion,
                pdescripcionlarga: this.v_nombrelargoedicion,
                pusuarioregistroid: idusuario,
                pbactivo: this.v_activo,
                ptipoleyid: this.v_tipofundamentoedicion,
                pruta: this.v_ruta
            });
           
            console.log(myJSON);
         
            let err = 0;
            let msgerror = '';

            if (this.v_tipofundamentoedicion == null || this.v_tipofundamentoedicion == '' || this.v_tipofundamentoedicion == 'undefined') {
                err = 1;
                msgerror = 'Ingresar tipo fundamento';
            }

            if (err == 0) {
                if (this.v_nombrecortoedicion == null || this.v_nombrecortoedicion == '' || this.v_nombrecortoedicion == 'undefined') {
                    err = 1;
                    msgerror = 'Ingresar nombre corto';
                }
            }

            if (err == 0) {
                if (this.v_nombrelargoedicion == null || this.v_nombrelargoedicion == '' || this.v_nombrelargoedicion == 'undefined') {
                    err = 1;
                    msgerror = 'Ingresar nombre largo';
                }
            }


            if (err == 0) {
                this.leyService.updateLey(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 50000);
                        this.popupVisible2 = false;
                         
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }


                });
            } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                    message: this.message,
                }, this.type, 4000);

            }
        },

        editRegister(e) {
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let vactivo = e.data.bactivo == true ? 1 : 0;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pdescripcioncorta: e.data.descripcioncorta,
                pdescripcionlarga: e.data.descripcionlarga,
                pusuarioregistroid: idusuario,
                pbactivo: vactivo,
                ptipoleyid: e.data.tipoleyid,
            });


            this.leyService.updateLey(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha actualizado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        deleteRegister(e) {
            let myJSON = JSON.stringify({
                pid: e.data.id,
            });
            console.log(myJSON);

            this.leyService.deleteLey(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        createRegister(e) {

            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let vactivo = e.data.bactivo == true ? 1 : 0;
            let myJSON = JSON.stringify({

                pdescripcioncorta: e.data.descripcioncorta,
                pdescripcionlarga: e.data.descripcionlarga,
                pusuarioregistroid: idusuario,
                pbactivo: vactivo,
                ptipoleyid: e.data.tipoleyid,
            });

            this.leyService.createLey(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },

        onInitNewRow(e) {
            e.data.serializable = true;
            e.data.bactivo = true;
            //e.data.id_equipo_padre = null;      
            //this.equipmentService.getProductoPadres().then(data => this.dataSourceFatherItems=data);
        },
    },
};
