
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,

    DxGrouping,
    DxGroupPanel,
    DxButton,
    DxItem,
    DxForm,
    DxFormItem,
} from 'devextreme-vue/data-grid';

import { DxToast } from 'devextreme-vue/toast';

import 'devextreme-vue/text-area';
import moment from 'moment';
import CotizacionService from '../services/cotizacion-service';
import CotizacionServicioService from '../services/cotizacionservicio-service';
import SolicitudService from '../services/solicitud-service';
import CompromisoService from '../services/compromisopago-service';
import CondicionService from '../services/condicionpago-service';
import FormaService from '../services/formapago-service';
import PlazoService from '../services/plazofacturacion-service';
import EstatusService from '../services/estatus-service';
import CotizacionConfiguracionFormatoService from '../services/cotizacionconfiguracionformato-service';
import ClienteContactoService from '../services/clientecontacto-service';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import Cookies from "js-cookie";
window.Cookies = Cookies;
import notify from 'devextreme/ui/notify';


import DxPopup  from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import { DxTextBox } from 'devextreme-vue/text-box';
import DxDateBox from 'devextreme-vue/date-box';
import DxTextArea from 'devextreme-vue/text-area';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxNumberBox } from 'devextreme-vue/number-box';

import { jsPDF } from "jspdf";
import 'jspdf-autotable';

export default {
    components: {
        DxFormItem,
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxItem,
        DxForm,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule,
      
        DxGrouping,
        DxGroupPanel,
        DxButton,
        DxPopup,
        DxScrollView,
       
        DxTextBox,
        DxDateBox,
        DxTextArea,
        DxSelectBox,
        DxNumberBox,
    },
    data() {
        return {

            popupVisible: false,
            popupTitle: "Alta Cotización",
            loading: true,

            popupVisible2: false,
            popupTitle2: "Cotización",
            loading2: true,

            popupVisible3: false,
            popupTitle3: "Agregar Partida a Cotización",

            popupVisible4: false,
            popupTitle4: "Editar Partida a Cotización",

            popupVisible5: false,
            popupTitle5: "Editar Configuración Formato Cotización",
            frm_conf_id: null,
            frm_conf_cotizacion: null,
            frm_conf_dirigido: null,
            frm_conf_titulo: null,
            frm_conf_alcance: null,
            frm_conf_responsabilidad: null,
            frm_conf_formapago: null,

            dataSourceClienteContacto: null,
            dataSourceClienteContactoEditar: null,
            frm_clientecontacto: null,
            frm_ed_clientecontacto: null,

            ivaconf: null,
            frm_id: null,
            frm_solicitudid: null,
            frm_tipocotizacion:null,
            frm_numerocotizacion: null,
            frm_fechavigenciacotizacion: null,
            frm_fechadeseadaservicio: null,
            frm_tiempoentrega: null,
            frm_compromisopagoid: null,
            frm_condicionpagoid: null,
            frm_formapagoid: null,
            frm_plazofacturacionid: null,
            frm_observaciones: null,
            frm_servicio: null,
            frm_subtotal: null,
            frm_iva: null,
            frm_total: null,
    
          
            frm_ed_numerocotizacion: null,
            frm_ed_fechavigenciacotizacion: null,
            frm_ed_fechadeseadaservicio: null,
            frm_ed_tiempoentrega: null,
            frm_ed_compromisopagoid: null,
            frm_ed_condicionpagoid: null,
            frm_ed_formapagoid: null,
            frm_ed_plazofacturacionid: null,
            frm_ed_observaciones: null,
            frm_ed_estatus: null,
            frm_ed_servicio: null,
            frm_ed_subtotal: null,
            frm_ed_iva: null,
            frm_ed_total: null,

            frm_partida_id: null,
            frm_partida_partida: null,
            frm_partida_servicio: null,
            frm_partida_subtotal: null,
            frm_partida_tiempoentrega: null,
            frm_partida_iva: null,
            frm_partida_total: null,
            frm_partida_observaciones: null,
            frm_partida_servicioid: null,
            frm_partida_estatusid:null,

            dataPDF_fechaEmision: null,
            dataPDF_lugarFecha: null,
            dataPDF_nombre: null,
            dataPDF_RFC: null,
            dataPDF_razonSocial: null,
            dataPDF_fechaVigencia: null,
            dataPDF_observaciones: null,
            dataPDF_codigocotizacion: null,
            dataPDF_subtotal: null,
            dataPDF_iva: null,
            dataPDF_total: null,

            dataPDF_dirigido: null,
            dataPDF_titulo: null,
            dataPDF_alcance: null,
            dataPDF_responsabilidad: null,
            dataPDF_formapago: null,

            dataPDF_servicios: null,
            dataPDF_terminos: null,
           
            dataSourcePartidasCotizar: null,
            dataSourcePartidasAsignadas:null,

            dataSourceTipoCotizacion: [{
                                            id: 1,
                                            descripcion: 'Generar Código Automático'
                                            }, {
                                                id: 2,
                                            descripcion: 'Generar Código Manual (Cotización Anterior)'
                                            }],
            dataSourceSolCot: null,
            v_es_manual:'N',
            dataSourceCotizacion: null,
            dataSourceSolicitud: null,
            dataSourceCondicion: null,
            dataSourceCompromiso: null,
            dataSourceForma: null,
            dataSourcePlazo: null,
            dataSourceEstatus:  [
                                    {
                                        "id": 1,
                                        "descripcion": "Pendiente",
                                        "bactivo": 1
                                    },
                                    {
                                        "id": 4,
                                        "descripcion": "Aprobada",
                                        "bactivo": 1
                                    },
                                    {
                                        "id": 5,
                                        "descripcion": "Rechazada",
                                        "bactivo": 1
                                    }
                                ],
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Cotizaciones',
                showTitle: true,
                width: 850,
                height: 650,
            },
            popupOptionsAltaCot: {
                title: 'Cotizaciones',
                showTitle: true,
                width: 1000,
                height: 750,
            },
            suppliersData: createStore({
                key: 'SupplierID',
            }),
        };
    },
    created() {
        this.ClienteContactoService = new ClienteContactoService();
        this.cotizacionService = new CotizacionService();
        this.cotizacionServicioService = new CotizacionServicioService();
        this.solicitudService = new SolicitudService();
        this.compromisoService = new CompromisoService();
        this.condicionService = new CondicionService();
        this.formaService = new FormaService();
        this.plazoService = new PlazoService();
        this.estatusService = new EstatusService();
        this.cotizacioncofiguracionformatoService = new CotizacionConfiguracionFormatoService();
    },
    mounted() {
        this.loadDataInfo();
    },
    methods: {
        onValueChanged_cod(e) {
            this.v_es_manual='N';
            this.frm_numerocotizacion = '';
            if(e.value == '2')

                this.v_es_manual='S';

                },

        deleteRegister(e) {
            let myJSON = JSON.stringify({
                pid: e.data.id,
            });


            this.cotizacionService.deleteCotizacion(myJSON).then(data => {

                if (data.err == 0) {
                    this.type = 'success';
                    this.message = 'Se ha eliminado el registro';
                    this.isVisible = true;

                    notify({
                        message: this.message,
                    }, this.type, 5000);
                    this.popupVisible = false;
                    this.loadDataInfo();
                } else {

                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                    this.loadDataInfo();
                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                    }, this.type, 4000);


                }
                //this.loadDataInfo();

            });




        },
        numberBoxValueChanged(e) {

            this.frm_partida_iva = parseFloat(e.value) * 0.16;//this.ivaconf;
            this.frm_partida_total = parseFloat(this.frm_partida_subtotal) + parseFloat(this.frm_partida_iva);
            // Event handling commands go here
        },
        numberBoxValueChanged2(e) {

            this.frm_partida_iva = parseFloat(e.value) * 0.16;//this.ivaconf;
            this.frm_partida_total = parseFloat(this.frm_partida_subtotal) + parseFloat(this.frm_partida_iva);
            // Event handling commands go here
        },
        onValueChanged(e) {

            if (this.frm_solicitudid != null) {
                let myJSON = JSON.stringify({
                    pid: e.value,
                });

                this.solicitudService.getSolicitudDatosParaCotizacion(myJSON).then(data => {
                    this.frm_fechadeseadaservicio = data.fechadeseadaservicio;
                    this.frm_tiempoentrega = data.tiempoentrega;
                    this.frm_servicio = data.servicio;
                    this.ivaconf = data.ivaconf;
                    this.frm_subtotal = 0;
                    this.frm_iva = 0;
                    this.frm_total = 0;
                });

                let myJSON2 = JSON.stringify({
                    psolicitudid: e.value,
                });
                this.ClienteContactoService.getClienteContactoCotizacion(myJSON2).then(data => {
                    this.dataSourceClienteContacto = data;
                });
            }
            else {
                this.frm_fechadeseadaservicio = null;
                this.frm_tiempoentrega = null;
                this.frm_servicio = null;
                this.frm_subtotal = 0;
                this.frm_iva = 0;
                this.frm_total = 0;
                this.dataSourceClienteContacto = null;
            }
        },


        muestraConfiguracion(e) {
            this.frm_conf_id = null;
            this.frm_conf_cotizacion = null;
            this.frm_conf_dirigido = null;
            this.frm_conf_titulo = null;
            this.frm_conf_alcance = null;
            this.frm_conf_responsabilidad = null;
            this.frm_conf_formapago = null;

            let myJSON = JSON.stringify({
                    pcotizacionid: e.row.data.id,
                });
                
                 
                this.cotizacioncofiguracionformatoService.getCotizacionConfiguracionFormato(myJSON).then(data => {
                    this.frm_conf_id = data[0].id;
                    this.frm_conf_cotizacion = data[0].numerocotizacion;
                    this.frm_conf_dirigido = data[0].dirigido;
                    this.frm_conf_titulo = data[0].titulo;
                    this.frm_conf_alcance = data[0].alcance;
                    this.frm_conf_responsabilidad = data[0].responsabilidad;
                    this.frm_conf_formapago = data[0].formapago;
                });

            this.loading = true;
            this.popupVisible5 = true;

        },


        agregarCotizacion() {
            this.dataSourcePartidasCotizar = null;
            this.frm_id = null;
            this.frm_solicitudid = null;
            this.frm_tipocotizacion = null;
            this.frm_numerocotizacion = null;
            this.frm_fechavigenciacotizacion = null;
            this.frm_fechadeseadaservicio = null;
            this.frm_tiempoentrega = null;
            this.frm_compromisopagoid = null;
            this.frm_condicionpagoid = null;
            this.frm_formapagoid = null;
            this.frm_plazofacturacionid = null;
            this.frm_observaciones = null;
            this.frm_servicio = null;
            this.frm_subtotal = 0;
            this.frm_iva = 0;
            this.frm_total = 0;
            this.frm_clientecontacto = null;

            //this.dataSourceSolicitud = null;
            let myJSON = JSON.stringify({
                pid: 0,
            });
            this.solicitudService.getSolicitudCotizar(myJSON).then(data => this.dataSourceSolicitud = data);

            this.loading = true;
            this.popupVisible = true;

        },

        obtienePartidas(id) {
            
            let myJSON = JSON.stringify({
                pid: id,
            });
            console.log("OBTIENE PARTIDAS",myJSON);

                this.solicitudService.getSolicitudPartidasCotizar(myJSON).then(data => {
                    this.dataSourcePartidasCotizar = data;
                     });

        },

        obtienePartidasAsignadas(id) {
            
            let myJSON = JSON.stringify({
                pid: id, //id cotizacion
            });
            

                this.cotizacionService.getCotizacionPartidasAsignadas(myJSON).then(data => {
                    this.dataSourcePartidasAsignadas = data;
                     });

        },

        saveRegisterPartida() {

            

            let err = 0;
            let msgerror = '';

            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;

            if (this.frm_partida_subtotal == null) {
                err = 1;
                msgerror = 'Ingresar subtotal';
            }
            if (err == 0) {
                if (this.frm_partida_subtotal == 0) {
                    err = 1;
                    msgerror = 'Ingresar subtotal';
                }
            }
            if (err == 0) {
                if (this.frm_partida_subtotal == '0') {
                    err = 1;
                    msgerror = 'Ingresar subtotal';
                }
            }
            if (err == 0) {
                if (this.frm_partida_estatusid == null || this.frm_partida_estatusid == 'null' ) {
                    err = 1;
                    msgerror = 'Ingresar estatus';
                }
            }
            if (this.frm_partida_tiempoentrega == null) {
                this.frm_partida_tiempoentrega=0;
            }
 
            let myJSON = JSON.stringify({
                pcotizacionid: this.frm_id,
                pservicioid: this.frm_partida_servicioid,
                psubtotal: this.frm_partida_subtotal,
                piva: this.frm_partida_iva,
                ptotal: this.frm_partida_total,
                pobservaciones: this.frm_partida_observaciones,
                pestatusid:  this.frm_partida_estatusid,
                pusuario: idusuario,
                ptiempoentrega:this.frm_partida_tiempoentrega
            });

            console.log(myJSON);

            if (err == 0) {
                this.cotizacionServicioService.createCotizacionServicio(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha guardado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible = false;
                        this.loadDataInfo();
                        //SE ACTUALIZAN LOS VALORES DE LA COTIZACION
                        this.popupVisible3 = false;
                        this.editar_Cotizacion_Enlace(this.frm_id,'N');
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                    //this.loadDataInfo();

                });
            } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                    message: this.message,
                }, this.type, 4000);

            }




            },
  
            editRegisterPartida() {

            

            let err = 0;
            let msgerror = '';

            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;

            if (this.frm_partida_subtotal == null) {
                err = 1;
                msgerror = 'Ingresar subtotal';
            }
            if (err == 0) {
                if (this.frm_partida_subtotal == 0) {
                    err = 1;
                    msgerror = 'Ingresar subtotal';
                }
            }
            if (err == 0) {
                if (this.frm_partida_subtotal == '0') {
                    err = 1;
                    msgerror = 'Ingresar subtotal';
                }
            }
            if (err == 0) {
                if (this.frm_partida_estatusid == null || this.frm_partida_estatusid == 'null' ) {
                    err = 1;
                    msgerror = 'Ingresar estatus';
                }
            }
            if (this.frm_partida_tiempoentrega == null) {
                this.frm_partida_tiempoentrega=0;
            }

            let myJSON = JSON.stringify({
                pid: this.frm_partida_id,
                pcotizacionid: this.frm_id,
                pservicioid: this.frm_partida_servicioid,
                psubtotal: this.frm_partida_subtotal,
                piva: this.frm_partida_iva,
                ptotal: this.frm_partida_total,
                pobservaciones: this.frm_partida_observaciones,
                pestatusid:  this.frm_partida_estatusid,
                pusuario: idusuario,
                ptiempoentrega:this.frm_partida_tiempoentrega
            });

            console.log(myJSON);

            if (err == 0) {
                this.cotizacionServicioService.updateCotizacionServicio(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible = false;
                        this.loadDataInfo();
                        //SE ACTUALIZAN LOS VALORES DE LA COTIZACION
                        this.frm_id = data.cotizacionid;
                        this.popupVisible4 = false;
                        this.editar_Cotizacion_Enlace(this.frm_id,'N');
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                    //this.loadDataInfo();

                });
            } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                    message: this.message,
                }, this.type, 4000);

            }




},

        saveRegister() {

         
            let err = 0;
            let msgerror = '';
            let vnumerocotizacion = '';
            let vgeneranumero = 1;
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;

            if (this.frm_tipocotizacion == null) {
                err = 1;
                msgerror = 'Ingresar tipo cotización a generar';
            }

            if (err == 0) {
                if (this.frm_tipocotizacion == 2) {
                    vgeneranumero=0;
                    if (this.frm_numerocotizacion == null || this.frm_numerocotizacion == '') {
                        err = 1;
                        msgerror = 'Ingresar número cotización';
                    }else{
                         vnumerocotizacion = this.frm_numerocotizacion;
                    }
                }
            }

            if (err == 0) {
                if (this.frm_solicitudid == null) {
                    err = 1;
                    msgerror = 'Ingresar solicitud';
                }
            }

            if (err == 0) {
                if (this.frm_fechavigenciacotizacion == null) {
                    err = 1;
                    msgerror = 'Ingresar fecha de vigencia';
                }
            }
            if (err == 0) {
                if (this.frm_compromisopagoid == null) {
                    err = 1;
                    msgerror = 'Ingresar compromiso pago';
                }
            }
            if (err == 0) {
                if (this.frm_clientecontacto == null) {
                    err = 1;
                    msgerror = 'Ingresar contacto de cliente';
                }
            }

            let myJSON = JSON.stringify({
                pnumerocotizacion: vnumerocotizacion,
                psolicitudid: this.frm_solicitudid,
                pfechavigenciacotizacion: moment(this.frm_fechavigenciacotizacion).format('YYYY-MM-DD'),
                pfechadeseadaservicio: this.frm_fechadeseadaservicio ? moment(this.frm_fechadeseadaservicio).format('YYYY-MM-DD') : null,
                ptiempoentrega: this.frm_tiempoentrega,
                pcompromisopagoid: this.frm_compromisopagoid,
                pcondicionpagoid: this.frm_condicionpagoid,
                pformapagoid: this.frm_formapagoid,
                pplazofacturacionid: this.frm_plazofacturacionid,
                pobservaciones: this.frm_observaciones,
                pusuarioregistroid: idusuario,

                psubtotal: 0,
                piva: 0,
                ptotal: 0,
                pgeneranumero:vgeneranumero,
                pclientecontactoid:this.frm_clientecontacto
            });



            if (err == 0) {
                this.cotizacionService.createCotizacion(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha guardado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible = false;
                        this.loadDataInfo();
                        //ENLAZAR A PANTALLAS DE EDICION
                       
                        this.frm_id= data.id;
                        this.frm_numerocotizacion= data.codigo;
                        this.frm_solicitudid= data.solicitudid;

                        this.editar_Cotizacion_Enlace(this.frm_id,'S');
                        
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                    //this.loadDataInfo();

                });
            } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                    message: this.message,
                }, this.type, 4000);

            }




        },

        saveConfiguracion() {

         
            let err = 0;
            let msgerror = '';

         

            if (this.frm_conf_dirigido == null) {
                err = 1;
                msgerror = 'Ingresar Dirigido';
            }


            if (err == 0) {
                if (this.frm_conf_titulo == null) {
                    err = 1;
                    msgerror = 'Ingresar Titulo';
                }
            }

            if (err == 0) {
                if (this.frm_conf_alcance == null) {
                    err = 1;
                    msgerror = 'Ingresar Alcance';
                }
            }
            if (err == 0) {
                if (this.frm_conf_responsabilidad == null) {
                    err = 1;
                    msgerror = 'Ingresar Responsabilidades';
                }
            }
            if (err == 0) {
                if (this.frm_conf_formapago == null) {
                    err = 1;
                    msgerror = 'Ingresar Forma Pago';
                }
            }


            let myJSON = JSON.stringify({
                pid: this.frm_conf_id,
                pdirigido: this.frm_conf_dirigido,
                ptitulo: this.frm_conf_titulo,
                palcance: this.frm_conf_alcance,
                presponsabilidad: this.frm_conf_responsabilidad,
                pformapago: this.frm_conf_formapago,
              
            });



            if (err == 0) {
                this.cotizacioncofiguracionformatoService.updateCotizacionConfiguracionFormato(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible5 = false;
                         
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                    //this.loadDataInfo();

                });
            } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                    message: this.message,
                }, this.type, 4000);

            }




            },
        editRegister() {


            let err = 0;
            let msgerror = '';

            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;

            if (this.frm_ed_solicitudid == null) {
                err = 1;
                msgerror = 'Ingresar solicitud';
            }
            /**
            if (err == 0) {
                if (this.frm_ed_numerocotizacion == null) {
                    err = 1;
                    msgerror = 'Ingresar numero cotizacion';
                }
            }


            if (err == 0) {
                if (this.frm_ed_subtotal == 0) {
                    err = 1;
                    msgerror = 'Ingresar subtotal';
                }
            }


            if (err == 0) {
                if (this.frm_ed_estatus == 0) {
                    err = 1;
                    msgerror = 'Ingresar estatus';
                }
            }
            **/

            if (err == 0) {
                if (this.frm_ed_numerocotizacion == null || this.frm_ed_numerocotizacion == '') {
                        err = 1;
                        msgerror = 'Ingresar número cotización';
                    }
            }
           


            if (err == 0) {
                if (this.frm_ed_fechavigenciacotizacion == null) {
                    err = 1;
                    msgerror = 'Ingresar de vigencia';
                }
            }
            if (err == 0) {
                if (this.frm_ed_compromisopagoid == null) {
                    err = 1;
                    msgerror = 'Ingresar compromiso pago';
                }
            }
            if (err == 0) {
                if (this.frm_ed_clientecontacto == null || this.frm_ed_clientecontacto == 0) {
                    err = 1;
                    msgerror = 'Ingresar contacto cliente';
                }
            }
            let myJSON = JSON.stringify({
                pid: this.frm_id,
                pnumerocotizacion: this.frm_ed_numerocotizacion,
                psolicitudid: this.frm_ed_solicitudid,
                pfechavigenciacotizacion: moment(this.frm_ed_fechavigenciacotizacion).format('YYYY-MM-DD'),
                pfechadeseadaservicio: this.frm_ed_fechadeseadaservicio ? moment(this.frm_ed_fechadeseadaservicio).format('YYYY-MM-DD') : null,
                ptiempoentrega: this.frm_ed_tiempoentrega,
                pcompromisopagoid: this.frm_ed_compromisopagoid,
                pcondicionpagoid: this.frm_ed_condicionpagoid,
                pformapagoid: this.frm_ed_formapagoid,
                pplazofacturacionid: this.frm_ed_plazofacturacionid,
                pobservaciones: this.frm_ed_observaciones,
                pusuarioregistroid: idusuario,
                psubtotal: 0,
                piva: 0,
                ptotal: 0,
                pestatus: 0,
                pclientecontactoid:this.frm_ed_clientecontacto
            });


            if (err == 0) {
                this.cotizacionService.updateCotizacion(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible2 = false;
                        this.loadDataInfo();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                    //this.loadDataInfo();

                });
            } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                    message: this.message,
                }, this.type, 4000);

            }


        },
        editar_Cotizacion_Enlace(id,abrepop)
        {
                this.dataSourcePartidasAsignadas=null;
                this.dataSourcePartidasCotizar=null;
                this.frm_ed_tiempoentrega = '';
                this.frm_ed_observaciones = '';
                this.frm_ed_solicitudid = '';
                this.frm_ed_numerocotizacion = '';
                this.frm_ed_fechavigenciacotizacion = '';
                this.frm_ed_clientecontacto = 0;
                this.frm_ed_fechadeseadaservicio = '';
                this.frm_ed_compromisopagoid = '';
                this.frm_ed_condicionpagoid = '';
                this.frm_ed_formapagoid = '';
                this.frm_ed_plazofacturacionid = '';
                this.frm_ed_subtotal = '';
                this.frm_ed_iva = '';
                this.frm_ed_total = '';
                this.frm_ed_estatus = '';
                this.frm_ed_servicio = '';

                this.frm_id = id; //id cotizacion

                //Estatus
                //this.estatusService.getEstatusSolicitud().then(data => this.dataSourceEstatus = data);

                //Partidas Disponibles
                this.obtienePartidas(this.frm_solicitudid);

                //Partidas Asignadas
                this.obtienePartidasAsignadas( this.frm_id);

                //Datos de la cotizacion
                let myJSON = JSON.stringify({
                    pid: this.frm_id,
                });

                this.cotizacionService.getCotizacionEdicion(myJSON).then(data => {

            
                    this.frm_ed_tiempoentrega = data[0].tiempoentrega;
                    this.frm_ed_observaciones = data[0].observaciones;
                    this.frm_ed_solicitudid = data[0].solicitudid;
                    this.frm_ed_numerocotizacion = data[0].numerocotizacion;
                    this.frm_ed_fechavigenciacotizacion = data[0].fechavigenciacotizacion;
                    this.frm_ed_fechadeseadaservicio = data[0].fechadeseadaservicio;
                    this.frm_ed_compromisopagoid = data[0].compromisopagoid;
                    this.frm_ed_condicionpagoid = data[0].condicionpagoid;
                    this.frm_ed_formapagoid = data[0].formapagoid;
                    this.frm_ed_plazofacturacionid = data[0].plazofacturacionid;
                    this.frm_ed_subtotal = data[0].subtotal;
                    this.frm_ed_iva = data[0].iva;
                    this.frm_ed_total = data[0].total;
                    this.frm_ed_estatus = data[0].estatusid;
                    this.frm_ed_servicio = data[0].servicio;
                    this.frm_ed_clientecontacto = data[0].clientecontactoid;
                    this.ClienteContactoService.getClienteContactoCotizacionporid(myJSON).then(data => {
                                this.dataSourceClienteContactoEditar = data;
                              
                                });

                });


                if(abrepop=='S'){
                    this.loading2 = true;
                    this.popupVisible2 = true;
                }
        },
        agregarPartizaCotizacion(e){
           
            this.frm_partida_partida =e.row.data.partida;
            this.frm_partida_servicio =e.row.data.servicio;
            this.frm_partida_servicioid =e.row.data.servicioid;
            this.frm_partida_estatusid = 1;
            this.frm_partida_id = '';
            this.frm_partida_subtotal ='';
            this.frm_partida_iva ='';
            this.frm_partida_total ='';
            this.frm_partida_observaciones ='';
            this.frm_partida_tiempoentrega = '';

            this.popupVisible3 = true;
        },
        editarPartizaCotizacion(e){
            this.frm_partida_id =e.row.data.id;
           this.frm_partida_partida =e.row.data.partida;
           this.frm_partida_servicio =e.row.data.servicio;
           this.frm_partida_servicioid =e.row.data.servicioid;
           this.frm_partida_estatusid = e.row.data.estatusid;
           this.frm_partida_tiempoentrega = e.row.data.tiempoentrega;
           this.frm_partida_subtotal =e.row.data.subtotal;
           this.frm_partida_iva =e.row.data.iva;
           this.frm_partida_total =e.row.data.total;
           this.frm_partida_observaciones =e.row.data.observaciones;
          

           this.popupVisible4 = true;
       },
        deleteProcedureServicePartida(e) {
            this.frm_id = e.data.solicitudid;
            let myJSON = JSON.stringify({
                pid: e.data.id
            });

           
            
                this.cotizacionServicioService.deleteCotizacionServicio(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha eliminado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible = false;
                        this.loadDataInfo();
                        //SE ACTUALIZAN LOS VALORES DE LA COTIZACION
                        this.popupVisible3 = false;
                        this.frm_id = data.cotizacionid;
                        this.editar_Cotizacion_Enlace(this.frm_id,'N');
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                  

                });
           



        },

        editarCotizacion(e) {
            this.frm_id= e.row.data.id;
            this.frm_solicitudid= e.row.data.solicitudid;

            this.editar_Cotizacion_Enlace(this.frm_id,'S');
           
            //this.dataSourceSolicitud = null;
            let myJSON = JSON.stringify({
                pid:  this.frm_solicitudid,
            });
            this.solicitudService.getSolicitudCotizar(myJSON).then(data => this.dataSourceSolicitud = data);
             /**
            // CARGAMOS ESTATUS
            let myJSON2 = JSON.stringify({
                pid: e.row.data.id,
            });
            this.estatusService.getEstatusParaEdicionCotizacion(myJSON2).then(data => this.dataSourceEstatus = data);

            this.frm_id = e.row.data.id;
            this.frm_ed_tiempoentrega = e.row.data.tiempoentrega;
            this.frm_ed_observaciones = e.row.data.observaciones;
            this.frm_ed_solicitudid = e.row.data.solicitudid;
            this.frm_ed_numerocotizacion = e.row.data.numerocotizacion;
            this.frm_ed_fechavigenciacotizacion = e.row.data.fechavigenciacotizacion;
            this.frm_ed_fechadeseadaservicio = e.row.data.fechadeseadaservicio;
            this.frm_ed_compromisopagoid = e.row.data.compromisopagoid;
            this.frm_ed_condicionpagoid = e.row.data.condicionpagoid;
            this.frm_ed_formapagoid = e.row.data.formapagoid;
            this.frm_ed_plazofacturacionid = e.row.data.plazofacturacionid;
            this.frm_ed_subtotal = e.row.data.subtotal;
            this.frm_ed_iva = e.row.data.iva;
            this.frm_ed_total = e.row.data.total;
            this.frm_ed_estatus = e.row.data.estatusid;
            this.frm_ed_servicio = e.row.data.servicio;
            this.loading2 = true;
            this.popupVisible2 = true;
            **/


        },
        exportarPDF(e) {
            const doc = new jsPDF('portrait', 'mm', 'a4');
            


            this.dataPDF_fechaEmision = e.row.data.fecharegistro;
            this.dataPDF_lugarFecha = e.row.data.lugarfecha;
            this.dataPDF_nombre = e.row.data.cliente;
            this.dataPDF_RFC = e.row.data.rfc;
            this.dataPDF_razonSocial = e.row.data.razonsocial;
            this.dataPDF_fechaVigencia = e.row.data.fechavigenciacotizacion;
            this.dataPDF_observaciones = e.row.data.observaciones;
            this.dataPDF_codigocotizacion = e.row.data.numerocotizacion;
            this.dataPDF_subtotal = e.row.data.subtotal;
            this.dataPDF_iva = e.row.data.iva;
            this.dataPDF_total = e.row.data.total;

 
            this.dataPDF_dirigido= e.row.data.dirigido;
            this.dataPDF_titulo= e.row.data.titulo;
            this.dataPDF_alcance= e.row.data.alcance;
            this.dataPDF_responsabilidad= e.row.data.responsabilidad;
            this.dataPDF_formapago= e.row.data.formapago;

            //////////////////
            
            let servicios = e.row.data.serviciosjson;//'[{"servicio":"Servicio1", "cantidad": 1, "subtotal":1.00, "iva":1.00, "total":1.00}]';
            //let terminos = e.row.data.terminosjson;//'[{"id": 1, "termino":"Termino 1"}, {"id": 2, "termino":"Termino 2"}]';

            
            //PALABRAS NEGRITAS
            //let palabras = e.row.data.palabrasclavejson;
            //let palabrasJSON = JSON.parse(palabras);
            
            
            //console.log(palabrasJSON);
            let serviciosJSON = JSON.parse(servicios);
            //let terminosJSON = JSON.parse(terminos);

            //Centrar el texto

            var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            var posicion = 50;
            var textoHeigth = 0;
            //var textoWidth = 0;
            //var renglonesPorPagina = pageHeight - 50;
            //doc.setFontSize(15);  
            //doc.text("TITULO 1", 130, 30, {align: "left"});

           

            //ZONA DE PORTADA
            
            doc.setFontSize(38);
            doc.setTextColor(36,64,97);
            doc.setFont('helvetica','bold');
            doc.text("Cotización", pageWidth / 2, 90, { align: "center" });
            doc.text("LUHOTT-MERAKI", pageWidth / 2, 110, { align: "center" });
            doc.text("Asesores Ambientales", pageWidth / 2, 130, { align: "center" });
            var img = new Image();
            img.src = require("../assets/logo.png");
            doc.addImage(img, "PNG", 8, 8, 90, 40);
            doc.setFontSize(16);
            doc.setTextColor(0,0,0);
            
            doc.text(this.dataPDF_razonSocial, pageWidth / 2, 170, { align: "center" });

            doc.setFontSize(12);
            
            doc.text("No.", 148, 235, { align: "right" });
            doc.text("Fecha: ", 156, 242, { align: "right" });
            
            doc.setFont('helvetica','normal');
            doc.text(this.dataPDF_codigocotizacion, 190, 235, { align: "right" });
            doc.text(this.dataPDF_fechaEmision, 185, 242, { align: "right" });
            
            doc.rect(140, 230, 52, 13);
            doc.line(140, 237, 192, 237);
            doc.line(158, 230, 158, 243);
            doc.setDrawColor(0, 0, 205);
            doc.rect(10, 10, 190, 280);
            doc.rect(25, 160, 160, 25);

            img.src = require("../assets/portadaCotizacion.jpg");
            doc.addImage(img, "JPEG", 15, 250, 180, 35);

            doc.addPage();


            doc.setFontSize(9);


            //CANDIDATOS CONFIGURACION GENERAL
            doc.setFont('helvetica','bold');
            doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", 200, 15, { align: "right" });
            doc.setFont('helvetica','normal');
            doc.text("Fray Luis de León No. 1730 Col. Jardín Español" , 200, 20, { align: "right" });
            doc.text("Monterrey, Nuevo León C.P. 64820", 200, 25, { align: "right" });


            doc.setFont('helvetica','bold');
            doc.text(this.dataPDF_dirigido, 15, posicion, { align: "left" });
            //doc.text(this.dataPDF_nombre, 15, 55, { align: "left" });
            doc.setFont('helvetica','normal');

            posicion = posicion + 15;
            

            //Titulo
            let splitTitle = doc.splitTextToSize(this.dataPDF_titulo, 180);
            //doc.text(splitTitle, 15, posicion, { align: "left", maxWidth: 180 });
            //posicion = this.justificacionTextoPrueba(doc, splitTitle, posicion, palabrasJSON) + 10;
            posicion = this.justificacionTexto(doc, splitTitle, posicion) + 10;
            //Alcance
            doc.setFont('helvetica','bold');
            doc.text("1. ALCANCES", 15, posicion, { align: "left" });
            doc.setFont('helvetica','normal');

            posicion = posicion + 10;
        
            let splitTitle2 = doc.splitTextToSize(this.dataPDF_alcance, 180);
            posicion = this.justificacionTexto(doc, splitTitle2, posicion) + 10;
            //doc.text(splitTitle2, 15, 105, { align: "justify", maxWidth: 180 });
            
            /**
            doc.setFontSize(15);
            doc.text("LUHOTT - COTIZACIÓN SERVICIOS", pageWidth / 2, 53, { align: "center" });

            doc.setFontSize(10);
            doc.text("Numero Cotización: " + this.dataPDF_codigocotizacion, 15, 65, { align: "left" });
            doc.text("Nombre: " + this.dataPDF_nombre, 15, 75, { align: "left" });
            doc.text("RFC: " + this.dataPDF_RFC, 100, 65, { align: "left" });
            let splitTitle = doc.splitTextToSize(this.dataPDF_razonSocial, 75);
            doc.text(123, 75, splitTitle);
            doc.text("Razon Social: ", 100, 75, { align: "left" });
            doc.text("Fecha Vigencia: " + this.dataPDF_fechaVigencia, 15, 85, { align: "left" });
            **/
            //console.log(this.dataPDF_alcance.length);
            //console.log(this.dataPDF_dirigido.length); 
            //console.log(posicion);

            if( posicion > 220){
                doc.addPage();
                doc.setFont('helvetica','bold');
                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", 200, 15, { align: "right" });
                doc.setFont('helvetica','normal');
                doc.text("Fray Luis de León No. 1730 Col. Jardín Español" , 200, 20, { align: "right" });
                doc.text("Monterrey, Nuevo León C.P. 64820", 200, 25, { align: "right" });

                posicion = 50;
            }

            var columns_servicios = [
                { title: "Cantidad", dataKey: "cantidad" },
                { title: "Descripción", dataKey: "servicio" },
                { title: "Tiempo Estimado", dataKey: "dias" },
                { title: "Costo del servicio (M.N.)", dataKey: "subtotal" },

            ];

            var datos_totales = [
                ["SubTotal", "IVA", "Total"],
                ["$ " + this.dataPDF_subtotal, "$ " + this.dataPDF_iva, "$ " + this.dataPDF_total]
            ];



            doc.setFont('helvetica','bold');
            doc.text("2. COSTOS", 15, posicion, { align: "left" });
            doc.setFont('helvetica','normal');
            posicion = posicion + 10;

            var autoTablePos = 0;
            var tamanioFilas = 0;
            var checkEspacio = ((pageHeight - posicion) - 10);
            var checkTamanio = (serviciosJSON.length + 1) * 8.9;
            
            //console.log(serviciosJSON.length);
            if(checkTamanio > checkEspacio){
                var arregloDatos = [];
                var datosRestantes = serviciosJSON.length;
                var indiceDatos = 0;

                do {
                    tamanioFilas = Math.floor(checkEspacio / 8.9);
                    
                    if(datosRestantes < tamanioFilas){
                        arregloDatos = serviciosJSON.slice(indiceDatos);
                    } else {
                        arregloDatos = serviciosJSON.slice(indiceDatos, indiceDatos + tamanioFilas);
                        indiceDatos = indiceDatos + tamanioFilas;
                    }
                    //console.log(arregloDatos.length);
                    doc.autoTable(columns_servicios, arregloDatos, {
                        startY: posicion,
                        finalY: 140 + 35,
                        theme: 'plain',
                        rowPageBreak: 'avoid',
                        pageBreak: 'avoid',
                        halign: 'center',
                        styles: {
                        lineWidth: 0.5,
                        lineColor: 0,
                        valign: 'bottom',
                        },
                        headStyles: {
                        textColor: [0, 0, 0],
                        halign: 'center',
                        },
                    });

                    posicion = doc.autoTable.previous.finalY;
                    //console.log(posicion);
                    if(posicion > 250){
                        doc.addPage();
                        doc.setFont('helvetica','bold');
                        doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", 200, 15, { align: "right" });
                        doc.setFont('helvetica','normal');
                        doc.text("Fray Luis de León No. 1730 Col. Jardín Español" , 200, 20, { align: "right" });
                        doc.text("Monterrey, Nuevo León C.P. 64820", 200, 25, { align: "right" });

                        posicion = 50;
                    }
                    
                    datosRestantes = datosRestantes - tamanioFilas;
                    checkEspacio = (pageHeight - posicion) - 10;
                    

                } while (datosRestantes > 0);
                
                //console.log(doc.autoTable.previous.finalY);
                if(posicion == 50){
                    autoTablePos = posicion;
                } else {
                    autoTablePos = doc.autoTable.previous.finalY;
                }
                

            } else {

                doc.autoTable(columns_servicios, serviciosJSON, {
                    startY: posicion,
                    finalY: 140 + 35,
                    theme: 'plain',
                    rowPageBreak: 'avoid',
                    pageBreak: 'avoid',
                    halign: 'center',
                    styles: {
                    lineWidth: 0.5,
                    lineColor: 0,
                    valign: 'bottom',
                    },
                    headStyles: {
                    textColor: [0, 0, 0],
                    halign: 'center',
                    },
                });

                autoTablePos = doc.autoTable.previous.finalY;
            }

            if(autoTablePos > 258){

                doc.addPage();
                doc.setFont('helvetica','bold');
                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", 200, 15, { align: "right" });
                doc.setFont('helvetica','normal');
                doc.text("Fray Luis de León No. 1730 Col. Jardín Español" , 200, 20, { align: "right" });
                doc.text("Monterrey, Nuevo León C.P. 64820", 200, 25, { align: "right" });

                autoTablePos = 50;
                
            } else {
                autoTablePos = autoTablePos + 5;
            }

            doc.autoTable({
                head: datos_totales.splice(0, 1),
                body: datos_totales, 
                startY: autoTablePos,
                finalY: autoTablePos + 35,
                rowPageBreak: 'avoid',
                pageBreak: 'auto',
                halign: 'center',
                styles: {
                    lineWidth: 0.5,
                    lineColor: 0,
                    valign: 'bottom',
                    fillColor: (255, 255, 255),
                },
                headStyles: {
                    textColor: [0, 0, 0],
                    halign: 'center',
                },
                bodyStyles: {
                    textColor: [0, 0, 0],
                    fillColor: (255, 255, 255),
                },
            });

            autoTablePos = doc.autoTable.previous.finalY;

            if(autoTablePos > 250){

                doc.addPage();
                doc.setFont('helvetica','bold');
                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", 200, 15, { align: "right" });
                doc.setFont('helvetica','normal');
                doc.text("Fray Luis de León No. 1730 Col. Jardín Español" , 200, 20, { align: "right" });
                doc.text("Monterrey, Nuevo León C.P. 64820", 200, 25, { align: "right" });

                posicion = 50;
            } else {
                posicion = autoTablePos + 10;
            }

            //Resposanbilidades
            doc.setFont('helvetica','bold');
            doc.text("3. RESPONSABILIDADES", 15, posicion, { align: "left" });
            doc.setFont('helvetica','normal');
            let splitTitle3 = doc.splitTextToSize(this.dataPDF_responsabilidad, 170);
            let arregloSinTabulacionesRES = splitTitle3.map(cadena => cadena.replace(/\t/g, ' '));
            //doc.text(arregloSinTabulacionesRES, 15, posicion + 10, { align: "justify", maxWidth: 180 });

            textoHeigth = doc.getTextDimensions(splitTitle3).h;
            
            
            posicion = posicion + 10;

            
            var espacioRenglonesRES =  (pageHeight - posicion) - 20;
            var tamanioRenglonesRES = 0;
            var indice = 0;
            var ultimoRenglon = 0;
            //var checkPosicion = posicion + textoHeigth;
            //console.log(textoHeigth);
            //console.log(espacioRenglonesRES);
            if(textoHeigth > espacioRenglonesRES){
                
                var arregloTextoRES = [];
                var restantesRES = arregloSinTabulacionesRES.length;
               
                
                do
                {   
                    tamanioRenglonesRES = Math.floor(espacioRenglonesRES / 3.14);
                    //console.log(restantesRES);
                    //console.log(espacioRenglonesRES);
                    if(tamanioRenglonesRES == 0){
                        tamanioRenglonesRES = 1;
                    }

                    if(restantesRES <= tamanioRenglonesRES){
                        arregloTextoRES = arregloSinTabulacionesRES.slice(indice);
                    } else {
                        arregloTextoRES = arregloSinTabulacionesRES.slice(indice, indice + tamanioRenglonesRES);
                        
                        indice = indice + tamanioRenglonesRES;
                        
                        ultimoRenglon = doc.getTextDimensions(arregloTextoRES[arregloTextoRES.length - 1]).w;
                        
                        if(arregloTextoRES[arregloTextoRES.length - 1].includes("3.") && ultimoRenglon > 145){
                            indice = indice - 1;
                            arregloTextoRES.pop();
                        }
                    }

                    posicion = this.justificacionTexto(doc, arregloTextoRES, posicion);
                    

                    if(posicion > 250){
                        doc.addPage();
                        doc.setFont('helvetica','bold');
                        doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", 200, 15, { align: "right" });
                        doc.setFont('helvetica','normal');
                        doc.text("Fray Luis de León No. 1730 Col. Jardín Español" , 200, 20, { align: "right" });
                        doc.text("Monterrey, Nuevo León C.P. 64820", 200, 25, { align: "right" });

                        posicion = 50;
                        
                    }

                    //indice = indice + espacioRenglonesRES;
                    restantesRES = restantesRES - tamanioRenglonesRES;
                    espacioRenglonesRES = (pageHeight - posicion) - 20;

                } while (restantesRES >= 0);
                
                if( posicion > 200){
                    doc.addPage();
                    doc.setFont('helvetica','bold');
                    doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", 200, 15, { align: "right" });
                    doc.setFont('helvetica','normal');
                    doc.text("Fray Luis de León No. 1730 Col. Jardín Español" , 200, 20, { align: "right" });
                    doc.text("Monterrey, Nuevo León C.P. 64820", 200, 25, { align: "right" });

                    posicion = 50;
                }
                    
            } else {

                posicion = this.justificacionTexto(doc, arregloSinTabulacionesRES, posicion);
                if( posicion > (pageHeight - 30)){
                    doc.addPage();
                    doc.setFont('helvetica','bold');
                    doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", 200, 15, { align: "right" });
                    doc.setFont('helvetica','normal');
                    doc.text("Fray Luis de León No. 1730 Col. Jardín Español" , 200, 20, { align: "right" });
                    doc.text("Monterrey, Nuevo León C.P. 64820", 200, 25, { align: "right" });

                    posicion = 50;
                } else {
                    
                    posicion = posicion + 4;
                }

                
            }    
            posicion = posicion + 10;
            //Forma de Pago
            doc.setFontSize(9);
            doc.setFont('helvetica','bold');
            doc.text("4.	FORMA DE PAGO", 15, posicion, { align: "left" });
            doc.setFont('helvetica','normal');
            
            let splitTitle4 = doc.splitTextToSize(this.dataPDF_formapago, 180);
            let arregloSinTabulacionesFOR = splitTitle4.map(cadena => cadena.replace(/\t/g, ' '));

            textoHeigth = doc.getTextDimensions(splitTitle4).h;
        
            posicion = posicion + 10;
            
            var espacioRenglonesFOR =  (pageHeight - posicion) - 20;
            var tamanioRenglonesFOR = 0;
            
            if(textoHeigth > espacioRenglonesFOR){
                indice = 0;
                var arregloTextoFOR = [];
                var restantesFOR = arregloSinTabulacionesFOR.length;
                ultimoRenglon = 0;
                

                do
                {   
                    tamanioRenglonesFOR = Math.floor(espacioRenglonesFOR / 3.14);
                    if(tamanioRenglonesFOR == 0){
                        tamanioRenglonesFOR = 1;
                    }
                    if(restantesFOR <= tamanioRenglonesFOR){
                        arregloTextoFOR = arregloSinTabulacionesFOR.slice(indice);
                    } else {
                        arregloTextoFOR = arregloSinTabulacionesFOR.slice(indice, indice + tamanioRenglonesFOR);
                        //console.log(arregloSinTabulacionesFOR);
                        indice = indice + tamanioRenglonesFOR;
                        ultimoRenglon = doc.getTextDimensions(arregloTextoFOR[arregloTextoFOR.length - 1]).w;

                        if(arregloTextoFOR[arregloTextoFOR.length - 1].includes("4.") && ultimoRenglon > 145){
                            indice = indice - 1;
                            arregloTextoFOR.pop();
                        }
                    }

                    posicion = this.justificacionTexto(doc, arregloTextoFOR, posicion);

                    if(posicion > 250){
                        doc.addPage();
                        doc.setFont('helvetica','bold');
                        doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", 200, 15, { align: "right" });
                        doc.setFont('helvetica','normal');
                        doc.text("Fray Luis de León No. 1730 Col. Jardín Español" , 200, 20, { align: "right" });
                        doc.text("Monterrey, Nuevo León C.P. 64820", 200, 25, { align: "right" });

                        posicion = 50;
                        
                    }

                    //indice = indice + espacioRenglonesFOR;
                    restantesFOR = restantesFOR - tamanioRenglonesFOR;
                    espacioRenglonesFOR = (pageHeight - posicion) - 20;

                } while (restantesFOR >= 0);

                posicion = posicion + 10;

                if( posicion > 200){
                    doc.addPage();
                    doc.setFont('helvetica','bold');
                    doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", 200, 15, { align: "right" });
                    doc.setFont('helvetica','normal');
                    doc.text("Fray Luis de León No. 1730 Col. Jardín Español" , 200, 20, { align: "right" });
                    doc.text("Monterrey, Nuevo León C.P. 64820", 200, 25, { align: "right" });

                    posicion = 50;
                }
                    
            } else {

                posicion = this.justificacionTexto(doc, arregloSinTabulacionesFOR, posicion);
                if( posicion > 235){
                    doc.addPage();
                    doc.setFont('helvetica','bold');
                    doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", 200, 15, { align: "right" });
                    doc.setFont('helvetica','normal');
                    doc.text("Fray Luis de León No. 1730 Col. Jardín Español" , 200, 20, { align: "right" });
                    doc.text("Monterrey, Nuevo León C.P. 64820", 200, 25, { align: "right" });

                    posicion = 50;
                } else {
                    
                    posicion = posicion + 5;
                }
            }
            
            img.src = require("../assets/firmaCotizacion.png");
            doc.addImage(img, "PNG", 25, posicion, 0, 0);





            /*
            doc.text("Atentamente:", 100, 220, { align: "left" });
            doc.text("QBP JOSE LUIS TAMEZ GARZA", 80, 250, { align: "left" });
            doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", 68, 255, { align: "left" });

            /**
            var columns_terminos = [
                { title: "Términos y Condiciones", dataKey: "termino" },
            ];
            doc.autoTable(columns_terminos, terminosJSON, {
                startY: 130,
                finalY: 130 + 35,
                theme: 'grid',
                rowPageBreak: 'avoid',
                pageBreak: 'auto',
                headStyles: { halign: 'center' },
            });

            doc.setFontSize(15);
            **/
            //doc.text("Observaciones:" + this.dataPDF_observaciones, 20, 250, { align: "left" });

            

            doc.autoPrint();
            window.open(
                URL.createObjectURL(doc.output("blob")),
                "_blank",
                "height=650,width=500,scrollbars=yes,location=yes"
            );
            setTimeout(() => {
                window.URL.revokeObjectURL(doc.output("bloburl"));
            }, 100);


        },
        justificacionTexto(doc, texto, posicion){
            var renglonWidth = 0;
            /*
            if(texto.length > 10) {
                var indice = 0;
                var tamanio = Math.abs(texto.length / 5);

                if((texto.length % 5) > 0){
                    tamanio = tamanio + 1;
                }

                var renglones = [];
                var parrafo = [];
                var tamanioR = 0;
                
                for( var i = 1; i <= tamanio; i++){
                    if((indice + 5) <= texto.length){
                        renglones = texto.slice(indice, indice + 5);
                        indice = indice + 5;
                    } else {
                        renglones = texto.slice(indice);
                    }
                    
                    renglones.forEach(
                    renglon => {
                        
                        renglonWidth = doc.getTextDimensions(renglon).w;
                       
                        if ( renglonWidth > 145) {
                            parrafo.push(renglon);
                            if(tamanioR === (texto.length - 1)){
                                parrafo.push(" ");
                                doc.text(parrafo, 15, posicion, { align: "justify", maxWidth: 180 });
                                posicion = posicion + doc.getTextDimensions(parrafo).h;
                            }

                        } else {
                            if(parrafo.length > 0){
                                parrafo.push(renglon);
                                doc.text(parrafo, 15, posicion, { align: "justify", maxWidth: 180 });
                                posicion = posicion + doc.getTextDimensions(parrafo).h;
                                parrafo = [];
                            } else {
                                doc.text(renglon, 15, posicion, { align: "left"});
                                posicion = posicion + 3.5;
                            } 
                        }
                        tamanioR++;

                    });
                    
                }

            } else {
                var parrafoC = [];
                var tamanioC = 0;
                texto.forEach(
                renglon => {

                    renglonWidth = doc.getTextDimensions(renglon).w;
                    //console.log(renglonWidth);

                    if ( renglonWidth > 145) {
                        parrafoC.push(renglon);

                        if(tamanioC === (texto.length - 1)){
                            parrafoC.push(" ");
                            doc.text(parrafoC, 15, posicion, { align: "justify", maxWidth: 180 });
                            posicion = posicion + doc.getTextDimensions(parrafoC).h;
                        }
                        //doc.text(renglon, 15, posicion, { align: "justify", maxWidth: 180 });
                    } else {
                        if(parrafoC.length > 0){

                            parrafoC.push(renglon);
                            doc.text(parrafoC, 15, posicion, { align: "justify", maxWidth: 180 });
                            posicion = posicion + doc.getTextDimensions(parrafoC).h;
                            parrafoC = [];

                        } else {

                            doc.text(renglon, 15, posicion, { align: "left"});
                            posicion = posicion + 3.4;
                        } 
                    }
                    tamanioC++;
                });
            }

            */
            var parrafoC = [];
                var tamanioC = 0;
                texto.forEach(
                renglon => {

                    renglonWidth = doc.getTextDimensions(renglon).w;
                    //console.log(renglonWidth);

                    if ( renglonWidth > 145) {
                        parrafoC.push(renglon);

                        if(tamanioC === (texto.length - 1)){
                            parrafoC.push(" ");
                            doc.text(parrafoC, 15, posicion, { align: "justify", maxWidth: 180 });
                            posicion = posicion + doc.getTextDimensions(parrafoC).h;
                        }
                        //doc.text(renglon, 15, posicion, { align: "justify", maxWidth: 180 });
                    } else {
                        if(parrafoC.length > 0){

                            parrafoC.push(renglon);
                            doc.text(parrafoC, 15, posicion, { align: "justify", maxWidth: 180 });
                            posicion = posicion + doc.getTextDimensions(parrafoC).h;
                            parrafoC = [];

                        } else {

                            doc.text(renglon, 15, posicion, { align: "left"});
                            posicion = posicion + 3.4;
                        } 
                    }
                    tamanioC++;
                });
            
            
            return posicion;
        },
        obtenerPalabrasTexto(renglon, palabrasJSON){
            var palabrasEncontradas = [];
            var indice = 0;
            var tamanio = renglon.length;
                     
            palabrasJSON.forEach(palabra =>{
                var palabraDesglozada = palabra.descripcion.split(" ").filter(Boolean);
                var checkTamanio = 0;
                //var checkPalabra = 0;
                indice = renglon.indexOf(palabraDesglozada[0]);


                while (indice !== -1) {
                    //indices.push(indice);
                    checkTamanio = tamanio - (indice + palabra.descripcion.length);
                    if(checkTamanio > 0){
                        palabrasEncontradas.push({ tam: palabra.descripcion.length, indice: indice, faltante: 0, texto: palabra.descripcion});

                        indice = renglon.indexOf(palabraDesglozada[0], indice + 1);
                    } else {
                        checkTamanio = tamanio - indice;

                        palabrasEncontradas.push({ tam: checkTamanio, indice: indice, faltante: (palabra.descripcion.length - checkTamanio), texto: palabra.descripcion.substring(0, checkTamanio)});
                        indice = -1;
                    }
                     // Continuar buscando después del último índice encontrado
                }
            });

            if( palabrasEncontradas.length > 0){
                palabrasEncontradas.sort((a, b) => a.indice - b.indice);
            }

            return palabrasEncontradas;
        },

        reemplazarConEspacios(doc, texto, palabrasAReemplazar) {
            palabrasAReemplazar.forEach(palabra => {
                let regex = new RegExp(palabra, 'g'); // 'g' para reemplazar todas las ocurrencias
                let espacios = this.ajustarEspacios(doc, palabra);
                texto = texto.replace(regex, espacios);
            });
            return texto;
        },

        reemplazarConEspacios2(doc, texto, palabrasAReemplazar) {
            palabrasAReemplazar.forEach(palabra => {
                let regex = new RegExp(palabra, 'g'); // 'g' para reemplazar todas las ocurrencias
                let espacios = " ".repeat(palabra.length);
                texto = texto.replace(regex, espacios);
            });
            return texto;
        },

        ajustarEspacios(doc, texto) {
            let resultado = "";
            var textWidth = doc.getTextDimensions(texto).w;
            var espacioWidth = doc.getTextDimensions(' ').w;

            if (espacioWidth > textWidth) {
                return ' ';
            }
            var numEspacios = Math.max(Math.ceil(textWidth/espacioWidth), 0);

            resultado = ' '.repeat(numEspacios);

            return resultado;
        },

        justificacionTextoPrueba(doc, texto, posicion, palabras){
            var renglonWidth = 0;
            var palabrasEncontradas = [];
            var faltante = 0;
            var indiceRenglon = 0;
            var parrafoC = [];
            var textoNegritas = "";
            var textoSinNegritas = "";
            var textoDesplegado = "";
            //var totalWidth = 0;
            var textoReferencia = "";
            var textoInicioRenglon = "";
            //var tamaniosTextoreferencia = [];
            var poisicionRenglon = posicion;
            var textoConNegritas = [];
            var palabrasNegritas = [];

            //var lugarRenglon = 15;
                
            var tamanioC = 0;
            texto.forEach(
            renglon => {
                textoNegritas = "";
                textoSinNegritas = "";
                textoDesplegado = "";
                textoInicioRenglon = "";
                indiceRenglon = 0;
                palabrasNegritas = [];
                textoConNegritas = [];

                palabrasEncontradas = this.obtenerPalabrasTexto(renglon, palabras);
                
                textoSinNegritas = renglon;

                if(faltante != 0){
                    textoDesplegado = renglon.substring(0, faltante);
                    textoInicioRenglon = textoDesplegado;
                    
                    palabrasNegritas.push(textoDesplegado);
                    textoNegritas = "" + textoDesplegado;
                    indiceRenglon = indiceRenglon + faltante;

                    if(palabrasEncontradas.length == 0){
                        textoDesplegado = " ".repeat(renglon.length - textoNegritas.length);
                        textoNegritas = textoNegritas + textoDesplegado;
                    }
                }

                if(palabrasEncontradas.length > 0){
                    palabrasEncontradas.forEach(palabra =>{
                       
                        var rep = palabra.indice - indiceRenglon; 
                        var espaciosTextoReferencia = "";
                        
                        textoReferencia = renglon.substring(indiceRenglon, palabra.indice);
                        
                        indiceRenglon = indiceRenglon + rep;
                        if(rep > 0){
                            espaciosTextoReferencia = this.ajustarEspacios(doc, textoReferencia);
                        } else {
                            espaciosTextoReferencia = "";
                            textoInicioRenglon = palabra.texto;
                        }
            
                        textoNegritas = textoNegritas + espaciosTextoReferencia;
                        
                        textoDesplegado = renglon.substring(palabra.indice, palabra.indice + palabra.tam);
                        
                        textoNegritas = textoNegritas + textoDesplegado;

                        palabrasNegritas.push(palabra.texto);

                        indiceRenglon = indiceRenglon + palabra.tam;
                        
                        faltante = (palabra.faltante != 0) ? palabra.faltante : 0;
                       
                        
                    });

                    if(indiceRenglon < renglon.length){
                        //console.log("I " + renglon.substring(indiceRenglon, renglon.length));
                        //textoDesplegado = " ".repeat(renglon.length - indiceRenglon);
                        textoDesplegado = this.ajustarEspacios(doc, renglon.substring(indiceRenglon, renglon.length));
                        textoNegritas = textoNegritas + textoDesplegado;
                        
                    }

                }

                
                
                renglonWidth = doc.getTextDimensions(renglon).w;

                if((textoNegritas != "") && (palabrasNegritas.length > 0)){

                    if(textoInicioRenglon.length > 0){
                        
                        //var t = textoSinNegritas.substring(textoInicioRenglon.length, textoSinNegritas.length);
                        //textoSinNegritas = this.ajustarEspacios(doc,textoInicioRenglon);
                        //textoSinNegritas = textoSinNegritas + t;
                        //console.log("Length: " + textoInicioRenglon.length);
                        //console.log("Width: " + doc.getTextDimensions(textoInicioRenglon).w);
                        textoSinNegritas = this.reemplazarConEspacios2(doc, textoSinNegritas, palabrasNegritas);

                    } else {
                        textoSinNegritas = this.reemplazarConEspacios(doc, textoSinNegritas, palabrasNegritas);
                    }

                    //textoNegritas = this.ajustarEspacios(doc, textoNegritas, espacioletra);
                    //console.log("TamN: " + doc.getTextDimensions(textoNegritas).w);
                    //console.log("Tam: " + doc.getTextDimensions(textoSinNegritas).w);
                    doc.setFont('helvetica','bold');
                    textoConNegritas.push(textoNegritas);
                    if(renglonWidth > 145){
                        textoConNegritas.push(" ");
                    }
                    
                    doc.text(textoConNegritas, 15, poisicionRenglon, { align: "justify", maxWidth: 180 });
                    renglon = textoSinNegritas;
                }
                
                poisicionRenglon = poisicionRenglon + 3.6;
                
                //renglonWidth = doc.getTextDimensions(renglon).w;
                //console.log(renglonWidth);

                doc.setFont('helvetica','normal');

                if ( renglonWidth > 145) {
                    parrafoC.push(renglon);

                    if(tamanioC === (texto.length - 1)){
                        parrafoC.push(" ");
                        doc.text(parrafoC, 15, posicion, { align: "justify", maxWidth: 180 });
                        posicion = posicion + doc.getTextDimensions(parrafoC).h;
                        poisicionRenglon = posicion;
                    }
                    //doc.text(renglon, 15, posicion, { align: "justify", maxWidth: 180 });
                } else {
                    if(parrafoC.length > 0){

                        parrafoC.push(renglon);
                        doc.text(parrafoC, 15, posicion, { align: "justify", maxWidth: 180 });
                        posicion = posicion + doc.getTextDimensions(parrafoC).h;
                        poisicionRenglon = posicion;
                        parrafoC = [];

                    } else {

                        doc.text(renglon, 15, posicion, { align: "left"});
                        posicion = posicion + 3.4;
                        poisicionRenglon = posicion;
                    } 
                }
                tamanioC++;
            });
            
            return posicion;
        },
        priceColumn_customizeText(cellInfo) {
            return '$' + cellInfo.value;
        },
        loadDataInfo() {
            /**
            let myJSON = JSON.stringify({
                pid: 0,
            });
            this.solicitudService.getSolicitudCotizar(myJSON).then(data => this.dataSourceSolicitud = data);
 */
            this.cotizacionService.getCotizacion().then(data => this.dataSourceCotizacion = data);
            this.condicionService.getCondicionPago().then(data => this.dataSourceCondicion = data);
            this.compromisoService.getCompromisoPago().then(data => this.dataSourceCompromiso = data);
            this.formaService.getFormaPago().then(data => this.dataSourceForma = data);
            this.plazoService.getPlazoFacturacion().then(data => this.dataSourcePlazo = data);
            //this.estatusService.getEstatus().then(data => this.dataSourceEstatus = data);

        },


        onInitNewRow(e) {
            e.data.bactivo = true;
        },

        onInitNewRowPartidas(e) {
            e.data.aprobar = true;
        },

        
    },
};
