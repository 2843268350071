import axios from 'axios';

export default class GrupoEtapaDetalleService {

    getGrupoEtapaDetalle(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/grupoetapadetalle`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateGrupoEtapaDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupoetapadetalleedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createGrupoEtapaDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupoetapadetallealta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteGrupoEtapaDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupoetapadetallebaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}