

import {
  DxVectorMap,   
  DxFont,
  DxLayer,
  DxLabel,
  DxTooltip,
} from 'devextreme-vue/vector-map';

import { roomsData, buildingData, equipmentData } from './../data/data.js';

export default {
  components: {
    DxVectorMap,
    DxLayer,
    DxLabel,
    DxTooltip,
    DxFont,
  },
  data() {
    return {
      roomsData,
      buildingData,
      equipmentData,
      projection: {
        to: ([l, lt]) => [l / 100, lt / 100],
        from: ([x, y]) => [x * 100, y * 100],
      },
    };
  },

  methods: {
    customizeTooltip(info) {
      if (info.layer.name === 'rooms') {
        return { text: `Square: ${info.attribute('square')} ft&#178` };
      }
      return null;
    },
  },
};
