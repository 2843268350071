import axios from 'axios';

export default class TipoServicioService {

    getTipoServicio() {            
        const url = process.env.VUE_APP_APIURL + `/api/tiposervicio`;    
        return axios.get(url).then(res=>res.data);
    }

    updateTipoServicio(info){
        const url = process.env.VUE_APP_APIURL + `/api/tiposervicioedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createTipoServicio(info){
        const url = process.env.VUE_APP_APIURL + `/api/tiposervicioalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteTipoServicio(info){
        const url = process.env.VUE_APP_APIURL + `/api/tiposerviciobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}