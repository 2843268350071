
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxLookup,
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,
    DxFormItem,
    
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
 import 'devextreme-vue/text-area';
 
import Servicioervice from '../services/servicio-service';
import SolicitudServicioService from '../services/solicitudservicio-service';




export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxLookup,
        DxForm,
        DxItem,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule,
        DxFormItem,
          
    },
    props: {
    supplierId: {
      type: Number,
      default: null,
    },
  },
    data() {
        
        return {
            
            procedimientos: null,
            dataSourceServicio: null,
            dataSourceSolicitudServicio:null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Solicitud Servicio',
                showTitle: true,
                width: 400,
                height: 400,
            },
             
           
            
        };
    },
    created() {
        
         this.solicitudservicioService = new SolicitudServicioService();
        this.servicioService = new Servicioervice();

    },
    mounted() {
        
        this.loadDataInfo();
    },
    methods: {
        loadDataInfo() {
            let id = this.supplierId;
            let myJSON = JSON.stringify({
                psolicitudid: id
            });
            
            this.servicioService.getServicio().then(data => this.dataSourceServicio = data);
            this.solicitudservicioService.getSolicitudServicio(myJSON).then(data => this.dataSourceSolicitudServicio = data);
        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },
       
        deleteRegister(e) {
            console.log(e.data);
            let myJSON = JSON.stringify({
                pid: e.data.id,
            });


            this.solicitudservicioService.deleteSolicitudServicio(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
         editRegister(e) {
            
           
            let myJSON = JSON.stringify({
                pid: e.data.id,
                 pservicioid: e.data.servicioid,
                pobservaciones: e.data.observaciones,
            });
           

            this.solicitudservicioService.updateSolicitudServicio(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha actualizado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        createRegister(e) {
  

            let id =this.supplierId;
            let myJSON = JSON.stringify({
                psolicitudid: id,
                pservicioid: e.data.servicioid,
                pobservaciones: e.data.observaciones,

            });


            this.solicitudservicioService.createSolicitudServicio(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        onInitNewRow(e) {
            e.data.bactivo = true;
        },
    },
};
