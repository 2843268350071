import axios from 'axios';

export default class GrupoEstatusService {

    getGrupoEstatus() {            
        const url = process.env.VUE_APP_APIURL + `/api/grupoestatus`;    
        return axios.get(url).then(res=>res.data);
    }

    updateGrupoEstatus(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupoestatusedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createGrupoEstatus(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupoestatusalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteGrupoEstatus(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupoestatusbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

   

}