
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import HabilidadTab from './guiaevaluacioncapitulo.vue';
import EstandarTab from './guiaevaluacionestandar.vue';
import FundamentosTab from './guiaevaluacionfundamento.vue';

export default {
  components: {
    DxTabPanel, DxItem, HabilidadTab, EstandarTab, FundamentosTab
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
};
