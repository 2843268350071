import axios from 'axios';

export default class GuiaEvaluacionEstandarFundamentoService {

    getGuiaEvaluacionEstandarFundamento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionestandarfundamento`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getGuiaEvaluacionEstandarFundamentoLeyes() {            
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionestandarfundamentoleyes`;    
        return axios.get(url).then(res=>res.data);
    }
  
    updateGuiaEvaluacionEstandarFundamento(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionestandarfundamentoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createGuiaEvaluacionEstandarFundamento(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionestandarfundamentoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteGuiaEvaluacionEstandarFundamento(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionestandarfundamentobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    // Se agregan nuevas relaciones de preguntas sobre una ley en especifico
    getGuiaEvaluacionEstandarLey(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionestandarleyconsulta`;    
     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
    createGuiaEvaluacionEstandarLey(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionestandarleyalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    deleteGuiaEvaluacionEstandarLey(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionestandarleybaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

}