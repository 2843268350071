import axios from 'axios';

export default class GrupoListadoDocumentacionService {

    getGrupoListadoInformacion() {            
        const url = process.env.VUE_APP_APIURL + `/api/grupolistadoinformacion`;    
        return axios.get(url).then(res=>res.data);
    }

    updateGrupoListadoInformacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupolistadoinformacionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createGrupoListadoInformacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupolistadoinformacionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteGrupoListadoInformacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupolistadoinformacionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}