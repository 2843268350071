import axios from 'axios';

export default class LeyDetalleService {

    getLeyDetalle(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/leydetalle`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getLeyDetalleParte(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/leydetalleporparte`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getLeyDetallePartePadre(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/leydetalleporpartepadre`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
 
    updateLeyDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/leydetalleedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateLeyDetalleGeneral(info){
        const url = process.env.VUE_APP_APIURL + `/api/leydetalleactualizacion`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createLeyDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/leydetallealta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteLeyDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/leydetallebaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getLeyDetalleConsultaGeneral(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/leydetalleconsultageneral`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    //asignacion eca ley
    getLeyDetalleConsultaGeneralECA() {            
        const url = process.env.VUE_APP_APIURL + `/api/leydetalleconsultaeca`;    
        return axios.post(url,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getLeyDetalleConsultaGeneralECAEstandar(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/leydetalleconsultaecaestandar`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateLeyDetalleAsignaECA(info){
        const url = process.env.VUE_APP_APIURL + `/api/leydetalleasignaecaestandar`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


     //asignacion irregularidad de ley
     getLeyDetalleConsultaGeneralProcedimiento() {            
        const url = process.env.VUE_APP_APIURL + `/api/leydetalleconsultaotprocedimiento`;    
        return axios.post(url,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getLeyDetalleConsultaGeneralProcedimientoIrregularidad(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/leydetalleconsultaotprocedimientoirregularidad`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateLeyDetalleAsignaIrregularidad(info){
        const url = process.env.VUE_APP_APIURL + `/api/leydetalleconsultaotprocedimientoirregularidadasigna`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}