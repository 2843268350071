import axios from 'axios';

export default class FactorUMAService {

    getFactorUMA() {            
        const url = process.env.VUE_APP_APIURL + `/api/factoruma`;    
        return axios.get(url).then(res=>res.data);
    }

    updateFactorUMA(info){
        const url = process.env.VUE_APP_APIURL + `/api/factorumaedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    altaFactorUMA(info){
        const url = process.env.VUE_APP_APIURL + `/api/factorumaalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteFactorUMA(info){
        const url = process.env.VUE_APP_APIURL + `/api/factorumabaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}