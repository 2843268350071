import axios from 'axios';

export default class MultaTemaService {

    getMultaTema() {            
        const url = process.env.VUE_APP_APIURL + `/api/multatema`;    
        return axios.get(url).then(res=>res.data);
    }

    updateMultaTema(info){
        const url = process.env.VUE_APP_APIURL + `/api/multatemaedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createMultaTema(info){
        const url = process.env.VUE_APP_APIURL + `/api/multatemaalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteMultaTema(info){
        const url = process.env.VUE_APP_APIURL + `/api/multatemabaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    //// ECAs
    getMultaTemaEca(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/multatemaeca`;   
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data); 
        
    }

    updateMultaTemaEca(info){
        const url = process.env.VUE_APP_APIURL + `/api/multatemaedicioneca`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createMultaTemaEca(info){
        const url = process.env.VUE_APP_APIURL + `/api/multatemaaltaeca`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteMultaTemaEca(info){
        const url = process.env.VUE_APP_APIURL + `/api/multatemabajaeca`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getMultaECA() {            
        const url = process.env.VUE_APP_APIURL + `/api/multatemaobtieneeca`;   
        return axios.get(url).then(res=>res.data);
        
    }

    getMultaECAEstandar(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/multatemaobtieneecaestandar`;   
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data); 
        
    }


    //// Fin ECAs

    getMultaTemaInfraccion() {            
        const url = process.env.VUE_APP_APIURL + `/api/multatemainfraccion`;    
        return axios.get(url).then(res=>res.data);
    }

    updateMultaTemaInfraccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/multatemainfraccionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createMultaTemaInfraccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/multatemainfraccionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteMultaTemaInfraccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/multatemainfraccionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



    getMultaTemaObligacion() {            
        const url = process.env.VUE_APP_APIURL + `/api/multatemaobligacion`;    
        return axios.get(url).then(res=>res.data);
    }

    updateMultaTemaObligacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/multatemaobligacionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createMultaTemaObligacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/multatemaobligacionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteMultaTemaObligacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/multatemaobligacionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }







    //// MULTA ASIGNACION VINCULO


    getMulta() {            
        const url = process.env.VUE_APP_APIURL + `/api/multa`;    
        return axios.get(url).then(res=>res.data);
    }

    updateMulta(info){
        const url = process.env.VUE_APP_APIURL + `/api/multaedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createMulta(info){
        const url = process.env.VUE_APP_APIURL + `/api/multaalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteMulta(info){
        const url = process.env.VUE_APP_APIURL + `/api/multabaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


    getMultaLey(info){
        const url = process.env.VUE_APP_APIURL + `/api/multaconsultaley`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getMultaLeyDetalle1(info){
        const url = process.env.VUE_APP_APIURL + `/api/multaconsultaleydetalle1`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


}