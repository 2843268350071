import axios from 'axios';

export default class CondicionPagoService {

    getCondicionPago() {            
        const url = process.env.VUE_APP_APIURL + `/api/condicionpago`;    
        return axios.get(url).then(res=>res.data);
    }

    updateCondicionPago(info){
        const url = process.env.VUE_APP_APIURL + `/api/condicionpagoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createCondicionPago(info){
        const url = process.env.VUE_APP_APIURL + `/api/condicionpagoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteCondicionPago(info){
        const url = process.env.VUE_APP_APIURL + `/api/condicionpagobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}