import axios from 'axios';

export default class EcaEvidenciaService {

	getArchivos(datajson) {
		const url = process.env.VUE_APP_APIURL + `/api/evidenciasresultado`;
		return axios.post(url, datajson).then(res => res.data);
	}

	createArchivos(file) {
		const url = process.env.VUE_APP_APIURL + `/api/archivos/guardar`;
		return axios.post(url, file).then(res => res.data);
	}

	deleteArchivos(file) {
		const url = process.env.VUE_APP_APIURL + `/api/evidenciabaja`;
		return axios.post(url, file).then(res => res.data);
	}

}