import axios from 'axios';

export default class HabilidadService {

    getHabilidad() {            
        const url = process.env.VUE_APP_APIURL + `/api/habilidad`;    
        return axios.get(url).then(res=>res.data);
    }

    updateHabilidad(info){
        const url = process.env.VUE_APP_APIURL + `/api/habilidadedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createHabilidad(info){
        const url = process.env.VUE_APP_APIURL + `/api/habilidadalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteHabilidad(info){
        const url = process.env.VUE_APP_APIURL + `/api/habilidadbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}