import axios from "axios";

export default class PermissionRoleService {
  getPermissionRole() {
    const url = process.env.VUE_APP_APIURL + `/api/rolpermisos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getPermissionRoleByAssigning(id_rol) {
    const url = process.env.VUE_APP_APIURL + `/api/rolpermisos/asignados/${id_rol}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getPermissionRoleByAvailable(id_rol) {
    const url = process.env.VUE_APP_APIURL + `/api/rolpermisos/disponibles/${id_rol}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updatePermissionRole(rolpermiso) {
    const url = process.env.VUE_APP_APIURL + `/api/rolpermisos/${rolpermiso.id_rol_permiso}`;
    return axios
      .put(url, rolpermiso, { headers: { "Content-Type": "application/json" } })
      .then((res) => res.data);
  }

  createPermissionRole(rolpermiso) {
    const url = process.env.VUE_APP_APIURL + `/api/rolpermisos`;
    return axios
      .post(url, rolpermiso, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => res.data);
  }

  deletePermissionRole(rolpermiso) {
    const url = process.env.VUE_APP_APIURL + `/api/rolpermisos/${rolpermiso.id_rol_permiso}`;
    return axios
      .delete(url, rolpermiso, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => res.data);
  }
}
