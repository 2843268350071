export const resourcesList = ['Estatus', 'Tipo', 'Prioridad', 'Asignado'];

export const jobtitles = [
{
  idPuesto: 1,
  nombre: 'Gerente de Mantenimiento',
  activo: true,
},
{
  idPuesto: 2,
  nombre: 'Supervidor de Mantenimiento',
  activo: true,
},
{
  idPuesto: 3,
  nombre: 'Guardia de Seguridad',
  activo: true,
},
{
  idPuesto: 4,
  nombre: 'Técnico en Mantenimiento',
  activo: true,
}];

export const businesses = [
  {
    idEmpresa: 1,
    clave: '001',
    nombre: 'Edificio MX3000',
    activo: true,
  },
  {
    idEmpresa: 2,
    clave: '002',
    nombre: 'Torres Babilon X',
    activo: true,
  },
];

export const companies = [
  {
    id_empresa: 1,
    clave: '001',
    nombre: 'Edificio MX3000',
    activo: true,
  },
  {
    id_empresa: 2,
    clave: '002',
    nombre: 'Torres Babilon X',
    activo: true,
  },
];

export const warehouses = [
  {
    idAlmacen: 1,
    clave: 'REF01',
    nombre: 'Refacciones',
    activo: true,
  },
  {
    idAlmacen: 2,
    clave: 'CON01',    
    nombre: 'Consumibles',
    activo: true,
  },
  {
    idAlmacen: 3,
    clave: 'HER01',    
    nombre: 'Herramientas',
    activo: true,
  },
];

export const clients = [
  {
    idCliente: 1,
    nombreComercial: 'ACME',
    razonSocial: 'ACME SA DE CV',
    rfc: 'ACM701212XG5',
    logotipo: '',
    vialidad: 'Ave. Juárez',
    numeroExterior: '14',
    numeroInterior: '',
    asentamiento: 'Col. Mirador',
    municipio: 'Guadalajara',
    idPais: 1,
    latitud: 26.344595675394523,
    longitud: -103.15847657960578,
    usoCfdi: '03',
    activo: true,
    fechaReg: '2022-01-01 10:30:25'
  },
  
];

export const countries = [
  {
    idPais: 1,
    nombre: 'México',
  },
  {
    idPais: 2,
    nombre: 'Estados Unidos',
  },
  {
    idPais: 3,
    nombre: 'Canadá',
  },
];

export const unitsofmeasurements = [
  {
    idUnidadMedida: 1,
    nombre: 'Grados Centígrados',
    clave: 'C',
    activo: true,
    planMantenimiento: false,
  },
  {
    idUnidadMedida: 2,
    nombre: 'Milímetros',
    clave: 'mm',
    activo: true,
    planMantenimiento: false,
  },
  {
    idUnidadMedida: 3,
    nombre: 'Metros Cúbicos',
    clave: 'm3',
    activo: true,
    planMantenimiento: false,
  },
  {
    idUnidadMedida: 4,
    nombre: 'Piezas',
    clave: 'Pza',
    activo: true,
    planMantenimiento: false,
  },
];


export const employees = [{
    ID: 1,
    FirstName: 'John',
    LastName: 'Heart',
    Prefix: 'Mr.',
    Position: 'CEO',
    BirthDate: '1964/03/16',
    HireDate: '1995/01/15',
    Notes: 'John has been in the Audio/Video industry since 1990. He has led DevAv as its CEO since 2003.\r\n\r\nWhen not working hard as the CEO, John loves to golf and bowl. He once bowled a perfect game of 300.',
    Address: '351 S Hill St.',
    StateID: 5,
  }, {
    ID: 2,
    FirstName: 'Olivia',
    LastName: 'Peyton',
    Prefix: 'Mrs.',
    Position: 'Sales Assistant',
    BirthDate: '1981/06/03',
    HireDate: '2012/05/14',
    Notes: 'Olivia loves to sell. She has been selling DevAV products since 2012. \r\n\r\nOlivia was homecoming queen in high school. She is expecting her first child in 6 months. Good Luck Olivia.',
    Address: '807 W Paseo Del Mar',
    StateID: 5,
  }, {
    ID: 3,
    FirstName: 'Robert',
    LastName: 'Reagan',
    Prefix: 'Mr.',
    Position: 'CMO',
    BirthDate: '1974/09/07',
    HireDate: '2002/11/08',
    Notes: 'Robert was recently voted the CMO of the year by CMO Magazine. He is a proud member of the DevAV Management Team.\r\n\r\nRobert is a championship BBQ chef, so when you get the chance ask him for his secret recipe.',
    Address: '4 Westmoreland Pl.',
    StateID: 4,
  }, {
    ID: 4,
    FirstName: 'Greta',
    LastName: 'Sims',
    Prefix: 'Ms.',
    Position: 'HR Manager',
    BirthDate: '1977/11/22',
    HireDate: '1998/04/23',
    Notes: "Greta has been DevAV's HR Manager since 2003. She joined DevAV from Sonee Corp.\r\n\r\nGreta is currently training for the NYC marathon. Her best marathon time is 4 hours. Go Greta.",
    Address: '1700 S Grandview Dr.',
    StateID: 11,
  }, {
    ID: 5,
    FirstName: 'Brett',
    LastName: 'Wade',
    Prefix: 'Mr.',
    Position: 'IT Manager',
    BirthDate: '1968/12/01',
    HireDate: '2009/03/06',
    Notes: 'Brett came to DevAv from Microsoft and has led our IT department since 2012.\r\n\r\nWhen he is not working hard for DevAV, he coaches Little League (he was a high school pitcher).',
    Address: '1120 Old Mill Rd.',
    StateID: 13,
  }, {
    ID: 6,
    FirstName: 'Sandra',
    LastName: 'Johnson',
    Prefix: 'Mrs.',
    Position: 'Controller',
    BirthDate: '1974/11/15',
    HireDate: '2005/05/11',
    Notes: "Sandra is a CPA and has been our controller since 2008. She loves to interact with staff so if you've not met her, be certain to say hi.\r\n\r\nSandra has 2 daughters both of whom are accomplished gymnasts.",
    Address: '4600 N Virginia Rd.',
    StateID: 44,
  }, {
    ID: 7,
    FirstName: 'Kevin',
    LastName: 'Carter',
    Prefix: 'Mr.',
    Position: 'Shipping Manager',
    BirthDate: '1978/01/09',
    HireDate: '2009/08/11',
    Notes: 'Kevin is our hard-working shipping manager and has been helping that department work like clockwork for 18 months.\r\n\r\nWhen not in the office, he is usually on the basketball court playing pick-up games.',
    Address: '424 N Main St.',
    StateID: 5,
  }, {
    ID: 8,
    FirstName: 'Cynthia',
    LastName: 'Stanwick',
    Prefix: 'Ms.',
    Position: 'HR Assistant',
    BirthDate: '1985/06/05',
    HireDate: '2008/03/24',
    Notes: 'Cindy joined us in 2008 and has been in the HR department for 2 years. \r\n\r\nShe was recently awarded employee of the month. Way to go Cindy!',
    Address: '2211 Bonita Dr.',
    StateID: 4,
  }, {
    ID: 9,
    FirstName: 'Kent',
    LastName: 'Samuelson',
    Prefix: 'Dr.',
    Position: 'Ombudsman',
    BirthDate: '1972/09/11',
    HireDate: '2009/04/22',
    Notes: 'As our ombudsman, Kent is on the front-lines solving customer problems and helping our partners address issues out in the field.    He is a classically trained musician and is a member of the Chamber Orchestra.',
    Address: '12100 Mora Dr',
    StateID: 26,
  }, {
    ID: 10,
    FirstName: 'Taylor',
    LastName: 'Riley',
    Prefix: 'Mr.',
    Position: 'Network Admin',
    BirthDate: '1982/08/14',
    HireDate: '2012/04/14',
    Notes: "If you are like the rest of us at DevAV, then you've probably reached out for help from Taylor. He does a great job as a member of our IT department.",
    Address: '7776 Torreyson Dr',
    StateID: 5,
  }, {
    ID: 11,
    FirstName: 'Sam',
    LastName: 'Hill',
    Prefix: 'Mr.',
    Position: 'Sales Assistant',
    BirthDate: '1984/02/17',
    HireDate: '2012/02/01',
    Notes: 'Sammy is proud to be a member of the DevAV team. He joined the team in 2012 and has been in the sales department from the beginning.\r\n\r\nHe has just picked up golf so you can find him on the links every weekend.',
    Address: '645 Prospect Crescent',
    StateID: 11,
  }, {
    ID: 12,
    FirstName: 'Kelly',
    LastName: 'Rodriguez',
    Prefix: 'Ms.',
    Position: 'Support Assistant',
    BirthDate: '1988/05/11',
    HireDate: '2012/10/13',
    Notes: "Kelly loves people and that's why she joined DevAV's support department. One of the funniest people in the company, she does stand-up on the weekends at the Laugh Factory.",
    Address: '1601 W Mountain St.',
    StateID: 5,
  }, {
    ID: 13,
    FirstName: 'Natalie',
    LastName: 'Maguirre',
    Prefix: 'Mrs.',
    Position: 'Trainer',
    BirthDate: '1977/10/07',
    HireDate: '2008/06/19',
    Notes: 'Natalie travels the US and teaches our partners how to explain the benefits of our products to customers.\r\n\r\nShe is a proud wife and mom and volunteers her time at the elementary school.',
    Address: '6400 E Bixby Hill Rd',
    StateID: 29,
  }, {
    ID: 14,
    FirstName: 'Walter',
    LastName: 'Hobbs',
    Prefix: 'Mr.',
    Position: 'Programmer',
    BirthDate: '1984/12/24',
    HireDate: '2011/02/17',
    Notes: "Walter has been developing apps and websites for DevAV since 2011. His passion is software and if you ever walk by his desk, you'll know why.\r\n\r\nWally once worked 72 hours straight - writing code and fixing bugs.",
    Address: '10385 Shadow Oak Dr',
    StateID: 13,
  }];
  
  export const states = [{
    ID: 1,
    Name: 'Alabama',
  }, {
    ID: 2,
    Name: 'Alaska',
  }, {
    ID: 3,
    Name: 'Arizona',
  }, {
    ID: 4,
    Name: 'Arkansas',
  }, {
    ID: 5,
    Name: 'California',
  }, {
    ID: 6,
    Name: 'Colorado',
  }, {
    ID: 7,
    Name: 'Connecticut',
  }, {
    ID: 8,
    Name: 'Delaware',
  }, {
    ID: 9,
    Name: 'District of Columbia',
  }, {
    ID: 10,
    Name: 'Florida',
  }, {
    ID: 11,
    Name: 'Georgia',
  }, {
    ID: 12,
    Name: 'Hawaii',
  }, {
    ID: 13,
    Name: 'Idaho',
  }, {
    ID: 14,
    Name: 'Illinois',
  }, {
    ID: 15,
    Name: 'Indiana',
  }, {
    ID: 16,
    Name: 'Iowa',
  }, {
    ID: 17,
    Name: 'Kansas',
  }, {
    ID: 18,
    Name: 'Kentucky',
  }, {
    ID: 19,
    Name: 'Louisiana',
  }, {
    ID: 20,
    Name: 'Maine',
  }, {
    ID: 21,
    Name: 'Maryland',
  }, {
    ID: 22,
    Name: 'Massachusetts',
  }, {
    ID: 23,
    Name: 'Michigan',
  }, {
    ID: 24,
    Name: 'Minnesota',
  }, {
    ID: 25,
    Name: 'Mississippi',
  }, {
    ID: 26,
    Name: 'Missouri',
  }, {
    ID: 27,
    Name: 'Montana',
  }, {
    ID: 28,
    Name: 'Nebraska',
  }, {
    ID: 29,
    Name: 'Nevada',
  }, {
    ID: 30,
    Name: 'New Hampshire',
  }, {
    ID: 31,
    Name: 'New Jersey',
  }, {
    ID: 32,
    Name: 'New Mexico',
  }, {
    ID: 33,
    Name: 'New York',
  }, {
    ID: 34,
    Name: 'North Carolina',
  }, {
    ID: 35,
    Name: 'Ohio',
  }, {
    ID: 36,
    Name: 'Oklahoma',
  }, {
    ID: 37,
    Name: 'Oregon',
  }, {
    ID: 38,
    Name: 'Pennsylvania',
  }, {
    ID: 39,
    Name: 'Rhode Island',
  }, {
    ID: 40,
    Name: 'South Carolina',
  }, {
    ID: 41,
    Name: 'South Dakota',
  }, {
    ID: 42,
    Name: 'Tennessee',
  }, {
    ID: 43,
    Name: 'Texas',
  }, {
    ID: 44,
    Name: 'Utah',
  }, {
    ID: 45,
    Name: 'Vermont',
  }, {
    ID: 46,
    Name: 'Virginia',
  }, {
    ID: 47,
    Name: 'Washington',
  }, {
    ID: 48,
    Name: 'West Virginia',
  }, {
    ID: 49,
    Name: 'Wisconsin',
  }, {
    ID: 50,
    Name: 'Wyoming',
  }, {
    ID: 51,
    Name: 'North Dakota',
  }];

  export const assignees = [{
    text: 'Juan Hernández',
    id: 1,
    color: '#22AFE1',
  }, {
    text: 'Luis Carlos Macías',
    id: 2,
    color: '#32c9ed',
  }, {
    text: 'Esteban López',
    id: 3,
    color: '#1BB31A',
  }, {
    text: 'Miguel Treviño',
    id: 4,
    color: '#AFAFAF',
  }];
  
  export const priorities = [{
    text: 'Normal',
    id: 1,
    color: '#E9CF19',
  }, {
    text: 'Alta',
    id: 2,
    color: '#E97A19',
  }, {
    text: 'Urgente',
    id: 3,
    color: '#DC0D0D',
  }];

  export const statuses = [
    {
      text: 'Planeada',
      id: 1,
      color: '#22AFE1',
    }, {
      text: 'Autorizada',
      id: 2,
      color: '#1BB31A',
    }, {
      text: 'En Proceso',
      id: 3,
      color: '#F2A220',
    }, {
      text: 'Terminada',
      id: 4,
      color: '#AFAFAF',
    }, {
      text: 'Cancelada',
      id: 5,
      color: '#801919',
    },
  ];

  export const types = [
    {
      text: 'Preventivo',
      id: 1,
      color: '#1BB31A',
    }, {
      text: 'Correctivo',
      id: 2,
      color: '#DC0D0D',
    }, {
      text: 'Predictivo',
      id: 3,
      color: '#F2A220',
    }, {
      text: 'Condicional',
      id: 4,
      color: '#22AFE1',
    }, {
      text: 'Oportunidad',
      id: 5,
      color: '#E9CF19',
    },
  ];

  export const data = [
    {
      text: 'OT 1523 Equipos: 2 - Preventivo',
      startDate: new Date('2022-02-21T09:00:00.000'),
      endDate: new Date('2022-02-21T12:00:00.000'),
      assigneeId: 4,
      priorityId: 1,
      statusId: 4,
      typeId: 1,
    },
    {
      text: 'OT 1524 Equipos: 3 - Preventivo',
      startDate: new Date('2022-02-22T09:00:00.000'),
      endDate: new Date('2022-02-22T16:00:00.000'),
      assigneeId: 3,
      priorityId: 1,
      statusId: 3,
      typeId: 1,
    }, {
      text: 'OT 1525 Equipo: 1 - Correctivo',
      startDate: new Date('2022-02-22T08:00:00.000'),
      endDate: new Date('2022-02-22T11:00:00.000'),
      assigneeId: 2,
      priorityId: 3,
      statusId: 2,
      typeId: 2,
    }, {
      text: 'OT 1526 Equipo: 1 - Predictivo',
      startDate: new Date('2022-02-23T10:30:00.000'),
      endDate: new Date('2022-02-23T12:30:00.000'),
      assigneeId: 1,
      priorityId: 2,
      statusId: 2,
      typeId: 3,
    }, {
      text: 'OT 1527 Equipo: 1 - Condicional',
      startDate: new Date('2022-02-23T14:00:00.000'),
      endDate: new Date('2022-02-23T18:00:00.000'),
      assigneeId: 1,
      priorityId: 1,
      statusId: 2,
      typeId: 4,
    }, {
      text: 'OT 1528 Equipo: 2 - Preventivo',
      startDate: new Date('2022-02-24T11:30:00.000'),
      endDate: new Date('2022-02-24T12:30:00.000'),
      assigneeId: 2,
      priorityId: 1,
      statusId: 5,
      typeId: 1,
    }, {
      text: 'OT 1529 Equipo: 1 - Preventivo',
      startDate: new Date('2022-02-24T15:00:00.000'),
      endDate: new Date('2022-02-24T18:00:00.000'),
      assigneeId: 3,
      priorityId: 1,
      statusId: 1,
      typeId: 1,
    }, {
      text: 'OT 1530 Equipo: 1 - Oportunidad',
      startDate: new Date('2022-02-25T08:00:00.000'),
      endDate: new Date('2022-02-25T11:45:00.000'),
      assigneeId: 4,
      priorityId: 1,
      statusId: 2,
      typeId: 5,
    }, {
      text: 'OT 1531 Equipo: 1 - Preventivo',
      startDate: new Date('2022-02-25T12:00:00.000'),
      endDate: new Date('2022-02-25T17:00:00.000'),
      assigneeId: 1,
      priorityId: 1,
      statusId: 3,
      typeId: 1,
    }
  ];

  export const bugs = [
    {
      idReporteFalla: 1,
      folio: 264,
      fecha: '2022-01-01',
      tipoFalla: 'Desgaste Natural',
      titulo: 'Gotera en tubería de drenaje. Estacinamiento Nivel 2C',
      descripcion: 'Hay un charco bastante grande de mal aspecto. Los clientes le sacan la vuelta.',
      equipo: 'BARH-01 Bomba de recirculación',
      ordenTrabajo: '83',
      estatus: 'Pendiente',
      usuario: 'JGG',
      fechaReg: '2022-01-01 10:30:25'
    },
    {
      idReporteFalla: 2,
      folio: 285,
      fecha: '2022-01-16',
      tipoFalla: 'Desgaste Natural',
      titulo: 'Gotera en tubería de drenaje. Estacinamiento Nivel 2C',
      descripcion: 'Hay un charco bastante grande de mal aspecto. Los clientes le sacan la vuelta.',
      equipo: 'BARH-01 Bomba de recirculación',
      ordenTrabajo: '83',
      estatus: 'Pendiente',
      usuario: 'JGG',
      fechaReg: '2022-01-01 10:30:25'
    },
  ];

  export const markersData = [{
    location: [25.692127330811697, -100.13394299657016],
    tooltip: {
      text: 'JGG 22 feb 2022 8:16 AM',
    },
  }, {
    location: [25.69152491051239, -100.13076686727169],
    tooltip: {
      text: 'JGG 22 feb 2022 9:21 AM',
    },
  }, {
    location: { lat: 25.69093289890423, lng: -100.13204202198011 },
    tooltip: {
      text: 'JGG 22 feb 2022 11:38 AM',
    },
  }, {
    location: [25.69185631231703, -100.12854987442026],
    tooltip: {
      text: 'MJE 22 feb 2022 09:12 AM',
    },
  }];
  
  export const routesData = [{
    weight: 4,
    color: 'blue',
    opacity: 0.5,
    mode: 'walking',
    locations: [
      [25.692127330811697, -100.13394299657016],
      [25.69093289890423, -100.13204202198011],
      [25.69185631231703, -100.12854987442026],
    ],
  }];


  export const buildingData = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [10, -80],
            [-80, -80],
            [-80, 80],
            [40, 80],
            [40, -20],
            [100, -20],
            [100, -80],
            [30, -80],
            [30, -74],
            [34, -74],
            [34, -68],
            [40, -68],
            [40, -74],
            [94, -74],
            [94, -26],
            [40, -26],
            [40, -60],
            [34, -60],
            [34, 74],
            [-74, 74],
            [-74, 30],
            [10, 30],
            [10, 24],
            [-74, 24],
            [-74, -24],
            [10, -24],
            [10, -30],
            [-74, -30],
            [-74, -74],
            [10, -74],
          ]],
        },
      },
    ],
  };
  
  export const roomsData = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          name: 'Cuarto Maq 1',
          square: 576,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [-74, -30],
            [34, -30],
            [34, -74],
            [-74, -74],
          ]],
        },
      }, {
        type: 'Feature',
        properties: {
          name: 'Cuarto Maq 2',
          square: 600,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [-74, 24],
            [34, 24],
            [34, -24],
            [-74, -24],
          ]],
        },
      }, {
        type: 'Feature',
        properties: {
          name: 'Cuarto Maq 3',
          square: 540,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [-74, 74],
            [34, 74],
            [34, 30],
            [-74, 30],
          ]],
        },
      }, {
        type: 'Feature',
        properties: {
          name: 'Bombas',
          square: 288,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [40, -26],
            [94, -26],
            [94, -74],
            [40, -74],
          ]],
        },
      },
    ],
  };

  function getImageUrl(name) {
    return `./../assets/${name}.png`;
  }

  export const equipmentData = {
    type: 'FeatureCollection',
    features: [{
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-74.007118, 40.71455],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'New York',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-77.016251, 38.904758],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'Washington',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-81.674782, 30.332251],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'Jacksonville',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-84.423058, 33.763191],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'Atlanta',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97.514839, 35.466179],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'Oklahoma City',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-87.632408, 41.884151],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'Chicago',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-93.103882, 44.947769],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'St. Paul',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-94.626823, 39.113522],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'Kansas City',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-95.381889, 29.775419],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'Houston',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-104.991997, 39.740002],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'Denver',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-116.193413, 43.606979],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'Boise',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-118.245003, 34.053501],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'Los Angeles',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-121.886002, 37.3386],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'San Jose',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-123.0252, 44.923199],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'Salem',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-122.329437, 47.603561],
      },
      properties: {
        url: getImageUrl('logo'),
        text: 'Seattle',
      },
    }],
  };

export const taskdetails = [
  {
    idTarea:1,
    equipo:'BARH01 Bomba de recirculación',
    tipo:'Tarea',
    descripcion:'Engrasado de piezas mecánicas',
    cantidad:null,
    precio:null,
    total:null,
  },
  {
    idTarea:1,
    equipo:'BARH01 Bomba de recirculación',
    tipo:'Medición',
    descripcion:'Flujo de agua: 16 m3/s',
    cantidad:null,
    precio:null,
    total:null,
  },
  {
    idTarea:2,
    equipo:'BARH01 Bomba de recirculación',
    tipo:'Refacción',
    descripcion:'Cambio de empaques',
    cantidad:10,
    precio:50.00,
    total:500.00,
  },
  {
    idTarea:2,
    equipo:'RO02 Engranes principales',
    tipo:'Refacción',
    descripcion:'Cambio de engranes',
    cantidad:1,
    precio:6000.00,
    total:6000.00,
  },
];

export const services = [
  {
    idServicio:1,
    folio:672,
    fecha:'2022-02-15',
    titulo:'Mantenimiento Preventivo de bombas de recirculación',
    periodo:'Febrero 2022',
    poliza:'98824',
    estatus:'Facturado',
    importe:'$6,500',        
  },
  {
    idServicio:2,
    folio:673,
    fecha:'2022-02-16',
    titulo:'Mantenimiento Correctivo Fugas en mangueras',
    periodo:'Febrero 2022',
    poliza:'98824',
    estatus:'Enviado',
    importe:'$4,500',        
  },
];

export const listaprecios = [
  {
    idListaPrecio:1,
    codigo:'BARH01',
    descripcion:'Bomba de agua presurizada',
    numeroParte:'112473',
    marca:'Múnich',
    modelo:'BP-1209',
    um:'Pza',
    precio:1189.00,
    te:'Inmediata',
    condicion:'Contado',
    existencia:25,        
  },
  {
    idListaPrecio:2,
    codigo:'BAC01HP',
    descripcion:'Bomba de agua centrífuga 1hp',
    numeroParte:'824314',
    marca:'Pedrollo',
    modelo:'CPM-620',
    um:'Pza',
    precio:3600.00,
    te:'5 días',
    condicion:'Crédito',
    existencia:0,        
  },
];

export const inventarios = [
  {
    idExistencia:1,
    almacen:'REFACCIONES',
    codigo:'BARH01',
    descripcion:'Bomba de agua presurizada',
    numeroParte:'112473',
    marca:'Múnich',
    modelo:'BP-1209',    
    existencia:25,
    um:'Pza',
    precio:1189.00,            
  },
  {
    idExistencia:2,
    almacen:'REFACCIONES',
    codigo:'BAC01HP',
    descripcion:'Bomba de agua centrífuga 1hp',
    numeroParte:'824314',
    marca:'Pedrollo',
    modelo:'CPM-620',
    existencia:2,
    um:'Pza',
    precio:3600.00,
  },
];

export const tareas = [
  {
    idTarea:1,
    idGrupoTarea:1,
    nombre:'Revisar fugas visualmente',
    duracion: 10,
    descripcion:null,
    idProcedimiento:null,
    idTareaPadre:null,
    activo:true,             
  },
  {
    idTarea:2,
    idGrupoTarea:1,
    nombre:'Revisar lubricación de baleros',
    descripcion:'Revisar si aumenta la temperatura de los baleros',
    duracion: 20,
    idProcedimiento:null,
    idTareaPadre:null,
    activo:true,             
  },
];

export const grupotareas = [
  {
    idGrupoTarea:1,
    nombre:'Rodamientos',    
    activo:true,             
  },
  {
    idGrupoTarea:2,
    nombre:'Eléctricas',
    activo:true,             
  },
];

export const tareaspadre = [
  {
    idTareaPadre:1,
    nombre:'Revisión de Fugas',    
    activo:true,             
  },
];

export const procedimientos = [
  {
    idProcedimiento:1,
    nombre:'Procedimiento de revisión de fugas',    
    activo:true,             
  },
];

export const grupos = [
  {
    idGrupo:1,
    nombre:'Grupo de Alertas',            
  },
  {
    idGrupo:2,
    nombre:'Grupo de Artículos',          
  },
  {
    idGrupo:4,
    nombre:'Grupo de Mediciones',          
  },
  {
    idGrupo:5,
    nombre:'Grupo de Permisos',          
  },
  {
    idGrupo:6,
    nombre:'Grupo de Tareas',          
  },  
  {
    idGrupo:7,
    nombre:'Grupo de Usuarios',          
  },
  {
    idGrupo:8,
    nombre:'Grupo de Productos',          
  },
];

export const tipos = [
  {
    idTipo:1,
    nombre:'Tipo de Archivo',          
  },
  {
    idTipo:2,
    nombre:'Tipo de Artículo',            
  },
  {
    idTipo:3,
    nombre:'Tipo de Control',          
  },
  {
    idTipo:4,
    nombre:'Tipo de Dato',          
  },
  {
    idTipo:5,
    nombre:'Tipo de Documento',          
  },
  {
    idTipo:6,
    nombre:'Tipo de Evento',          
  },
  {
    idTipo:7,
    nombre:'Tipo de Evento de Geocerca',          
  },
  {
    idTipo:8,
    nombre:'Tipo de Falla',          
  },
  {
    idTipo:9,
    nombre:'Tipo de Garantía',          
  },
  {
    idTipo:10,
    nombre:'Tipo de Inventario Físico',          
  },
  {
    idTipo:11,
    nombre:'Tipo de Kit',          
  },
  {
    idTipo:12,
    nombre:'Tipo de Layout',          
  },
  {
    idTipo:13,
    nombre:'Tipo de Log',          
  },
  {
    idTipo:14,
    nombre:'Tipo de Medición',          
  },
  {
    idTipo:15,
    nombre:'Tipo de Mantenimiento',          
  },
  {
    idTipo:16,
    nombre:'Tipo de Movimientos',          
  },
  {
    idTipo:17,
    nombre:'Tipo de Movimiento de Artículos',          
  },
  {
    idTipo:18,
    nombre:'Tipo de Movimiento de Equipos',          
  },
  {
    idTipo:19,
    nombre:'Tipo de Notificación',          
  },
  {
    idTipo:20,
    nombre:'Tipo de Orden de Trabajo',          
  },
  {
    idTipo:21,
    nombre:'Tipo de Procedimiento',          
  },
  {
    idTipo:22,
    nombre:'Tipo de Reconocimiento',          
  },
  {
    idTipo:23,
    nombre:'Tipo de Hallazgo',          
  },
];

export const estatus = [
  {
    idEstatus:1,
    nombre:'Estatus de Cotización',          
  },
  {
    idEstatus:2,
    nombre:'Estatus de Inventario Físico',            
  },
  {
    idEstatus:3,
    nombre:'Estatus de Orden de Compra',          
  },
  {
    idEstatus:4,
    nombre:'Estatus de Orden de Trabajo',          
  },
  {
    idEstatus:5,
    nombre:'Estatus de Orden de Trabajo Equipo',          
  },
  {
    idEstatus:6,
    nombre:'Estatus de Plan de Mantenimiento',          
  },
  {
    idEstatus:7,
    nombre:'Estatus de Reporte de Falla',          
  },
  {
    idEstatus:8,
    nombre:'Estatus de Requisición',          
  },
  {
    idEstatus:9,
    nombre:'Estatus de Tarea',          
  },
  {
    idEstatus:10,
    nombre:'Estatus de Actividad',          
  },  
];


export const actividades = [{
  id_actividad: 1,
  id_usuario_asignado: 1,
  id_usuario: 1,
  titulo: 'Arranque con cliente Workin',
  fecha_inicio: '2022-03-08T00:00:00',
  fecha_fin: '2022-03-10T00:00:00',
  id_estatus: 1,
  id_prioridad: 1,
  avance: 20,
  id_actividad_padre: null,
}, {
  id_actividad: 2,
  id_usuario_asignado: 1,
  id_usuario: 1,
  titulo: 'Entrega de reporte de servicio a ACME',
  fecha_inicio: '2022-03-09T00:00:00',
  fecha_fin: '2022-03-09T00:00:00',
  id_estatus: 21,
  id_prioridad: 2,
  avance: 0,
  id_actividad_padre: null,
}];

export const taskTabs = [
  {
    id: 0,
    text: 'Habilidades',
    icon: 'user',
    content: 'Relción de tarea con habilidades',
  },
  {
    id: 1,
    text: 'Puestos',
    icon: 'comment',
    content: 'Relación de tarea con puestos',
  }
];



