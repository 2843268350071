import axios from 'axios';

export default class GrupoEtapaService {

    getGrupoEtapa() {            
        const url = process.env.VUE_APP_APIURL + `/api/grupoetapa`;    
        return axios.get(url).then(res=>res.data);
    }

    updateGrupoEtapa(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupoetapaedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createGrupoEtapa(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupoetapaalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteGrupoEtapa(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupoetapabaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}