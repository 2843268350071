import axios from 'axios';

export default class CompromisoPagoService {

    getCompromisoPago() {            
        const url = process.env.VUE_APP_APIURL + `/api/compromisopago`;    
        return axios.get(url).then(res=>res.data);
    }

    updateCompromisoPago(info){
        const url = process.env.VUE_APP_APIURL + `/api/compromisopagoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createCompromisoPago(info){
        const url = process.env.VUE_APP_APIURL + `/api/compromisopagoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteCompromisoPago(info){
        const url = process.env.VUE_APP_APIURL + `/api/compromisopagobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}