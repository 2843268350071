import axios from 'axios';

export default class ControlSizeService {

	getTamanoControles() {		
        const url = process.env.VUE_APP_APIURL + `tamanocontroles`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTamanoControl(tamanocontrol) {
        const url = process.env.VUE_APP_APIURL + `/tamanocontroles/${tamanocontrol.id_tamano_control}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTamanoControl(tamanocontrol){
        const url = process.env.VUE_APP_APIURL + `/tamanocontroles/${tamanocontrol.id_tamano_control}`;
		return axios.put(url,tamanocontrol).then(res=>res.data.data);
	}

	createTamanoControl(tamanocontrol){
        const url = process.env.VUE_APP_APIURL + `/tamanocontroles`;
		return axios.post(url,tamanocontrol).then(res=>res.data.data);
	}

	deleteTamanoControl(tamanocontrol){
        const url = process.env.VUE_APP_APIURL + `/tamanocontroles/${tamanocontrol.id_tamano_control}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}