import axios from 'axios';

export default class TipoProyectoService {

    getTipoProyecto() {            
        const url = process.env.VUE_APP_APIURL + `/api/tipoproyecto`;    
        return axios.get(url).then(res=>res.data);
    }

    updateTipoProyecto(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipoproyectoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createTipoProyecto(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipoproyectoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteTipoProyecto(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipoproyectobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}