import axios from "axios";

export default class RolesService {
  getRoles() {
    const url = process.env.VUE_APP_APIURL + `/api/rol`;
    return axios.get(url).then((res) => res.data);
  }
  
  getRolesCombo() {
    const url = process.env.VUE_APP_APIURL + `/api/rol/combo`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateRoles(info) {
    const url = process.env.VUE_APP_APIURL + `/api/roledicion`;
    return axios
      .put(url, info, { headers: { "Content-Type": "application/json" } })
      .then((res) => res.data);
  }

  createRoles(info) {
    const url = process.env.VUE_APP_APIURL + `/api/rolalta`;
    return axios
      .post(url, info, { headers: { "Content-Type": "application/json" } })
      .then((res) => res.data);
  }

  deleteRoles(info) {
    const url = process.env.VUE_APP_APIURL + `/api/rolbaja`;
    return axios
      .post(url, info, { headers: { "Content-Type": "application/json" } })
      .then((res) => res.data);
  }
}
