import axios from 'axios';

export default class TipoMultaService {

    getTipoMulta() {            
        const url = process.env.VUE_APP_APIURL + `/api/tipomulta`;    
        return axios.get(url).then(res=>res.data);
    }

    updateTipoMulta(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipomultaedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createTipoMulta(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipomultaalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteTipoMulta(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipomultabaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}