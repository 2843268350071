import axios from 'axios';

export default class CumplimientoService {

    getCumplimiento() {            
        const url = process.env.VUE_APP_APIURL + `/api/cumplimiento`;    
        return axios.get(url).then(res=>res.data);
    }

    getCumplimientoConsulta() {            
        const url = process.env.VUE_APP_APIURL + `/api/cumplimientoconsulta`;    
        return axios.get(url).then(res=>res.data);
    }

    updateCumplimiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/cumplimientoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createCumplimiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/cumplimientoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteCumplimiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/cumplimientobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
}