import axios from 'axios';

export default class ServicioGrupoListadoInformacionService {

    getServicioGrupoListadoInformacion(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/serviciogrupolistadoinformacion`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

  
    createServicioGrupoListadoInformacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/serviciogrupolistadoinformacionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteServicioGrupoListadoInformacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/serviciogrupolistadoinformacionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

   

}