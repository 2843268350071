import axios from 'axios';

export default class GiroService {

    getGiro() {            
        const url = process.env.VUE_APP_APIURL + `/api/giro`;    
        return axios.get(url).then(res=>res.data);
    }

    getGirosGenerales(){
        const url = process.env.VUE_APP_APIURL + `/api/girosgenerales`;    
        return axios.get(url).then(res=>res.data);
    }

    updateGiro(info){
        const url = process.env.VUE_APP_APIURL + `/api/giroedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createGiro(info){
        const url = process.env.VUE_APP_APIURL + `/api/giroalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteGiro(info){
        const url = process.env.VUE_APP_APIURL + `/api/girobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}