import axios from 'axios';

export default class CotizacionConfiguracionFormatoService {

    getCotizacionConfiguracionFormato(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionconfiguracionformato`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateCotizacionConfiguracionFormato(info){
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionconfiguracionformatoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

   
}