
import {
    DxDataGrid,
    DxEditing,
    DxGrouping,
    DxGroupPanel,
    DxColumn,
    DxPaging,
    DxPopup,
    DxLookup,
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,
    DxSorting,
    DxSelection,

} from 'devextreme-vue/data-grid';
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxToast } from 'devextreme-vue/toast';
import Button from 'devextreme-vue/button';
import PermissionService from "../services/permission_service";
import RolesService from '../services/roles-service';
import PermissionRoleService from '../services/permission-role-service';
export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxGrouping,
        DxGroupPanel,
        DxPopup,
        DxLookup,
        DxForm,
        DxItem,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule,
        DxSorting,
        DxSelection,
        DxTabPanel,
        DxSelectBox,
        Button
    },
    data() {
        return {
            dataSourcePermission: null,
            dataSourceRoles: null,
            dataSourcePermitsAvailable: null,
            dataSourcePermitsAssigned: null,
            selectedRowsData: [],
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            isVisible: false,
            isVisibleRol: false,
            message: '',
            type: 'info',
            roles: null,
        }

    },
    created() {
        this.permissionService = new PermissionService();
        this.rolesService = new RolesService();
        this.permissionRoleService = new PermissionRoleService();
    },
    mounted() {
        this.permissionService.getPermission().then(data => this.dataSourcePermission = data);
        this.rolesService.getRolesCombo().then(data => this.dataSourceRoles = data);
    },
    methods: {
        createPermission(e) {
            this.permissionService.createPermission(e.data).then(() => {
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
            });
        },
        editPermission(e) {
            this.permissionService.updatePermission(e.data).then(() => {
                this.type = 'success';
                this.message = 'Se ha actualizado el registro';
                this.isVisible = true;
            });
        },
        deletePermission(e) {
            this.permissionService.deletePermission(e.data).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
            });
        },
        onInitNewRow(e) {
            e.data.activo = true;

        },
        onSelectionChanged({ selectedRowsData }) {
            this.selectedRowsData = selectedRowsData;
        },
        valueChangedItem(e) {
            this.permissionRoleService.getPermissionRoleByAvailable(e.value.id).then(data => this.dataSourcePermitsAvailable = data);
            this.permissionRoleService.getPermissionRoleByAssigning(e.value.id).then(data => this.dataSourcePermitsAssigned = data);
        },
        addRole() {
            for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
                this.permissionRoleService.createPermissionRole({ "id_rol": this.roles.id, "id_permiso": this.selectedRowsData[this.i].id_permiso }).then(() => {

                    if (this.i == this.selectedRowsData.length) {

                        this.permissionRoleService.getPermissionRoleByAvailable(this.roles.id).then(data => this.dataSourcePermitsAvailable = data);
                        this.permissionRoleService.getPermissionRoleByAssigning(this.roles.id).then(data => this.dataSourcePermitsAssigned = data);
                    }

                });
            }
        },
        deletePermits(e) {
            this.permissionRoleService.deletePermissionRole(e.data).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisibleRol = true;
                this.permissionRoleService.getPermissionRoleByAvailable(this.roles.id).then(data => this.dataSourcePermitsAvailable = data);
                this.permissionRoleService.getPermissionRoleByAssigning(this.roles.id).then(data => this.dataSourcePermitsAssigned = data);
            });
        },
    }
};
