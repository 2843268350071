import axios from 'axios';

export default class EstadoService {

    getEstados() {            
        const url = process.env.VUE_APP_APIURL + `/api/estado`;    
        return axios.get(url).then(res=>res.data);
    }

    
}