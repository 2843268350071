import axios from 'axios';

export default class AlertaService {

    consultarAlertasCorreos() {            
        const url = process.env.VUE_APP_APIURL + `/api/alertascorreo`;    
        return axios.get(url).then(res=>res.data);
    }

    updateAlertasCorreos(info){
        const url = process.env.VUE_APP_APIURL + `/api/alertascorreoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    altaAlertasCorreos(info){
        const url = process.env.VUE_APP_APIURL + `/api/alertascorreoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    bajaAlertasCorreos(info){
        const url = process.env.VUE_APP_APIURL + `/api/alertascorreoaltabaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    
    // Tipos
    consultarAlertasTipos() {            
        const url = process.env.VUE_APP_APIURL + `/api/alertastipo`;    
        return axios.get(url).then(res=>res.data);
    }
    
  // Configuracion
  consultarAlertasConfiguracion() {            
        const url = process.env.VUE_APP_APIURL + `/api/alertasconfiguracion`;    
        return axios.get(url).then(res=>res.data);
    }
    updateAlertasConfiguracion(info){
        const url = process.env.VUE_APP_APIURL + `/api/alertasconfiguracionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

}