import axios from 'axios';

export default class GuiaEvaluacionService {

    getGuiaEvaluacionCapitulo(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacioncapitulo`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

  
    updateGuiaEvaluacionCapitulo(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacioncapituloedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createGuiaEvaluacionCapitulo(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacioncapituloalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteGuiaEvaluacionCapitulo(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacioncapitulobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}