export const tasks = [];

export const dependencies = [];

export const resources = [{
  id: 1,
  text: 'Director General',
}, {
  id: 2,
  text: 'Director Operaciones',
}, {
  id: 3,
  text: 'Director Calidad',
}, {
  id: 4,
  text: 'Inspector Calidad',
}, {
  id: 5,
  text: 'Jefe Seguridad Ambiental',
}, {
  id: 6,
  text: 'Ingeniero Soporte Nivel 1',
}, {
  id: 7,
  text: 'Ingeniero Soporte Nivel 2',
}, {
  id: 8,
  text: 'Ingeniero Soporte Nivel 3',
}];

export const resourceAssignments =[];