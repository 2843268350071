import axios from 'axios';

export default class SemaforoProyectoService {

    getSemaforoProyecto() {            
        const url = process.env.VUE_APP_APIURL + `/api/semaforoproyecto`;    
        return axios.get(url).then(res=>res.data);
    }

    updateSemaforoProyecto(info){
        const url = process.env.VUE_APP_APIURL + `/api/semaforoproyectoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createSemaforoProyecto(info){
        const url = process.env.VUE_APP_APIURL + `/api/semaforoproyectoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteSemaforoProyecto(info){
        const url = process.env.VUE_APP_APIURL + `/api/semaforoproyectobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}