import axios from 'axios';

export default class RoleService {

	getRoles() {			
        const url = process.env.VUE_APP_APIURL + `roles`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getRol(rol) {
        const url = process.env.VUE_APP_APIURL + `/roles/${rol.id_rol}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateRol(rol){
        const url = process.env.VUE_APP_APIURL + `/roles/${rol.id_rol}`;
		return axios.put(url,rol).then(res=>res.data.data);
	}

	createRol(rol){
        const url = process.env.VUE_APP_APIURL + `/roles`;
		return axios.post(url,rol).then(res=>res.data.data);
	}

	deleteRol(rol){
        const url = process.env.VUE_APP_APIURL + `/roles/${rol.id_rol}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}