
import {
  DxDataGrid, DxColumn, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import CompanyService from '../services/company-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,

    DxPaging,
    DxToast,
  },
  data() {
    return {
      dataSourceCompany: [
        { id: '1', descripcion: 'Aguascalientes', bactivo: true },
        { id: '2', descripcion: 'Baja California', bactivo: true },
        { id: '3', descripcion: 'Baja California Sur', bactivo: true },
        { id: '4', descripcion: 'Campeche', bactivo: true },
        { id: '5', descripcion: 'Coahuila', bactivo: true },
        { id: '6', descripcion: 'Colima', bactivo: true },
        { id: '7', descripcion: 'Chiapas', bactivo: true },
        { id: '8', descripcion: 'Chihuahua', bactivo: true },
        { id: '9', descripcion: 'Ciudad de Mexico (DF)', bactivo: true },
        { id: '10', descripcion: 'Durango', bactivo: true },
        { id: '11', descripcion: 'Estado de México', bactivo: true },
        { id: '12', descripcion: 'Guanajuato', bactivo: true },
        { id: '13', descripcion: 'Guerrero', bactivo: true },
        { id: '14', descripcion: 'Hidalgo', bactivo: true },
        { id: '15', descripcion: 'Jalisco', bactivo: true },
        { id: '16', descripcion: 'Michoacán', bactivo: true },
        { id: '17', descripcion: 'Morelos', bactivo: true },
        { id: '18', descripcion: 'Nayarit', bactivo: true },
        { id: '19', descripcion: 'Nuevo León', bactivo: true },
        { id: '20', descripcion: 'Oaxaca', bactivo: true },
        { id: '21', descripcion: 'Puebla', bactivo: true },
        { id: '22', descripcion: 'Querétaro', bactivo: true },
        { id: '23', descripcion: 'Quintana Roo', bactivo: true },
        { id: '24', descripcion: 'San Luis Potosí', bactivo: true },
        { id: '25', descripcion: 'Sinaloa', bactivo: true },
        { id: '26', descripcion: 'Sonora', bactivo: true },
        { id: '27', descripcion: 'Tabasco', bactivo: true },
        { id: '28', descripcion: 'Tamaulipas', bactivo: true },
        { id: '29', descripcion: 'Tlaxcala', bactivo: true },
        { id: '30', descripcion: 'Veracruz', bactivo: true },
        { id: '31', descripcion: 'Yucatán', bactivo: true },
        { id: '32', descripcion: 'Zacatecas', bactivo: true },
      ],
      empresa: {},
      events: [],
      companyService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.companyService = new CompanyService();
  },
  mounted() {

  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editCompany() {

    },
    deleteCompany() {

    },
    createCompany() {

    },
  },
};
