import axios from 'axios';

export default class OrdenTrabajoResultadoService {

    getOrdenTrabajoPlan() {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplanconsulta`;    
        return axios.get(url).then(res=>res.data);
    }

    getOrdenTrabajoPlanTareas(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplantareasconsulta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


    getOrdenTrabajoPlanDependencias(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplandependenciaconsulta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


     // Recursos de plan
    
     getOrdenTrabajoPlanRecursos(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplanrecursosconsulta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getOrdenTrabajoPlanContactoCliente(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/clientecontactoconsultaparaplan`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createOrdenTrabajoPlanRecurso(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplanrecursosalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteOrdenTrabajoPlanRecurso(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplanrecursosbaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteOrdenTrabajoPlan(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplanbaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateOrdenTrabajoPlan(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplanedicion`;    
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    createOrdenTrabajoPlan(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplanalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



      // Recursos - TAREAS de plan
    
      getOrdenTrabajoPlanRecursoTarea(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplanrecursostareasconsulta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

     
    createOrdenTrabajoPlanRecursoTarea(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplanrecursostareasalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteOrdenTrabajoPlanRecursoTarea(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplanrecursostareasbaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}