import axios from 'axios';

export default class CotizacionService {

    getCotizacion() {            
        const url = process.env.VUE_APP_APIURL + `/api/cotizacion`;    
        return axios.get(url).then(res=>res.data);
    }

    updateCotizacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

   
    createCotizacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteCotizacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    //FORMATOS
    
    getCotizacionFormatoNivel2(info){
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionconsultaformatonivel2`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getCotizacionFormatoNivel3(info){
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionconsultaformatonivel3`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getCotizacionEdicion(info){
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionconsultaedicion`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getCotizacionPartidasAsignadas(info){
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionpartidasasignadas`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    
}