
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxLookup,
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,
    DxFormItem,
    DxButton
} from 'devextreme-vue/data-grid';
import notify from 'devextreme/ui/notify';
import Cookies from "js-cookie";
window.Cookies = Cookies;
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import DxPopup  from 'devextreme-vue/popup';
import DxTextArea from 'devextreme-vue/text-area';
import axios from 'axios';
import GuiaEvaluacionEstandarService from '../services/guiaevaluacionestandar-service';
import GuiaEvaluacionEstandarFundamentoService from '../services/guiaevaluacionestandarfundamento-service';
import LeyService from '../services/ley-service';
 
export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxLookup,
        DxForm,
        DxItem,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule,
        DxFormItem,
        DxButton,
        DxPopup ,
        DxTextArea,
    },
    props: {
        supplierId: {
            type: Number,
            default: null,
        },
    },
    data() {

        return {
            popupVisible: false,
            popupTitle: "Vinculación Fundamento",
            loading: true,
            dataSourceFundamentoEstandar: null,
            frm_ed_id: null,
            frm_ed_estandar: null,

            dataSourceLey: null,
            procedimientos: null,
            dataSourceEstandar: null,
            dataSourceCapitulo: null,
            dataSourceCumplimiento: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Estandar - Fundamento',
                showTitle: true,
                width: 600,
                height: 560,
            },
            popupOptions2: {
                title: 'Vincular - Estandar - Fundamento',
                showTitle: true,
                width: 600,
                height: 200,
            },
            leyService: null,



        };
    },
    created() {
        this.GuiaEvaluacionEstandarService = new GuiaEvaluacionEstandarService();
        this.GuiaEvaluacionEstandarFundamentoService = new GuiaEvaluacionEstandarFundamentoService();
        this.leyService = new LeyService();
    },
    mounted() {

        this.loadDataInfo();
    },
    methods: {
        vincularFundamento(e){
            
            this.frm_ed_id=e.row.data.guiaevaluacionestandarid;
            this.frm_ed_estandar = e.row.data.descripcion;
            this.loadDataInfoVinculos();
            
        },
        loadDataInfoVinculos() {

            let myJSON = JSON.stringify({
                pguiaevaluacionestandarid:  this.frm_ed_id,
            });

           
            this.GuiaEvaluacionEstandarFundamentoService.getGuiaEvaluacionEstandarLey(myJSON).then(data => this.dataSourceFundamentoEstandar = data);
            this.leyService.getLey().then(data => this.dataSourceLey = data);
            this.loading = true;
            this.popupVisible = true;
        },
        downloadfile(e) {
            console.log(e);
            let name_file = e.row.data.ruta;

            axios({
                url: process.env.VUE_APP_APIURL + '/api/archivodownload?name_file=' + name_file,
                method: 'GET',
                responseType: 'blob',

            }).then((res) => {
                var url = window.URL.createObjectURL((res.data));
                var namepdf = name_file;

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', namepdf);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });



        },
        loadDataInfo() {


            let id = this.supplierId;
            //this.GuiaEvaluacionEstandarFundamentoService.getGuiaEvaluacionEstandarFundamentoLeyes().then(data => this.dataSourceCumplimiento = data);

            let myJSON = JSON.stringify({
                pguiaevaluacionid: id,

            });
            this.GuiaEvaluacionEstandarService.getGuiaEvaluacionEstandar(myJSON).then(data => this.dataSourceCapitulo = data);
            this.GuiaEvaluacionEstandarFundamentoService.getGuiaEvaluacionEstandarFundamento(myJSON).then(data => this.dataSourceEstandar = data);

        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },

        deleteRegister(e) {

            let myJSON = JSON.stringify({
                pid: e.data.id,
            });


            this.GuiaEvaluacionEstandarFundamentoService.deleteGuiaEvaluacionEstandarFundamento(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        editRegister(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,
                pguiaevaluacionestandarid: e.data.guiaevaluacionestandarid,
                pleydetalleid: 0,//e.data.leydetalleid,
                pfundamento:e.data.fundamento,

            });


            this.GuiaEvaluacionEstandarFundamentoService.updateGuiaEvaluacionEstandarFundamento(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha actualizado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        createRegister(e) {

            let myJSON = JSON.stringify({
                pguiaevaluacionestandarid: e.data.guiaevaluacionestandarid,
                pleydetalleid: 0,//e.data.leydetalleid,
                pfundamento:e.data.fundamento,
            });


            this.GuiaEvaluacionEstandarFundamentoService.createGuiaEvaluacionEstandarFundamento(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },



        createRegisterFE(e) {
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let myJSON = JSON.stringify({
                pguiaevaluacionestandarid: this.frm_ed_id,
                pleyid: e.data.leyid,
                pusuarioid:idusuario,
            });

            
           
            this.GuiaEvaluacionEstandarFundamentoService.createGuiaEvaluacionEstandarLey(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha creado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.loadDataInfoVinculos();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        this.loadDataInfoVinculos();
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                    

                    });


        },

        deleteRegisterFE(e) {
            console.log(e);
            let myJSON = JSON.stringify({
                pid: e.key,
            });
           
            this.GuiaEvaluacionEstandarFundamentoService.deleteGuiaEvaluacionEstandarLey(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha eliminado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.loadDataInfoVinculos();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        this.loadDataInfoVinculos();
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                    

                    });


        },
        onInitNewRow(e) {
            e.data.bactivo = true;
        },
    },
};
