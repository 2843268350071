
import {
    DxDataGrid,
    DxColumn,
    DxGrouping,
    DxColumnChooser,
    DxLoadPanel,
    DxToolbar,
    DxItem,
    DxButton as DxButtonGrid,
    DxEditing,
} from 'devextreme-vue/data-grid';
import { DxButton as DxButtonButton } from 'devextreme-vue/button';
import DxPopup from 'devextreme-vue/popup';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxFileUploader } from 'devextreme-vue/file-uploader';

import Cookies from "js-cookie";
import axios from 'axios';

import EcaEvidenciaService from '../services/ecaevidencia-service';

export default {
    components: {
        DxButtonGrid,
        DxDataGrid,
        DxColumn,
        DxGrouping,
        DxColumnChooser,
        DxLoadPanel,
        DxToolbar,
        DxItem,
        DxButtonButton,
        DxPopup,
        DxTextBox,
        DxFileUploader,
        DxEditing,
    },
    props: {
        templateData: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        const { id } = this.templateData.data;
        return {
            id: id,
            evidence: [],
            popupOptions: {
                title: 'Evidencia',
                showTitle: true,
                width: 900,
                height: 600
            },
            popupTitle: 'Evidencia',
            popupVisible: false,
            ev_nombre: '',
            backendURL: process.env.VUE_APP_APIURL,
        }
    },
    created() {
        this.ecaEvidenciaService = new EcaEvidenciaService();
    },
    mounted() {
        this.refreshDataGrid();
    },
    methods: {
        refreshDataGrid() {
            let datajson = {
                pid: this.id
            };
            this.ecaEvidenciaService.getArchivos(datajson).then(data => {
                this.evidence = data;
            });
        },
        showPopup() {
            this.popupVisible = true;
        },
        onUploaded(e) {
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;
            let res = JSON.parse(e.request.responseText);
            let datajson = {
                tipo: res.tipo,
                file_name: res.file_name,
                folder: res.folder,
                extension: res.mime,
                pusuarioregistroid: idusuario,
                pidresultado: this.id,
                pnombre: this.ev_nombre,
            };
            this.ecaEvidenciaService.createArchivos(datajson).then(() => {
                this.ev_nombre = '';
                this.popupVisible = false;
                this.refreshDataGrid();
            });
        },
        deleteEvidence(e) {
            let datajson = {
                pid: e.data.id, 
            };
            this.ecaEvidenciaService.deleteArchivos(datajson).then(() => {                
                this.refreshDataGrid();
            }); 
        },
        downloadfile(e) { 
            let id = e.row.data.id;
            let name_file = e.row.data.nombre;

            axios({
                url: process.env.VUE_APP_APIURL + '/api/archivodownloadfile?pid=' + id,
                method: 'GET',
                responseType: 'blob',

            }).then((res) => {
                var url = window.URL.createObjectURL((res.data));
                var namepdf = name_file; 
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', namepdf);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });



        },
    }
};
