
  import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
  
    DxPaging,
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxMasterDetail,
  
  } from 'devextreme-vue/data-grid';
  import { DxItem } from 'devextreme-vue/form';
  import { DxToast } from 'devextreme-vue/toast';
  //import GrupoEtapaService from '../services/grupoetapa-service';
  import GrupoEstatusService from '../services/grupoestatus-service';
  import MasterDetail from './grupoestatusmasterdetail.vue';
  
  import Cookies from "js-cookie";
  window.Cookies = Cookies;
  
  
  export default {
    components: {
      DxDataGrid,
      DxEditing,
      DxColumn,
      DxValidationRule,
      DxPaging,
  
      DxForm,
      DxItem,
      DxFilterRow,
      DxSearchPanel,
      DxScrolling,
      DxPager,
      DxSelection,
      DxToast,
      DxMasterDetail,
      MasterDetail,
    },
    data() {
      return {
        productos: null,
        dataSourceProducts: null,
        dataSourceBrands: null,
        dataSourceGroupProducts: null,
        allowedPageSizes: [5, 10, 20, 50, 'all'],
        showInfo: true,
        showNavButtons: true,
        isVisible: false,
        message: '',
        type: 'info',
        popupOptions: {
          title: 'Nombre Agrupación Estatus',
          showTitle: true,
          width: 420,
          height: 250,
        },
        servicioService: null,
        labelMode: 'floating',
        labelLocation: 'left',
      };
    },
    created() {
      //this.grupoetapaService = new GrupoEtapaService();
      this.grupoEstatusService = new GrupoEstatusService();
  
    },
    mounted() {
      this.loadDataInfo();
    },
  
    methods: {
      loadDataInfo() {
        this.grupoEstatusService.getGrupoEstatus().then(data => {
          data.forEach(
            element => {
  
              if (element.bactivo == 1) {
                element.bactivo = true;
              } else {
                element.bactivo = false;
              }
  
            });
          this.dataSourceProducts = data;
  
        });
      },
      onSaved() {
        if (this.retryButtonVisible) {
          this.retryButtonVisible = false;
        }
      },
      onEditCanceled() {
        if (this.retryButtonVisible) {
          this.retryButtonVisible = false;
        }
      },
  
      editRegister(e) {
  
        let vactivo = e.data.bactivo == true ? 1 : 0;
  
        let myJSON = JSON.stringify({
          pid: e.data.id,
          pdescripcion: e.data.descripcion,
          pbactivo: vactivo,
        });
  
  
  
        this.grupoEstatusService.updateGrupoEstatus(myJSON).then(data => {
  
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;
            this.loadDataInfo();
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
  
          }
  
  
        });
      },
      deleteRegister(e) {
        let myJSON = JSON.stringify({
          pid: e.data.id,
        });
  
  
        this.grupoEstatusService.deleteGrupoEstatus(myJSON).then(data => {
  
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
            this.loadDataInfo();
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
  
          }
  
  
        });
  
      },
      createRegister(e) {
  
  
        let vactivo = e.data.bactivo == true ? 1 : 0;
  
        let myJSON = JSON.stringify({
          pdescripcion: e.data.descripcion,
          pbactivo: vactivo,
        });
  
  
  
        this.grupoEstatusService.createGrupoEstatus(myJSON).then(data => {
  
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
            //this.loadDataInfo();
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            // this.loadDataInfo();
          }
          this.loadDataInfo();
  
        });
  
  
      },
  
      onInitNewRow(e) {
        e.data.serializable = true;
        e.data.bactivo = true;
  
        //e.data.id_equipo_padre = null;      
        //this.equipmentService.getProductoPadres().then(data => this.dataSourceFatherItems=data);
      },
    },
  };
  