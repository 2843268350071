
//import { nullLiteral } from '@babel/types';
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import EstatusService from '../services/estatus-service';
import Cookies from "js-cookie";
window.Cookies = Cookies;

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {
      dataSourceCompany: null,
      empresa: {},
      events: [],
      estatusService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.estatusService = new EstatusService();
  },
  mounted() {
    this.loadinfo();
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    loadinfo() {

      this.useconver = Cookies.get("user");
 
      this.estatusService.getEstatus().then(data => {
         
          this.dataSourceCompany = data;

            });


    },
    editRegister(e) {
      let vactivo = e.data.bactivo == true ? 1 : 0;
      let vseguimiento = e.data.seguimiento == true ? 1 : 0;
      let vcierreot = e.data.cierreot == true ? 1 : 0;

        let myJSON = JSON.stringify({
          pid: e.data.id,
          pdescripcion: e.data.descripcion,
          pbactivo: vactivo,
          pseguimiento: vseguimiento,
          pcierreot: vcierreot, 
        });
         
      this.estatusService.updateEstatus(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.loadinfo();
      });


    },
    deleteRegister(e) {
      

        let myJSON = JSON.stringify({
          pid: e.data.id,
         
        });
      this.estatusService.deleteEstatus(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.loadinfo();
      });

    

    },

  
    createRegister(e) {
      let vactivo = e.data.bactivo == true ? 1 : 0;
      let vseguimiento = e.data.seguimiento == true ? 1 : 0;
      let vcierreot = e.data.cierreot == true ? 1 : 0;

        let myJSON = JSON.stringify({
          pdescripcion: e.data.descripcion,
          pbactivo: vactivo,
          pseguimiento: vseguimiento,
          pcierreot: vcierreot, 
        });

        console.log('DATOS',myJSON);
      this.estatusService.createEstatus(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.loadinfo();
      });




    },
  },
};
