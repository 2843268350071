
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import HabilidadTab from './solicitudrevisionservicios.vue';
 
 
//import FundamentosTab from './solicituddocumentacion.vue';
//import HistTab from './solicitudhistorial.vue';
export default {
  components: {
    DxTabPanel, DxItem, HabilidadTab,   //FundamentosTab  
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
};
