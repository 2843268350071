import axios from 'axios';

export default class TipoFolioService {

    getTipoFolio() {            
        const url = process.env.VUE_APP_APIURL + `/api/tipofolio`;    
        return axios.get(url).then(res=>res.data);
    }

    updateTipoFolio(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipofolioedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createTipoFolio(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipofolioalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteTipoFolio(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipofoliobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}