import axios from 'axios';

export default class CotizacionServicioService {

    getCotizacionServicio(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionservicio`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getCotizacionServicioOrdenTrabajo() {		
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionservicioordentrabajo`;    
		return axios.get(url).then(res=>res.data);
	}

    getCotizacionServicioOrdenTrabajoPorId(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionservicioordentrabajoporid`;    
      
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateCotizacionServicio(info){
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionservicioedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getCotizacionPartidasAsignadas(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionpartidasasignadas`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
   
    createCotizacionServicio(info){
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionservicioalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteCotizacionServicio(info){
        const url = process.env.VUE_APP_APIURL + `/api/cotizacionserviciobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
}