
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,

    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,

} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import 'devextreme-vue/text-area';


import SubGiroService from '../services/subgiro-service';





export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,

        DxForm,
        DxItem,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule
    },
    props: {
        supplierId: {
            type: Number,
            default: null,
        },
    },
    data() {

        return {

            procedimientos: null,
            dataSourceGiros: null,
            dataSourceSubgiros: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'SubGiro',
                showTitle: true,
                width: 400,
                height: 300,
            },



        };
    },
    created() {


        this.subGiroService = new SubGiroService();

    },
    mounted() {

        this.loadDataInfo();
    },
    methods: {
        loadDataInfo() {
            let id = this.supplierId;
            let myJSON = JSON.stringify({
                pid: id
            });



            this.subGiroService.getSubGiros(myJSON).then(data => {
                data.forEach(
                    element => {

                        if (element.bactivo == 1) {
                            element.bactivo = true;
                        } else {
                            element.bactivo = false;
                        }

                    });
                this.dataSourceSubgiros = data;

            });


        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },
        onInitNewRow(e) {
            e.data.bactivo = true;
            
        },
        deleteRegister(e) {

            let myJSON = JSON.stringify({
                pid: e.data.id,
            });

            this.subGiroService.deleteSubGiro(myJSON).then(data => {
                if (!data) {
                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                } else {
                    this.type = 'success';
                    this.message = 'Se ha eliminado el registro';
                    this.isVisible = true;
                    this.loadDataInfo();
                }
            });

        },
        editRegister(e) {

            //let id = this.supplierId;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pdescripcion: e.data.descripcion,
                pbactivo: e.data.bactivo,
            });

            this.subGiroService.updateSubGiro(myJSON).then(data => {
                if (!data) {
                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                } else {
                    this.type = 'success';
                    this.message = 'Se ha actualizado el registro';
                    this.isVisible = true;
                    this.loadDataInfo();
                }
            });

        },
        createRegister(e) {


            let id = this.supplierId;
            let myJSON = JSON.stringify({

                pdescripcion: e.data.descripcion,
                pbactivo: e.data.bactivo,
                pgiroid: id,

            });


            this.subGiroService.createSubGiro(myJSON).then(data => {
                if (!data) {
                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                } else {
                    this.type = 'success';
                    this.message = 'Se ha creado el registro';
                    this.isVisible = true;
                    this.loadDataInfo();
                }
            });

        },
        
    },
};
