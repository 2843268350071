import axios from 'axios';

export default class CompanyService {

	getEmpresas() {
		console.log('empresas');		
        const url = process.env.VUE_APP_APIURL + `empresas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEmpresa(empresa) {
        const url = process.env.VUE_APP_APIURL + `/empresas/${empresa.id_empresa}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEmpresa(empresa){
        const url = process.env.VUE_APP_APIURL + `/empresas/${empresa.id_empresa}`;
		return axios.put(url,empresa).then(res=>res.data.data);
	}

	createEmpresa(empresa){
        const url = process.env.VUE_APP_APIURL + `/empresas`;
		return axios.post(url,empresa).then(res=>res.data.data);
	}

	deleteEmpresa(empresa){
        const url = process.env.VUE_APP_APIURL + `/empresas/${empresa.id_empresa}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}