import axios from 'axios';

export default class GrupoEstatusDetalleService {

    getGrupoEstatusDetalle(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/grupoestatusdetalle`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateGrupoEstatusDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupoestatusdetalleedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createGrupoEstatusDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupoestatusdetallealta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteGrupoEstatusDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupoestatusdetallebaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}