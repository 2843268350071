import axios from 'axios';

export default class ClienteTipoService {

    getClienteTipo() {            
        const url = process.env.VUE_APP_APIURL + `/api/clientetipo`;    
        return axios.get(url).then(res=>res.data);
    }

    updateClienteTipo(info){
        const url = process.env.VUE_APP_APIURL + `/api/clientetipoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createClienteTipo(info){
        const url = process.env.VUE_APP_APIURL + `/api/clientetipoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteClienteTipo(info){
        const url = process.env.VUE_APP_APIURL + `/api/clientetipobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}