
import Giros from "./views/giros";
import Roles from "./views/roles";
import TipoLey from "./views/tipoley";
import TipoProyecto from "./views/tipoproyecto";
import Estados from "./views/estados";
import TipoDocumento from "./views/tipodocumento";
import FormaPago from "./views/formapago";
import Habilidad from "./views/habilidad";
import Puesto from "./views/puesto";
import Estatus from "./views/estatus";
import ClienteTipo from "./views/clientetipo";
import TipoMulta from "./views/tipomulta";
import Semaforo from "./views/semaforo";
import Servicio from "./views/servicio";
import Cliente from "./views/cliente";
import ClienteContacto from "./views/clientecontacto";
import ProyectoSeguimiento from "./views/proyectoseguimiento";
import Ley from "./views/ley";
import LeyDocumento from "./views/leydocumento";
import ParLey from "./views/parametrizacionley";
import Usuario from "./views/usuario";
import UsuarioEmpresa from "./views/usuarioempresa.vue"
import UsuarioPermisos from "./views/usuariopermisos";
import Etapa from "./views/etapa";
import ListadoInformacion from "./views/listadoinformacion";
import GrupoListadoInformacion from "./views/grupolistadoinformacion";
import TipoServicio from "./views/tiposervicio";
import TipoFolio from "./views/tipofolio";
import FolioDocumento from "./views/foliodocumento";
import ListadoDocumentacion from "./views/listadodocumentacion";
import GrupoListadoDocumentacion from "./views/grupolistadodocumentacion";
import GrupoEtapa from "./views/grupoetapa";
import GrupoEstatus from "./views/grupoestatus";
import UsuarioHabilidad from "./views/usuariohabilidad";
import GuiaEvaluacion from "./views/guiaevaluacion";
import Multa from "./views/multa";
import Cumplimiento from "./views/cumplimiento";
import Tamano from "./views/tamano";
import Solicitud from "./views/solicitud";
import SolicitudRevision from "./views/solicitudrevision";
import Cotizacion from "./views/cotizacion";
import OrdenTrabajo from "./views/ordentrabajo";
import OrdenTrabajoResultado from "./views/ordentrabajoresultado";
import OrdenTrabajoResultadoConGuia from "./views/ordentrabajoresultadoconguia";
import OrdenTrabajoResultadoProcedimiento from "./views/ordentrabajoresultadoprocedimiento";
import LeyDetalleConsultaGeneral from "./views/leydetalleconsultageneral";
import ReporteVisitaCampo from "./views/reportevisitacampo";
import ReporteAvanceGeneral from "./views/reporteavancegeneral";
import ReporteEspecificoAvance from "./views/reporteespecificoavance";
import ReporteEca from "./views/reporteeca";
import SeguimientoOT from "./views/seguimientoot"
import FactorUMA from "./views/factoruma";
//import PDFPrueba from './views/pdfprueba';

import OrdenTrabajoSeguimientoEstudio from "./views/ordentrabajoseguimientoestudio";
import OrdenTrabajoResultadoEstudio from "./views/ordentrabajoresultadoestudio";
import OrdenTrabajoResultadoPoliza from "./views/ordentrabajoresultadopoliza";
import OrdenTrabajoPlanEjecucion from "./views/plantrabajoejecucion";
import ReporteListadosInformacion from "./views/reporteavancelistadoinformacion";
import ReporteAlertas from "./views/reportealertas";
import ReporteAlertasEnviado from "./views/reportealertasenviado";
import Parametrizacion from "./views/parametrizacion";

import ReporteIndicadores from "./views/reporteindicadores";
import Permisos from "./views/permisos";
import ResponsableTecnico from "./views/responsabletecnico";
import OrdenTrabajoCierre from "./views/ordentrabajocierre";
import PlanTrabajo from "./views/plantrabajo";
import PlanTrabajoCierre from "./views/plantrabajocierre";
import CompromisoPago from "./views/compromisopago";
import Consideracion from "./views/consideracion";
import CondicionesPago from "./views/condicionespago";
import PlazoFacturacion from "./views/plazosfacturacion";
import MultaTema from "./views/multatema";
import MultaObligacion from "./views/multaobligacion";
import MultaInfraccion from "./views/multainfraccion";
import MultaListaInfraccion from "./views/multalistainfraccion";
import ControlSize from "./views/control-size";
import LayoutReport from "./views/layout-report";
import Company from "./views/company";
import Log from "./views/log";
import NotificationLog from "./views/notification-log";
import CotizacionConsulta from "./views/cotizacionconsulta";
import SolicitudConsulta from "./views/solicitudconsulta";
import AlertaCorreo from "./views/alertacorreos";
import AlertaConfiguracion from "./views/alertaconfiguracion";
import Role from "./views/role";
//import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";
import Home from "./views/home";
import Requerimiento007 from "./views/requerimiento007";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";

function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`);
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Home,
    },

    {
      path: "/login",
      name: "login",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "",
      },
      component: loadView("login"),
    },

    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Recuperar Contraseña",
        description:
          "Favor de escribir el correo electrónico registrado para enviar una liga de recuperación de contraseña vía email.",
      },
      component: loadView("reset-password-form"),
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up",
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password",
      },
      component: loadView("change-password-form"),
    },
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/recovery",
      redirect: "/home",
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home",
    },

    {
      path: "/role",
      name: "role",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Role,
    },
 
    {
      path: "/reportevisitacampo",
      name: "reportevisitacampo",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ReporteVisitaCampo
    }, 
    {
      path: "/reporteavancegeneral",
      name: "reporteavancegeneral",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ReporteAvanceGeneral
    },
    {
      path: "/reporteespecificoavance",
      name: "reporteespecificoavance",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ReporteEspecificoAvance
    },
    {
      path: "/factoruma",
      name: "factoruma",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: FactorUMA
    },
    {
      path: "/solicitudconsulta",
      name: "solicitudconsulta",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SolicitudConsulta
    },
    {
      path: "/cotizacionconsulta",
      name: "cotizacionconsulta",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: CotizacionConsulta
    },
    {
      path: "/reporteeca",
      name: "reporteeca",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ReporteEca
    },
    {
      path: "/log",
      name: "log",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Log,
    },

    {
      path: "/notification",
      name: "notification",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Notification,
    },

    {
      path: "/requerimiento007",
      name: "requerimiento007",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Requerimiento007,
    },

    {
      path: "/company",
      name: "company",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Company,
    },

    {
      path: "/layout-report",
      name: "layout-report",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: LayoutReport,
    },

    {
      path: "/control-size",
      name: "control-size",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ControlSize,
    },

    {
      path: "/giros",
      name: "giros",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Giros,
    },
    {
      path: "/roles",
      name: "roles",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Roles,
    },
    {
      path: "/tipoley",
      name: "tipoley",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: TipoLey,
    },
    {
      path: "/tiposervicio",
      name: "tiposervicio",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: TipoServicio,
    },
    {
      path: "/leydetalleconsultageneral",
      name: "leydetalleconsultageneral",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: LeyDetalleConsultaGeneral,
    },
    {
      path: "/tipoproyecto",
      name: "tipoproyecto",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: TipoProyecto,
    },
    {
      path: "/tipofolio",
      name: "tipofolio",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: TipoFolio,
    },
    {
      path: "/foliodocumento",
      name: "foliodocumento",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FolioDocumento,
    },
    {
      path: "/estados",
      name: "estados",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Estados,
    },
    {
      path: "/tipodocumento",
      name: "tipodocumento",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: TipoDocumento,
    },
    {
      path: "/formapago",
      name: "formapago",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormaPago,
    },
    {
      path: "/habilidad",
      name: "habilidad",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Habilidad,
    },
    {
      path: "/puesto",
      name: "puesto",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Puesto,
    },
    {
      path: "/estatus",
      name: "estatus",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Estatus,
    },
    {
      path: "/tipomulta",
      name: "tipomulta",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: TipoMulta,
    },
    {
      path: "/semaforo",
      name: "semaforo",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Semaforo,
    },
    {
      path: "/servicio",
      name: "servicio",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Servicio,
    },
    {
      path: "/cliente",
      name: "cliente",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Cliente,
    },
    {
      path: "/clientecontacto",
      name: "clientecontacto",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ClienteContacto,
    },
    {
      path: "/proyectoseguimiento",
      name: "proyectoseguimiento",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ProyectoSeguimiento,
    },
    {
      path: "/ley",
      name: "ley",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Ley,
    },
    {
      path: "/leydocumento",
      name: "leydocumento",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: LeyDocumento,
    },
    {
      path: "/parametrizacionley",
      name: "parametrizacionley",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ParLey,
    },
    {
      path: "/guiaevaluacion",
      name: "guiaevaluacion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: GuiaEvaluacion,
    },
    {
      path: "/multa",
      name: "multa",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Multa,
    },
    {
      path: "/cumplimiento",
      name: "cumplimiento",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Cumplimiento,
    },
    {
      path: "/tamano",
      name: "tamano",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Tamano,
    },
    {
      path: "/clientetipo",
      name: "clientetipo",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ClienteTipo,
    },
    {
      path: "/solicitud",
      name: "solicitud",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Solicitud,
    },
    {
      path: "/solicitudrevision",
      name: "solicitudrevision",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: SolicitudRevision,
    },
    {
      path: "/cotizacion",
      name: "cotizacion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Cotizacion,
    },
    {
      path: "/ordentrabajo",
      name: "ordentrabajo",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: OrdenTrabajo,
    },
    {
      path: "/ordentrabajocierre",
      name: "ordentrabajocierre",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: OrdenTrabajoCierre,
    },
    {
      path: "/usuario",
      name: "usuario",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Usuario,
    },
    {
      path: "/usuariopermisos",
      name: "usuariopermisos",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: UsuarioPermisos,
    },
    {
      path: "/usuarioempresa",
      name: "usuarioempresa",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: UsuarioEmpresa,
    },
    {
      path: "/usuariohabilidad",
      name: "usuariohabilidad",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: UsuarioHabilidad,
    },
    {
      path: "/compromisopago",
      name: "compromisopago",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: CompromisoPago,
    },
    {
      path: "/consideracion",
      name: "consideracion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Consideracion,
    },
    {
      path: "/condicionespago",
      name: "condicionespago",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: CondicionesPago,
    },
    {
      path: "/plazofacturacion",
      name: "plazofacturacion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: PlazoFacturacion,
    },

    {
      path: "/ordentrabajoresultado",
      name: "ordentrabajoresultado",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: OrdenTrabajoResultado,
    },
    {
      path: "/plantrabajo",
      name: "plantrabajo",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: PlanTrabajo,
    },
    {
      path: "/plantrabajocierre",
      name: "plantrabajocierre",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: PlanTrabajoCierre,
    },
    {
      path: "/listadoinformacion",
      name: "listadoinformacion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ListadoInformacion,
    },

    {
      path: "/listadodocumentacion",
      name: "listadodocumentacion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ListadoDocumentacion,
    },
    {
      path: "/grupolistadodocumentacion",
      name: "grupolistadodocumentacion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: GrupoListadoDocumentacion,
    },
    {
      path: "/grupolistadoinformacion",
      name: "grupolistadoinformacion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: GrupoListadoInformacion,
    },
    {
      path: "/etapa",
      name: "etapa",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Etapa,
    },
    {
      path: "/grupoetapa",
      name: "grupoetapa",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: GrupoEtapa,
    },
    {
      path: "/grupoestatus",
      name: "grupoestatus",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: GrupoEstatus,
    },
    {
      path: "/ordentrabajoresultadoconguia",
      name: "ordentrabajoresultadoconguia",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: OrdenTrabajoResultadoConGuia,
    },
    {
      path: "/ordentrabajoresultadopoliza",
      name: "ordentrabajoresultadopoliza",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: OrdenTrabajoResultadoPoliza,
    },
    {
      path: "/ordentrabajoresultadoestudio",
      name: "ordentrabajoresultadoestudio",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: OrdenTrabajoResultadoEstudio,
    },
    {
      path: "/ordentrabajoseguimientoestudio",
      name: "ordentrabajoseguimientoestudio",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: OrdenTrabajoSeguimientoEstudio,
    },
    {
      path: "/ordentrabajoresultadoprocedimiento",
      name: "ordentrabajoresultadoprocedimiento",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: OrdenTrabajoResultadoProcedimiento,
    },
    {
      path: "/plantrabajoejecucion",
      name: "plantrabajoejecucion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: OrdenTrabajoPlanEjecucion,
    },
    //OrdenTrabajoPlanEjecucion
    {
      path: "/parametrizacion",
      name: "parametrizacion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Parametrizacion,
    },
    {
      path: "/reporteavancelistadoinformacion",
      name: "reporteavancelistadoinformacion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ReporteListadosInformacion,
    },
    {
      path: "/reportealertas",
      name: "reportealertas",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ReporteAlertas,
    },
    {
      path: "/reportealertasenviado",
      name: "reportealertasenviado",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ReporteAlertasEnviado,
    },
    {
      path: "/reporteindicadores",
      name: "reporteindicadores",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ReporteIndicadores,
    },
    {
      path: "/permisos",
      name: "permisos",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Permisos,
    },
    {
      path: "/multatema",
      name: "multatema",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MultaTema,
    },
    {
      path: "/multaobligacion",
      name: "multaobligacion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MultaObligacion,
    },
    {
      path: "/multainfraccion",
      name: "multainfraccion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MultaInfraccion,
    },
    {
      path: "/multalistainfraccion",
      name: "multalistainfraccion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MultaListaInfraccion,
    },
    {
      path: "/responsabletecnico",
      name: "responsabletecnico",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ResponsableTecnico,
    },
    {
      path: "/seguimientoot",
      name: "seguimientoot",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: SeguimientoOT,
    },
    {
      path: "/registronotificaciones",
      name: "registronotificaciones",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: NotificationLog,
    },
    {
      path: "/alertacorreos",
      name: "alertacorreos",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: AlertaCorreo,
    },
    {
      path: "/alertaconfiguracion",
      name: "alertaconfiguracion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: AlertaConfiguracion,
    },
  ],
  history: createWebHashHistory(),
});
/** 
router.beforeEach((to, from, next) => {

  if (to.name === "login" && auth.loggedIn()) {
    next({ name: "home" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
 **/
export default router;
