import axios from 'axios';

export default class EstatusService {

    getEstatus() {            
        const url = process.env.VUE_APP_APIURL + `/api/estatus`;    
        
        return axios.get(url).then(res=>res.data);
    }
    getEstatusSolicitud() {            
         const url = process.env.VUE_APP_APIURL + `/api/estatussolicitud`;    
         return axios.get(url).then(res=>res.data);
        
    }
    getEstatusIrregularidad() {            
        const url = process.env.VUE_APP_APIURL + `/api/estatusirregularidad`;    
        return axios.get(url).then(res=>res.data);
    }

    getEstatusParaEdicion(datos){
        const url = process.env.VUE_APP_APIURL + `/api/estatusparaedicionconsulta`;     
        //return axios.post(url,datos).then(res=>res.data);
        return axios.post(url,datos,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getEstatusParaEdicion2(datos){
        const url = process.env.VUE_APP_APIURL + `/api/estatusparaedicionconsulta2`;     
        //return axios.post(url,datos).then(res=>res.data);
        return axios.post(url,datos,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getEstatusParaEdicionCotizacion(datos){
        const url = process.env.VUE_APP_APIURL + `/api/estatusparaedicionconsultacotizacion`;     
        //return axios.post(url,datos).then(res=>res.data);
        return axios.post(url,datos,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
   
    createEstatus(info){
        
        const url = process.env.VUE_APP_APIURL + `/api/estatusalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateEstatus(info){
        const url = process.env.VUE_APP_APIURL + `/api/estatusedicion`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteEstatus(info){
        const url = process.env.VUE_APP_APIURL + `/api/estatusbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getGrupoEstatusOrden(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupoestatusorden`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getGrupoEstatusOrdenPoliza(info){
        const url = process.env.VUE_APP_APIURL + `/api/grupoestatusordenpoliza`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}