import axios from 'axios';

export default class TipoDocumentoService {

    getTipoDocumento() {            
        const url = process.env.VUE_APP_APIURL + `/api/tipodocumento`;    
        return axios.get(url).then(res=>res.data);
    }

    updateTipoDocumento(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipodocumentoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createTipoDocumento(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipodocumentoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteTipoDocumento(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipodocumentobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}