
import DxContextMenu, { DxPosition } from "devextreme-vue/context-menu";
import DxList from "devextreme-vue/list";

export default {
  props: {
    menuMode: String,
    menuItems: Array,
    email: String
  },
  components: {
    DxContextMenu,
    DxPosition,
    DxList
  }
};
