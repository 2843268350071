import axios from 'axios';

export default class ServicioService {

    getServicio() {            
        const url = process.env.VUE_APP_APIURL + `/api/servicio`;    
        return axios.get(url).then(res=>res.data);
    }

    updateServicio(info){
        const url = process.env.VUE_APP_APIURL + `/api/servicioedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createServicio(info){
        const url = process.env.VUE_APP_APIURL + `/api/servicioalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteServicio(info){
        const url = process.env.VUE_APP_APIURL + `/api/serviciobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getServicioListadoInformacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/serviciolistadoinformacion`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getServicioEdicion(info){
        const url = process.env.VUE_APP_APIURL + `/api/servicioparaedicion`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

}