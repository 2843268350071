
  import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
 
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,
    DxEmailRule
  } from 'devextreme-vue/data-grid';
  import { DxItem } from 'devextreme-vue/form';
  import { DxToast } from 'devextreme-vue/toast';
  import ResponsableTecnicoService from '../services/responsabletecnico-service';
  import Cookies from "js-cookie";
  window.Cookies = Cookies;
  
  
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxPaging,
      DxEditing,
 
      DxForm,
      DxItem,
      DxFilterRow,
      DxSearchPanel,
      DxScrolling,
      DxPager,
      DxToast,
      DxValidationRule,
      DxEmailRule
    },
    data() {
      return {
        procedimientos: null,
        dataSourceResponsable: null,
      
        allowedPageSizes: [5, 10, 20, 50, 'all'],
        showInfo: true,
        showNavButtons: true,
        isVisible: false,
        message: '',
        type: 'info',
        popupOptions: {
          title: 'Responsable Técnico',
          showTitle: true,
          width: 600,
          height: 'auto',
        },
        opcionesColumna: {
          maxLength: 3
        },
      };
    },
    created() {
      this.responsabletecnicoService = new ResponsableTecnicoService();
      
    },
    mounted() {
      this.loadDataInfo();
    },
    methods: {
      loadDataInfo() {
        this.responsabletecnicoService.getResponsableTecnico().then(data => this.dataSourceResponsable = data);
        
      },
      onSaved() {
        if (this.retryButtonVisible) {
          this.retryButtonVisible = false;
        }
      },
      onEditCanceled() {
        if (this.retryButtonVisible) {
          this.retryButtonVisible = false;
        }
      },
      logEvent(eventName) {
        this.events.unshift(eventName);
      },
      editProcedure(e) {
  
        let vactivo = e.data.bactivo == true ? 1 : 0;
        let myJSON = JSON.stringify({
          pid: e.data.id,
          pnombre: e.data.nombre,
          ptelefono: e.data.telefono,
          pcorreo: e.data.correo,
          pbactivo: vactivo,
        });
  
         
        this.responsabletecnicoService.updateResponsableTecnico(myJSON).then(data => {
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;
            this.loadDataInfo();
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
          }
        });
      },
      deleteProcedure(e) {
        let myJSON = JSON.stringify({
          pid: e.data.id,
        });
  
       
  
        this.responsabletecnicoService.deleteResponsableTecnico(myJSON).then(data => {
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
            this.loadDataInfo();
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
          }
        });
      },
      createProcedure(e) {
  
  
       
        let vactivo = e.data.bactivo == true ? 1 : 0;
        let myJSON = JSON.stringify({
            pnombre: e.data.nombre,
          ptelefono: e.data.telefono,
          pcorreo: e.data.correo,
          pbactivo: vactivo,
        });
  
  
  
        this.responsabletecnicoService.createResponsableTecnico(myJSON).then(data => {
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
            this.loadDataInfo();
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
          }
        });
      },
      onInitNewRow(e) {
        e.data.bactivo = true;
      },
    },
  };
  