
  import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxLookup,
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,
    DxEmailRule,
     
  } from 'devextreme-vue/data-grid';
  import { DxItem } from 'devextreme-vue/form';
  import { DxToast } from 'devextreme-vue/toast';
  import AlertaService from '../services/alerta-service';
   
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxPaging,
      DxEditing,
      DxLookup,
      DxForm,
      DxItem,
      DxFilterRow,
      DxSearchPanel,
      DxScrolling,
      DxPager,
      DxToast,
      DxValidationRule,
      DxEmailRule,
     
    },
    data() {
      return {
        customer: null,
        procedimientos: null,
        dataSourceTipoAlerta: null,
        dataSourceCorreos: null,
        
        
        allowedPageSizes: [5, 10, 20, 50, 'all'],
        showInfo: true,
        showNavButtons: true,
        isVisible: false,
        message: '',
        type: 'info',
        popupOptions: {
          title: 'Cliente',
          showTitle: true,
          width: 800,
          height: 'auto',
        },
        opcionesColumna: {
          maxLength: 3
        },
        setStateValueGiro(rowData, value) {
          rowData.subgiroid = null;
          this.defaultSetCellValue(rowData, value);
        },
      };
    },
    
    created() {
      this.AlertaService = new AlertaService();
      
    },
    mounted() {
      this.loadDataInfo();
    },
    methods: {
      loadDataInfo() {
        this.AlertaService.consultarAlertasCorreos().then(data => this.dataSourceCorreos = data);
        this.AlertaService.consultarAlertasTipos().then(data => this.dataSourceTipoAlerta = data);
       
      },
      onSaved() {
        if (this.retryButtonVisible) {
          this.retryButtonVisible = false;
        }
      },
      onEditCanceled() {
        if (this.retryButtonVisible) {
          this.retryButtonVisible = false;
        }
      },
      logEvent(eventName) {
        this.events.unshift(eventName);
      },
      editProcedure(e) {
   

        let vmodoprueba = e.data.modoprueba == true ? 1 : 0;
        let myJSON = JSON.stringify({
           pid: e.data.id,
          pcorreo: e.data.correo,
          palertatipoid: e.data.alertatipoid,
          pmodoprueba: vmodoprueba,
          
        });
        console.log(myJSON);
  
       
        
        this.AlertaService.updateAlertasCorreos(myJSON).then(data => {
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;
            this.loadDataInfo();
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
          }
        });
      },
      deleteProcedure(e) {
        let myJSON = JSON.stringify({
          pid: e.data.id,
        });
  
       
        this.AlertaService.bajaAlertasCorreos(myJSON).then(data => {
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
            this.loadDataInfo();
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
          }
        });
      },
      createProcedure(e) {

        let vmodoprueba = e.data.modoprueba == true ? 1 : 0;
        let myJSON = JSON.stringify({
          pcorreo: e.data.correo,
          palertatipoid: e.data.alertatipoid,
          pmodoprueba: vmodoprueba,
          
        });
   
        
        this.AlertaService.altaAlertasCorreos(myJSON).then(data => {
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
            this.loadDataInfo();
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
          }
        });
      },
      
      
      
      onInitNewRow(e) {
        e.data.modoprueba = false;
        
      },
    },
  };
  