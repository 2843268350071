import axios from 'axios';

export default class OrdenTrabajoProcedimientoMedidasService {

    getTipoMedida(info){
        const url = process.env.VUE_APP_APIURL + `/api/tipomedidaconsulta`;    
        return axios.get(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getAccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioaccion`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createAccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioaccionalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteAccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioaccionbaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateAccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioaccionedicion`;    
        return axios.patch(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getFactorAmbiental(info){
        const url = process.env.VUE_APP_APIURL + `/api/factorambiental`;
        return axios.get(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
   
    getAccionMedida(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioaccionmedida`;
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createMedida(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioaccionmedidaalta`;
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteMedida(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioaccionmedidabaja`;
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateMedida(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioaccionmedidaedicion`;
        return axios.patch(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateMedidaSeguimiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioaccionmedidaedicionseguimiento`;
        return axios.patch(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data); 
    }

    getMedidaSeguimiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioaccionmedidaseguimiento`;
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

}