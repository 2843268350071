import axios from 'axios';

export default class FormaPagoService {

    getFormaPago() {            
        const url = process.env.VUE_APP_APIURL + `/api/formapago`;    
        return axios.get(url).then(res=>res.data);
    }

    updateFormaPago(info){
        const url = process.env.VUE_APP_APIURL + `/api/formapagoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createFormaPago(info){
        const url = process.env.VUE_APP_APIURL + `/api/formapagoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteFormaPago(info){
        const url = process.env.VUE_APP_APIURL + `/api/formapagobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}