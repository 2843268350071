import axios from 'axios';

export default class GuiaEvaluacionEstandarService {

    getGuiaEvaluacionEstandar(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionestandar`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

  
    updateGuiaEvaluacionEstandar(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionestandaredicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createGuiaEvaluacionEstandar(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionestandaralta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteGuiaEvaluacionEstandar(info){
        const url = process.env.VUE_APP_APIURL + `/api/guiaevaluacionestandarbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}