import axios from 'axios';

export default class TipoServicioSeguimientoService {

    getTipoServicioSeguimiento() {            
        const url = process.env.VUE_APP_APIURL + `/api/tiposervicioseguimiento`;    
        return axios.get(url).then(res=>res.data);
    }

     
}