

import {
  DxDataGrid, DxColumn, DxEditing, DxPaging, DxExport
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import Reporteservice from '../services/reportes-service';
import notify from 'devextreme/ui/notify';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxExport
  },
  data() {
    return {
      dataSourceCompany: null,
      empresa: {},
      events: [],
      rolService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.reportesService = new Reporteservice();
  },
  mounted() {
    this.reportesService.getAvanceListadoInformacion().then(data => {

      this.dataSourceCompany = data;

    });

  },
  methods: {

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('AvanceListado');

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReporteAvanceEntregaListadoInformacion.xlsx');
        });
      });
      e.cancel = true;
    },

    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editRegister(e) {
      let vactivo = e.data.bactivo == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pid: e.data.id,
        pdescripcion: e.data.descripcion,
        pbactivo: vactivo,
      });

      this.rolService.updateRoles(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });

    },
    deleteRegister(e) {

      let myJSON = JSON.stringify({
        pid: e.data.id,
      });

      this.rolService.deleteRoles(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
    },
    createRegister(e) {
      let vactivo = e.data.bactivo == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pdescripcion: e.data.descripcion,
        pbactivo: vactivo,
      });

      if (e.data.descripcion == '' || e.data.descripcion == null) {
        notify('Capture la descripcion', "error", 2000);
      } else {
        this.rolService.createRoles(myJSON).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
        });
      }


    },
  },
};
