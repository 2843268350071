import axios from 'axios';

export default class OrdenTrabajoResultadoService {

    getOrdenTrabajoEjecucion() {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoejecucionconsulta`;    
        return axios.get(url).then(res=>res.data);
    }

    getOrdenTrabajoResultado() {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoresultadoconsulta`;    
        return axios.post(url,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getOrdenTrabajoResultadoDetalle(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoresultadoconsultadetalle`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getOrdenTrabajoResultadoListadoDocumentacion(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoresultadolistadodocumentacion`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getOrdenTrabajoResultadoListadoInformacion(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoresultadolistadoinformacion`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    

    getOrdenTrabajoResultadoPorId(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoejecucionconsultaporid`;    
        
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getOrdenTrabajoResultadoPorId2(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoejecucionconsultaporid2`;    
        //console.log('RRERERERE',axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data));
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getPolizaResultadoPorId(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/polizaejecucionconsultaporid`;    
        
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getPolizaPorServicio(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/polizaejecucionconsultaporservicio`;    
        
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getOrdenTrabajoResultadoEdicion(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoejecucionedicion`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getOrdenTrabajoHistorialCambios(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajohistorialcambiosconsulta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getOrdenTrabajoCierre(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoejecuciocierre`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateOrdenTrabajoListadoInformacion(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajolistadoinformacionedicion`;    
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
    updateOrdenTrabajoListadoDocumentacion(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajolistadodocumentacionedicion`;    
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateOrdenTrabajoResultado(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoresultadoedicion`;    
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getOrdenTrabajoCierreEtapa(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoejecuciocambioetapa`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getOrdenTrabajoResultadoECA(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoresultadoeca`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getECAMulta(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoresultadoconsultadetallepregunta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getECAMultaSuma(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoresultadoconsultadetallepreguntasuma`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
    getOrdenTrabajoProcedimiento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoconsultaporid`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateOrdenTrabajoProcedimiento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoedicion`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateOrdenTrabajoProcedimientocont(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoedicioncont`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateOrdenTrabajoProcedimientoreso(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoedicionreso`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
    updateOrdenTrabajoProcedimientoaleg(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoedicionaleg`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



    getOrdenTrabajoIrregularidad(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoirregularidad`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createOrdenTrabajoIrregularidad(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoirregularidadalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateOrdenTrabajoIrregularidad(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoirregularidadedicion`;    
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteOrdenTrabajoIrregularidad(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoirregularidadbaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



    getOrdenTrabajoIrregularidadFundamento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoirregularidadfundamento`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createOrdenTrabajoIrregularidadFundamento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoirregularidadfundamentoalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateOrdenTrabajoIrregularidadFundamento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoirregularidadfundamentoedicion`;    
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteOrdenTrabajoIrregularidadFundamento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoirregularidadfundamentobaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateOrdenTrabajoResultadoReset(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoresultadoedicionreset`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


    // CONTESTACION

    
    getOrdenTrabajoProcedimientoContestacion(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientocontestacion`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    
    updateOrdenTrabajoProcedimientoContestacion(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientocontestacionedicion`;    
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
  
    createOrdenTrabajoProcedimientoContestacion(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientocontestacionalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
    deleteOrdenTrabajoProcedimientoContestacion(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientocontestacionbaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


    // CONTESTACION IRREGULARIDAD

    getOrdenTrabajoProcedimientoContestacionirre(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientocontestacionirr`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    
    updateOrdenTrabajoProcedimientoContestacionirre(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientocontestacionirredicion`;    
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
  
    createOrdenTrabajoProcedimientoContestacionirre(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientocontestacionirralta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
    deleteOrdenTrabajoProcedimientoContestacionirre(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientocontestacionirrbaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    /********************************************* */
    getOrdenTrabajoProcedimientoContestacionIrrEdicion(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoirregularidadconsultacontestacion`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



     // RESOLUCION IRREGULARIDAD

     getOrdenTrabajoProcedimientoResolucionirre(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoresolucionirr`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    
  
    createOrdenTrabajoProcedimientoResolucionirre(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoresolucionirralta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
    deleteOrdenTrabajoProcedimientoResolucionirre(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoresolucionirrbaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



     // RESOLUCION PROCESO RESP

     getOrdenTrabajoProcedimientoResolucionProc(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoresolucionproc`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    
  
    createOrdenTrabajoProcedimientoResolucionProc(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoresolucionprocalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
    deleteOrdenTrabajoProcedimientoResolucionProc(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoresolucionprocbaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

     // TPS
     updateOrdenTrabajoProcedimientoResolucionProcTps1(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoresolucionprocesotps1`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateOrdenTrabajoProcedimientoResolucionProcTps2(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoresolucionprocesotps2`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateOrdenTrabajoProcedimientoResolucionProcTps4(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoresolucionprocesotps4`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateOrdenTrabajoProcedimientoResolucionProcTps5(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoresolucionprocesotps5`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateOrdenTrabajoProcedimientoResolucionProcTps7(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoresolucionprocesotps7`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updateOrdenTrabajoProcedimientoResolucionProcTps8(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoresolucionprocesotps8`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateOrdenTrabajoProcedimientoResolucionProcTps9(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoresolucionprocesotps9`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateOrdenTrabajoProcedimientoResolucionProcTps6(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoresolucionprocesotps6`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }




     // RECONSIDERACIONES

     getOrdenTrabajoProcedimientoRecProcIrr(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoreconsideracionirr`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    
  
    createOrdenTrabajoProcedimientoRecProcIrr(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoreconsideracionirralta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
    deleteOrdenTrabajoProcedimientoRecProcIrr(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoreconsideracionirrbaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateOrdenTrabajoProcedimientoRecProcIrr(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientoreconsideracionedicionirr`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


    // MEDIDA DE SEGURIDAD EXPEDIENTE
    getOrdenTrabajoProcedimientoMedidasExpediente(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientomedidaseguridadexpediente`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
  
    createOrdenTrabajoProcedimientoMedidasExpediente(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientomedidaseguridadexpedientealta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
    deleteOrdenTrabajoProcedimientoMedidasExpediente(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientomedidaseguridadexpedientebaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateOrdenTrabajoProcedimientoMedidasExpediente(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientomedidaseguridadexpedienteedicion`;    
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


    // MEDIDA DE SEGURIDAD
    getOrdenTrabajoProcedimientoMedidas(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientomedidaseguridad`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
  
    createOrdenTrabajoProcedimientoMedidas(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientomedidaseguridadalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    
    deleteOrdenTrabajoProcedimientoMedidas(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientomedidaseguridadbaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateOrdenTrabajoProcedimientoMedidas(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientomedidaseguridadedicion`;    
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


    //MEDIDAS DE SEGURIDAD FUNDAMENTO
    getOrdenTrabajoProcedimientoMedidasFundamento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientomedidaseguridadfundamento`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createOrdenTrabajoProcedimientoMedidasFundamento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientomedidaseguridadfundamentoalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateOrdenTrabajoProcedimientoMedidasFundamento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientomedidaseguridadfundamentoedicion`;    
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteOrdenTrabajoProcedimientoMedidasFundamento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoprocedimientomedidaseguridadfundamentobaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
 
    /** INFORMACION COMPLEMENTARIA OT */
    getComplementaria(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/complementaria`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    altaComplementaria(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/complementariaalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    cambioComplementaria(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/complementariaedicion`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    bajaComplementaria(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/complementariabaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getResolutivo(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoresolutivo`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    cambioResolutivo(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoresolutivoedicion`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getVigencia() {            
        const url = process.env.VUE_APP_APIURL + `/api/aniovigencia`;    
        return axios.get(url).then(res=>res.data);
    }


    
    getResponsable(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoresponsable`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getResponsablePorId(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoresponsableporid`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    cambioResponsable(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoresponsableedicion`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
/**  
    updateOrdenTrabajoPlan(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplanedicion`;    
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


    // Recursos de plan
    
    getOrdenTrabajoPlanRecursos(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplanrecursosconsulta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getOrdenTrabajoPlanContactoCliente(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/clientecontactoconsultaparaplan`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createOrdenTrabajoPlanRecurso(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplanrecursosalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteOrdenTrabajoPlanRecurso(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoplanrecursosbaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
**/

}