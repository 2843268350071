import axios from 'axios';

export default class UsuarioHabilidadService {

    getUsuarioHabilidad(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/usuariohabilidad`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

   
    createUsuarioHabilidad(info){
        const url = process.env.VUE_APP_APIURL + `/api/usuariohabilidadalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteUsuarioHabilidad(info){
        const url = process.env.VUE_APP_APIURL + `/api/usuariohabilidadbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}