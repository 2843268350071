import axios from 'axios';

export default class OrdenTrabajoDocumentoService {

     
 

    getOrdenTrabajoDocumento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajodocumentoconsulta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createOrdenTrabajoDocumento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajodocumentoalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteOrdenTrabajoDocumento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajodocumentobaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

   downloadOrdenTrabajoDocumento(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/archivodownload`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

  
}