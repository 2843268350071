import axios from 'axios';

export default class OrdenTrabajoProcedimientoProgramasService {

    getPrograma(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioprograma`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createPrograma(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioprogramaalta`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deletePrograma(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioprogramabaja`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    updatePrograma(info){
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoestudioprogramaedicion`;    
        return axios.patch(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

}