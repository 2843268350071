

import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import PlazoFacturacionService from '../services/plazofacturacion-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {
      dataSourceCompany: null,
      empresa: {},
      events: [],
      rolService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.plazofacturacionService = new PlazoFacturacionService();
  },
  mounted() {
    this.loadDataInfo();

  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    }, loadDataInfo() {
      this.plazofacturacionService.getPlazoFacturacion().then(data => {
        data.forEach(
          element => {

            if (element.bactivo == 1) {
              element.bactivo = true;
            } else {
              element.bactivo = false;
            }

          });
        this.dataSourceCompany = data;

      });
    },
    editRegister(e) {

      let vactivo = e.data.bactivo == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pid: e.data.id,
        pexpedicion: e.data.expedicion,
        penvio: e.data.envio,
        pbactivo: vactivo,
      });


      this.plazofacturacionService.updatePlazoFacturacion(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.loadDataInfo();
      });
    },
    deleteRegister(e) {
      let myJSON = JSON.stringify({
        pid: e.data.id,
      });


      this.plazofacturacionService.deletePlazoFacturacion(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.loadDataInfo();
      });

    },
    createRegister(e) {


      let vactivo = e.data.bactivo == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pexpedicion: e.data.expedicion,
        penvio: e.data.envio,
        pbactivo: vactivo,
      });


      this.plazofacturacionService.createPlazoFacturacion(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.loadDataInfo();
      });

    }, onInitNewRow(e) {
      e.data.serializable = true;
      e.data.bactivo = true;

    },
  },
};
