import axios from 'axios';

export default class ResponsableTecnicoService {

    getResponsableTecnico() {            
        const url = process.env.VUE_APP_APIURL + `/api/responsabletecnico`; 
        return axios.get(url).then(res=>res.data);
    }
    getResponsableTecnicoOrden() {            
        const url = process.env.VUE_APP_APIURL + `/api/responsabletecnicoorden`; 
        return axios.get(url).then(res=>res.data);
    }


    updateResponsableTecnico(info){
        const url = process.env.VUE_APP_APIURL + `/api/responsabletecnicoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createResponsableTecnico(info){
        const url = process.env.VUE_APP_APIURL + `/api/responsabletecnicoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteResponsableTecnico(info){
        const url = process.env.VUE_APP_APIURL + `/api/responsabletecnicobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}