import axios from 'axios';

export default class TamanoService {

    getTamano() {            
        const url = process.env.VUE_APP_APIURL + `/api/tamano`;    
        return axios.get(url).then(res=>res.data);
    }

    updateTamano(info){
        const url = process.env.VUE_APP_APIURL + `/api/tamanoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createTamano(info){
        const url = process.env.VUE_APP_APIURL + `/api/tamanoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteTamano(info){
        const url = process.env.VUE_APP_APIURL + `/api/tamanobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}