
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import OrdersTab from './rol-form.vue';
 

export default {
  components: {
    DxTabPanel, DxItem, OrdersTab, 
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
};
