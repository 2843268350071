
  import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
  
  import HabilidadTab from './grupolistadoinformaciondetalle.vue';
   
  export default {
    components: {
      DxTabPanel, DxItem, HabilidadTab
    },
    props: {
      masterDetailData: {
        type: Object,
        default: () => ({}),
      },
    },
  };
  