import axios from 'axios';

export default class TipoRespuestaService {

    getTipoRespuesta() {            
        const url = process.env.VUE_APP_APIURL + `/api/tiporespuesta`;    
        return axios.get(url).then(res=>res.data);
    }

  
}